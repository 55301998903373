import { Modal, Col, Card } from 'react-bootstrap';
import CrossSvg from '../../_admin/assets/media/close.png';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  Piece,
} from '../../utils/constants';
import Method from '../../utils/methods';
function InstantOrderBatchInfo(props: any) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [advertisment, setAdvertisement] = useState(props.advertisement);
  return (
    <Modal
      {...props}
      show={props.show}
      onHide={props.onClose}
      dialogClassName="modal-dialog-centered min-w-lg-1000px min-w-md-700px"
      className="border-r10px"
      centered
    >
      <Modal.Header className="border-bottom-0 pb-0 text-center mx-auto">
        <div className="symbol symbol-md-40px symbol-35px close-inner-top">
          <img
            width={40}
            height={40}
            src={CrossSvg}
            alt=""
            onClick={props.onClose}
          />
        </div>
        <Modal.Title className="fs-26 fw-bolder mw-lg-425px pt-lg-3">
          Batch Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card.Body className="py-0 border border-r10px">
          <Col xs={12}>
            <div className="table-responsive">
              <table className="table table-rounded table-row-bordered align-middle gs-9 gy-4 mb-0">
                <thead>
                  <tr className="fs-16 fw-600 h-50px align-middle">
                    <th className="min-w-175px w-250px">Product name</th>
                    <th className="min-w-125px w-200px">
                      Goods <br className="br" /> loading area
                    </th>
                    <th className="min-w-175px">Seller name</th>
                    <th className="min-w-100px">Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {!loading ? (
                      <>
                        {props.details && Object.keys(props.details).length ? (
                          <>
                            {props.details.instantDeliveredOrders &&
                            props.details.instantDeliveredOrders.length
                              ? props.details.instantDeliveredOrders.map(
                                  (val: any, index: number) => {
                                    return (
                                      <>
                                        {val.variants.map(
                                          (
                                            variantVal: any,
                                            variantIndex: number
                                          ) => {
                                            return (
                                              <tr>
                                                <td>
                                                  <div className="d-flex align-items-center">
                                                    <div className="symbol symbol-50px border me-4">
                                                      <img
                                                        src={
                                                          variantVal.variant
                                                            .media[0].url
                                                        }
                                                        className="object-fit-containe"
                                                        alt=""
                                                      />
                                                    </div>
                                                    <div className="d-flex justify-content-start flex-column fs-15 fw-600 mw-md-160px w-md-auto w-100px">
                                                      <span>
                                                        {variantVal.variant.title.replace(
                                                          /\s*\)\s*/g,
                                                          ')'
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  <span className="badge badge-warning text-dark fs-15 fw-bolder px-4 min-h-36px">
                                                    {`${variantVal.business.goodsLoadingAreas
                                                      .map((u: any) => u.name)
                                                      .join(' & ')}`}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="fs-15 fw-600">
                                                    {variantVal.business.name}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="fs-15 fw-600">
                                                    {variantVal.batches &&
                                                    variantVal.batches
                                                      .length ? (
                                                      <>
                                                        {variantVal.batches
                                                          .filter(
                                                            (batch: any) =>
                                                              batch.quantityType ===
                                                              variantVal.quantityType
                                                          )
                                                          .map(
                                                            (
                                                              val: any,
                                                              index: number
                                                            ) => (
                                                              <span
                                                                key={val.batch}
                                                              >
                                                                {val.stockCount}{' '}
                                                                {val.quantityType ===
                                                                  CartonWithDozens ||
                                                                val.quantityType ===
                                                                  CartonWithPieces
                                                                  ? val.stockCount ===
                                                                    1
                                                                    ? 'carton'
                                                                    : 'cartons'
                                                                  : ''}{' '}
                                                                {val.quantityType ===
                                                                Piece
                                                                  ? val.stockCount ===
                                                                    1
                                                                    ? 'piece'
                                                                    : 'pieces'
                                                                  : ''}{' '}
                                                                {val.quantityType ===
                                                                Dozen
                                                                  ? val.stockCount ===
                                                                    1
                                                                    ? 'dozen'
                                                                    : 'dozens'
                                                                  : ''}{' '}
                                                                | Batch{' '}
                                                                {val.batch} -{' '}
                                                                {Method.convertDateToDDMMYYYY(
                                                                  val.expiry
                                                                )}
                                                                {index <
                                                                  variantVal
                                                                    .batches
                                                                    .length -
                                                                    1 && (
                                                                  <br />
                                                                )}{' '}
                                                                {/* Add a <br /> if not the last element */}
                                                              </span>
                                                            )
                                                          )}
                                                      </>
                                                    ) : (
                                                      <>-</>
                                                    )}
                                                  </span>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </>
                                    );
                                  }
                                )
                              : props.details.transferredStockDetails.map(
                                  (val: any, index: number) => {
                                    return (
                                      <>
                                        {val.variants.map(
                                          (
                                            variantVal: any,
                                            variantIndex: number
                                          ) => {
                                            return (
                                              <tr>
                                                <td>
                                                  <div className="d-flex align-items-center">
                                                    <div className="symbol symbol-50px border me-4">
                                                      <img
                                                        src={
                                                          variantVal.variant
                                                            .media[0].url
                                                        }
                                                        className="object-fit-containe"
                                                        alt=""
                                                      />
                                                    </div>
                                                    <div className="d-flex justify-content-start flex-column fs-15 fw-600 mw-md-160px w-md-auto w-100px">
                                                      <span>
                                                        {variantVal.variant.title.replace(
                                                          /\s*\)\s*/g,
                                                          ')'
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  <span className="badge badge-warning text-dark fs-15 fw-bolder px-4 min-h-36px">
                                                    {`${variantVal.business.goodsLoadingAreas
                                                      .map((u: any) => u.name)
                                                      .join(' & ')}`}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="fs-15 fw-600">
                                                    {variantVal.business.name}
                                                  </span>
                                                </td>
                                                <td>
                                                  <span className="fs-15 fw-600">
                                                    {variantVal.batches &&
                                                    variantVal.batches
                                                      .length ? (
                                                      <>
                                                        {variantVal.batches
                                                          .filter(
                                                            (batch: any) =>
                                                              batch.quantityType ===
                                                              variantVal.quantityType
                                                          )
                                                          .map(
                                                            (
                                                              val: any,
                                                              index: number
                                                            ) => (
                                                              <span
                                                                key={val.batch}
                                                              >
                                                                {val.stockCount}{' '}
                                                                {val.quantityType ===
                                                                  CartonWithDozens ||
                                                                val.quantityType ===
                                                                  CartonWithPieces
                                                                  ? val.stockCount ===
                                                                    1
                                                                    ? 'carton'
                                                                    : 'cartons'
                                                                  : ''}{' '}
                                                                {val.quantityType ===
                                                                Piece
                                                                  ? val.stockCount ===
                                                                    1
                                                                    ? 'piece'
                                                                    : 'pieces'
                                                                  : ''}{' '}
                                                                {val.quantityType ===
                                                                Dozen
                                                                  ? val.stockCount ===
                                                                    1
                                                                    ? 'dozen'
                                                                    : 'dozens'
                                                                  : ''}{' '}
                                                                | Batch{' '}
                                                                {val.batch} -{' '}
                                                                {Method.convertDateToDDMMYYYY(
                                                                  val.expiry
                                                                )}
                                                                {index <
                                                                  variantVal
                                                                    .batches
                                                                    .length -
                                                                    1 && (
                                                                  <br />
                                                                )}{' '}
                                                                {/* Add a <br /> if not the last element */}
                                                              </span>
                                                            )
                                                          )}
                                                      </>
                                                    ) : (
                                                      <>-</>
                                                    )}
                                                  </span>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </>
                                    );
                                  }
                                )}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                </tbody>
              </table>
            </div>
          </Col>
        </Card.Body>
      </Modal.Body>
    </Modal>
  );
}
export default InstantOrderBatchInfo;
