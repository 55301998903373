import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CrossSvg from '../../_admin/assets/media/close.png';
import { useState } from 'react';
const SuccessActionModal = (props: any) => {
  const [loading, setLoading] = useState(false);
  const handleSave = async () => {
    setLoading(true);
    await props.onSave();
   setTimeout(()=>{setLoading(false)},1000); 
  };
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      dialogClassName="modal-dialog-centered min-w-lg-590px"
      className="border-r10px"
      contentClassName="p-5"
    >
      <Modal.Header className="border-bottom-0 text-center mx-auto">
        <div className="symbol symbol-md-40px symbol-35px close-inner-top">
          <img
            width={40}
            height={40}
            src={CrossSvg}
            alt="closebutton"
            onClick={props.onHide}
          />
        </div>
        <Modal.Title className="fs-26 fw-bolder mw-lg-400px">
          {props?.title}?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-2">
        <div className="row justify-content-center">
          <div className="col-lg-10 text-center">
            <Button
              variant="primary"
              size="lg"
              onClick={handleSave}
              disabled={loading}
            >
              {loading ? (
                <span
                  className="indicator-progress"
                  style={{ display: 'block' }}
                >
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                'Yes'
              )}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default SuccessActionModal;
