import { IListAppSettings } from '../../types/request_data/settings';
export const settingsJSON = {
  appData: ({ interfaceName ,warehouse}: IListAppSettings) => {
    let data: any = {};
    if (interfaceName) {
      data = { ...data, interface: interfaceName };
    }
    if(warehouse){
      data.warehouse = warehouse
    }
    return data;
  },
};
