import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormLabel,
  InputGroup,
  Nav,
  OverlayTrigger,
  Popover,
  Row,
  Tab,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../custom/DateRange/dateRange.css";
import { useNavigate } from "react-router-dom";
import threeDots from "../../../_admin/assets/media/svg_uTrade/three-dot.svg";
import { KTSVG } from "../../../_admin/helpers";
import { CustomSelectTable2 } from "../../custom/Select/custom-select-table";
import APICallService from "../../../api/apiCallService";
import { customerService, multipleWarehouse } from "../../../api/apiEndPoints";
import { customerServiceJSON } from "../../../api/apiJSON/customerService";
import { getPageLimit, setPageLimit, useAuth } from "../auth";
import {
  Add,
  Admin,
  AllModules,
  AssistAdmin,
  CustomerServices,
  Delete,
  Edit,
  Manager,
  PAGE_LIMIT,
  ServiceMember,
  View,
  warehouseRef,
} from "../../../utils/constants";
import ManagerAlreadyAssigned from "../../modals/manager-already-assigned";
import Loader from "../../../Global/loader";
import Method from "../../../utils/methods";
import Pagination from "../../../Global/pagination";
import { CustomSelectTable } from "../../custom/Select/CustomSelectTable";
import { getKey, removeKey, setKey } from "../../../Global/history";
import {
  listCustomerServiceTeam,
  warehouse as warehouseStoreString,
} from "../../../utils/storeString";
import { ElementAnimateUtil } from "../../../_admin/assets/ts/_utils";
import PermissionModal from "../../modals/permission";
import { CustomSelect } from "../../custom/Select/CustomSelect";
const CustomerService = () => {
  const { currentUser }: any = useAuth();
  const navigate = useNavigate();
  const [active, setActive] = useState(
    getKey(listCustomerServiceTeam.currentTab) || 1
  );
  const [show, setShow] = useState(false);
  const [id, setId] = useState(-1);
  const [startDate, setStartDate] = useState<any>(new Date());
  const [defaultValue, setDefaultValue] = useState("1");
  const [updated, setUpdated] = useState(0);
  const [managerAlreadyAssigned, setManagerAlreadyAssigned] = useState(false);
  const [districtManagerAlreadyAssigned, setDistrictManagerAlreadyAssigned] =
    useState(false);
  const [managerData, setManagerData] = useState({});
  const [loading, setLoading] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [members, setMembers] = useState([]);
  const [assistAdmin, setAssistAdmin] = useState([]);
  const [fetchLoader, setFetchLoader] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalDistricts, setTotalDistricts] = useState(0);
  const [page, setPage] = useState<any>(
    getKey(listCustomerServiceTeam.currentTab)
      ? getKey(listCustomerServiceTeam.page) || 1
      : 1
  );
  const [pageLimit, setPageLimit] = useState<any>(
    getKey(listCustomerServiceTeam.currentTab)
      ? getKey(listCustomerServiceTeam.limit) || PAGE_LIMIT
      : PAGE_LIMIT
  );
  const [searchTerm, setSearchTerm] = useState<string>(
    getKey(listCustomerServiceTeam.search)?.toString() || ""
  );
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [warehouse, setWarehouse] = useState<any>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>(null);
  const [warehouseData, setWarehouseData] = useState<any>({
    value: currentUser?.warehouse.reference || "",
    title: currentUser?.warehouse.name || "",
    label: currentUser?.warehouse.name || "",
  });
  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      if (!Method.hasModulePermission(CustomerServices, currentUser)) {
        return window.history.back();
      }
      let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
      tempWarehouse = JSON.parse(tempWarehouse);
      if (tempWarehouse?._id == "") {
        tempWarehouse._id = currentUser?.warehouse?._id;
        tempWarehouse.value = currentUser?.warehouse?._id || "";
        tempWarehouse.title = currentUser?.warehouse?.name || "";
        tempWarehouse.label = currentUser?.warehouse?.name || "";
      }
      if (getKey(listCustomerServiceTeam.currentTab)) {
        let activeTemp: any = getKey(listCustomerServiceTeam.currentTab);
        setActive(parseInt(activeTemp));
        if (parseInt(activeTemp) === 2) {
          await fetchDistrict(1, 10, selectedWarehouse?._id || null);
          await fetchAssistAdmin(
            page,
            pageLimit,
            searchTerm,
            tempWarehouse?._id || null
          );
        } else {
          await fetchDistrictMembers(
            page,
            10,
            startDate,
            tempWarehouse?._id || null
          );
          let params: any = {
            pageNo: 1,
            limit: 0,
            sortKey: "name",
            sortOrder: 1,
            "role[]": 1,
          };
          if (tempWarehouse?._id) {
            params = {
              ...params,
              warehouse: tempWarehouse?._id,
            };
          }
          let apiService = new APICallService(
            customerService.list,
            params,
            "",
            "",
            "",
            "",
            CustomerServices,
            tempWarehouse?._id || null
          );
          let response = await apiService.callAPI();
          if (response.records.length) {
            setAssistAdmin(response.records);
          }
        }
        setActive(parseInt(activeTemp));
      } else {
        await fetchDistrictMembers(
          page,
          10,
          startDate,
          tempWarehouse?._id || null
        );
        let params: any = {
          pageNo: 1,
          limit: 0,
          sortKey: "name",
          sortOrder: 1,
          "role[]": 1,
        };
        if (tempWarehouse?._id) {
          params.warehouse = tempWarehouse?._id;
        }
        let apiService = new APICallService(
          customerService.list,
          params,
          "",
          "",
          "",
          "",
          CustomerServices,
          tempWarehouse?._id || null
        );
        let response = await apiService.callAPI();
        if (response.records) {
          setAssistAdmin(response.records);
        }
      }
      setDataFetched(true);
      setFetchLoader(false);
      fetchWarehouse();
    })();
  }, []);
  useEffect(() => {
    if (dataFetched) {
      let initialScrollPosition: any = getKey(
        listCustomerServiceTeam.scrollPosition
      );
      if (initialScrollPosition) {
        ElementAnimateUtil.scrollTo(parseInt(initialScrollPosition));
        setDataFetched(false);
      }
      setKey(listCustomerServiceTeam.search, "", false);
      setKey(listCustomerServiceTeam.scrollPosition, 0);
    }
  }, [dataFetched]);
  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      await fetchDistrict(1, 10, selectedWarehouse?._id || null);
      await fetchAssistAdmin(
        1,
        pageLimit,
        searchTerm,
        selectedWarehouse?._id || null
      );
      setFetchLoader(false);
    })();
  }, [updated]);
  const handleActive = async (event: any) => {
    setTotalRecords(0);
    setPage(1);
    setActive(event);
    setKey(listCustomerServiceTeam.currentTab, parseInt(event));
    setMembers([]);
    setFetchLoader(true);
    if (event === 2) {
      await fetchDistrict(1, 10, selectedWarehouse?._id || null);
      await fetchAssistAdmin(
        1,
        pageLimit,
        searchTerm,
        selectedWarehouse?._id || null
      );
    } else {
      await fetchDistrictMembers(1, 10, "", selectedWarehouse?._id || null);
      let params: any = {
        pageNo: 1,
        limit: 0,
        sortKey: "name",
        sortOrder: 1,
        "role[]": 1,
      };
      if (selectedWarehouse?._id) {
        params = {
          ...params,
          warehouse: selectedWarehouse?._id,
        };
      }
      let apiService = new APICallService(
        customerService.list,
        params,
        "",
        "",
        "",
        "",
        CustomerServices,
        selectedWarehouse?._id || null
      );
      let response = await apiService.callAPI();
      if (response.records) {
        setAssistAdmin(response.records);
      }
    }
    setFetchLoader(false);
  };
  const handleSearch = async (value: string) => {
    setSearchTerm(value);
    setKey(listCustomerServiceTeam.search, value, false);
    setLoading(true);
    await fetchAssistAdmin(
      page,
      pageLimit,
      value,
      selectedWarehouse?._id || null
    );
    setLoading(false);
  };
  const handleSelect = (event: any) => {
    setDefaultValue(event);
  };
  const onMenuScrollToBottom = async () => {
    if (!(districts.length === totalDistricts)) {
      let tempPage = page;
      tempPage = tempPage + 1;
      setPage(tempPage);
      await fetchDistrict(tempPage, 10, selectedWarehouse?._id || null);
    }
  };
  const fetchDistrict = async (
    pageNo: number,
    limit: number,
    warehouse: any
  ) => {
    let params: any = {
      pageNo: pageNo,
      limit: limit,
      needCount: true,
    };
    if (warehouse) {
      params = {
        ...params,
        warehouse: warehouse,
      };
    }
    let apiService = new APICallService(
      customerService.listDistrict,
      params,
      "",
      "",
      "",
      "",
      CustomerServices,
      warehouse
    );
    let response = await apiService.callAPI();
    let data: any = [...districts];
    if (response.records) {
      if (response.total) {
        setTotalDistricts(response.total);
      }
      response.records.map((val: any) => {
        data.push({
          value: val._id,
          label: val.name,
        });
      });
    }
    setDistricts(data);
  };
  const fetchDistrictMembers = async (
    pageNo: number,
    limit: number,
    dateFilter: string,
    warehouse?: any
  ) => {
    let params: any = {
      pageNo: pageNo,
      limit: limit,
      needCount: true,
      year: dateFilter ? Method.getYear(dateFilter) : "",
      month: dateFilter ? parseInt(Method.getMonth(dateFilter)) - 1 : "",
    };
    if (warehouse) {
      params["warehouse"] = warehouse;
    }
    let apiService = new APICallService(
      customerService.listDistrictManager,
      params,
      "",
      "",
      "",
      "",
      CustomerServices,
      warehouse
    );
    let response = await apiService.callAPI();
    if (response.records) {
      if (response.total) {
        setTotalRecords(response.total);
      }
      setMembers(response.records);
    }
  };
  const fetchAssistAdmin = async (
    pageNo: number,
    limit: number,
    search: string,
    warehouse?: any
  ) => {
    let params: any = {
      pageNo: pageNo,
      limit: limit,
      searchTerm: search,
      needCount: true,
      sortKey: "name",
      sortOrder: 1,
      ["role[0]"]: Manager,
      ["role[1]"]: ServiceMember,
    };
    if (warehouse) {
      params["warehouse"] = warehouse;
    }
    let apiService = new APICallService(
      customerService.list,
      params,
      "",
      "",
      "",
      "",
      CustomerServices,
      warehouse
    );
    let response = await apiService.callAPI();
    if (response.records) {
      if (response.total) {
        setTotalRecords(response.total);
      }
      response.records.map((val: any) => {
        let temp: any = [];
        val.districts.map((distVal: any) => {
          temp.push({
            ...distVal,
            value: distVal.reference,
            label: distVal.name,
          });
        });
        val.districts = temp;
        return val;
      });
      setAssistAdmin(response.records);
    } else {
      setAssistAdmin([]);
    }
  };
  const handleAssign = async (event: any, userId: string, index: number) => {
    let temp: any = JSON.parse(JSON.stringify([...assistAdmin]));
    const previousSelectedIds = temp[index].districts.map(
      (option: any) => option.reference
    );
    if (event.length > previousSelectedIds.length) {
      // Options were added
      if (event.length) {
        const newlySelectedOptions = event.filter(
          (option: any) =>
            !temp[index].districts.some(
              (selectedOption: any) => selectedOption.reference === option.value
            )
        );
        let apiService = new APICallService(
          customerService.checkAssignedManager,
          newlySelectedOptions.length
            ? newlySelectedOptions[0].value
            : event[0].value,
          "",
          "",
          "",
          "",
          CustomerServices,
          selectedWarehouse?._id || null
        );
        let response = await apiService.callAPI();
        if (response.records) {
          setManagerData({
            event: event,
            districtId: newlySelectedOptions.length
              ? newlySelectedOptions[0].value
              : event[0].value,
            index: index,
            userId: userId,
            districtName: newlySelectedOptions.length
              ? newlySelectedOptions[0].label
              : event[0].label,
            assignedId: response.records._id,
          });
          if (!Method.hasPermission(CustomerServices, Edit, currentUser)) {
            setShowPermissionModal(true);
          } else {
            setManagerAlreadyAssigned(true);
          }
          // setManagerAlreadyAssigned(true);
        } else {
          let tempDistrict: any = [];
          let districts: any = [];
          newlySelectedOptions.map((eventVal: any) => {
            tempDistrict.push({
              reference: eventVal.value,
              name: eventVal.label,
            });
            districts.push(eventVal.value);
          });
          // temp[index].districts = tempDistrict;
          // setMembers(temp);
          await handleAssignManager(
            newlySelectedOptions,
            districts,
            index,
            userId
          );
        }
      } else {
        temp[index].districts = [];
      }
    } else {
      const removedOptions = temp[index].districts.filter(
        (option: any) =>
          !event.map((val: any) => val.value).includes(option.reference)
      );
      if (removedOptions.length) {
        if (!Method.hasPermission(CustomerServices, Edit, currentUser)) {
          setShowPermissionModal(true);
        } else {
          await Promise.all(
            removedOptions.map(async (removeVal: any) => {
              let apiService = new APICallService(
                customerService.removeAssignedManager,
                {},
                {
                  id: userId,
                  districtId: removeVal.reference,
                },
                "",
                "",
                "",
                CustomerServices,
                selectedWarehouse?._id || null
              );
              let response = await apiService.callAPI();
              return response;
            })
          );
          const updatedOptions = temp[index].districts.filter(
            (option: any) =>
              !removedOptions.some(
                (removeVal: any) => removeVal.reference === option.reference
              )
          );
          // temp[index].districts = updatedOptions;
          setUpdated(updated + 1);
        }
      }
    }
    setAssistAdmin(temp);
  };
  const handleServiceMemberAssign = async (
    event: any,
    userId: string,
    index: number
  ) => {
    let temp: any = JSON.parse(JSON.stringify([...assistAdmin]));
    let district: any = [];
    let tempDistrict: any = [];
    district.push(event.value);
    tempDistrict.push({ reference: event.value, name: event.label });
    let apiService = new APICallService(
      customerService.assignDistrict,
      customerServiceJSON.assignDistrict({ districts: district }),
      { id: userId },
      "",
      "",
      "",
      CustomerServices,
      selectedWarehouse?._id || null
    );
    let response = await apiService.callAPI();
    if (response) {
      // temp[index].districts = tempDistrict;
    }
    setUpdated(updated + 1);
    await fetchDistrict(1, 10, selectedWarehouse?._id || null);
    await fetchAssistAdmin(
      1,
      pageLimit,
      searchTerm,
      selectedWarehouse?._id || null
    );
    setAssistAdmin(temp);
  };
  const handleAssignManager = async (
    event: any,
    districtId: any,
    index: number,
    userId: string
  ) => {
    let district: any = districtId;
    let temp: any = [...members];
    let apiService = new APICallService(
      customerService.assignDistrict,
      customerServiceJSON.assignDistrict({ districts: district }),
      { id: userId },
      "",
      "",
      "",
      CustomerServices,
      selectedWarehouse?._id || null
    );
    let response = await apiService.callAPI();
    if (response) {
      let managerTemp: any = [];
      {
        if (event.length) {
          let tempDistrict: any = [];
          event.map((eventVal: any) => {
            tempDistrict.push({
              reference: eventVal.value,
              name: eventVal.label,
            });
          });
          // temp[index].districts = tempDistrict;
        }
        setMembers(temp);
        setManagerAlreadyAssigned(false);
      }
      setUpdated(updated + 1);
    }
  };
  const handleRemoveManager = async (
    event: any,
    districtId: string,
    index: number,
    userId: string,
    districtName: string,
    assignedId: string
  ) => {
    if (!Method.hasPermission(CustomerServices, Edit, currentUser)) {
      setShowPermissionModal(true);
    } else {
      let apiService = new APICallService(
        customerService.removeAssignedManager,
        {},
        {
          id: assignedId,
          districtId: districtId,
        },
        "",
        "",
        "",
        CustomerServices,
        selectedWarehouse?._id || null
      );
      let response = await apiService.callAPI();
      if (response) {
        if (managerAlreadyAssigned)
          await handleAssignManager(event, [districtId], index, userId);
        else await handleDistrictAssignManager(event, districtId, index);
      }
    }
  };
  const handleRemoveServiceManager = async (
    event: any,
    districtId: string,
    index: number,
    districtName: string,
    assignedId: string
  ) => {
    if (!Method.hasPermission(CustomerServices, Edit, currentUser)) {
      setShowPermissionModal(true);
    } else {
      let apiService = new APICallService(
        customerService.removeAssignedManager,
        {},
        {
          id: assignedId,
          districtId: districtId,
        },
        "",
        "",
        "",
        CustomerServices,
        selectedWarehouse?._id || null
      );
      let response = await apiService.callAPI();
      if (response) {
        await handleServiceMemberAssign(event, assignedId, index);
      }
    }
  };
  const checkDistrictAssignManager = async (
    event: any,
    districtId: string,
    index: number,
    districtName: string
  ) => {
    let temp: any = [...members];
    let apiService = new APICallService(
      customerService.checkAssignedManager,
      districtId,
      "",
      "",
      "",
      "",
      CustomerServices,
      selectedWarehouse?._id || null
    );
    let response = await apiService.callAPI();
    if (response.records) {
      setManagerData({
        event: event,
        districtId: districtId,
        index: index,
        userId: event.value,
        districtName: districtName,
        assignedId: response.records._id,
      });
      if (!Method.hasPermission(CustomerServices, Edit, currentUser)) {
        setShowPermissionModal(true);
      } else {
        setDistrictManagerAlreadyAssigned(true);
      }
      // setDistrictManagerAlreadyAssigned(true);
    } else {
      await handleDistrictAssignManager(event, districtId, index);
    }
  };
  const handleDistrictAssignManager = async (
    event: any,
    districtId: string,
    index: number
  ) => {
    let district: any = [districtId];
    let temp: any = [...members];
    let apiService = new APICallService(
      customerService.assignDistrict,
      customerServiceJSON.assignDistrict({ districts: district }),
      { id: event.value },
      "",
      "",
      "",
      CustomerServices,
      selectedWarehouse?._id || null
    );
    let response = await apiService.callAPI();
    if (response) {
      let managerTemp: any = [];
      managerTemp.push({
        _id: event.value,
        name: event.label,
        monthlyTarget: temp[index].manager.length
          ? temp[index].manager[0].monthlyTarget
          : 0,
      });
      temp[index].manager = managerTemp;
    }
    setMembers(temp);
    setDistrictManagerAlreadyAssigned(false);
  };
  const handleMonthlyTarget = async (value: any, id: string, index: number) => {
    let temp: any = [...members];
    temp[index].manager[0].monthlyTarget = value;
    setMembers(temp);
    let apiService = new APICallService(
      customerService.monthlyTarget,
      { monthlyTarget: value },
      { id: id },
      "",
      "",
      "",
      CustomerServices,
      selectedWarehouse?._id || null
    );
    let response = await apiService.callAPI();
  };
  const handleDateFilter = async (value: any) => {
    setLoading(true);
    setStartDate(value);
    await fetchDistrictMembers(
      page,
      pageLimit,
      value,
      selectedWarehouse?._id || null
    );
    setLoading(false);
  };
  const handleCurrentPage = async (val: number) => {
    setPage(val);
    if (active === 2) {
      setKey(listCustomerServiceTeam.page, val);
      await fetchAssistAdmin(
        val,
        pageLimit,
        searchTerm,
        selectedWarehouse?._id || null
      );
    } else {
      await fetchDistrictMembers(
        val,
        pageLimit,
        startDate,
        selectedWarehouse?._id || null
      );
    }
  };
  const handleNextPage = async (val: number) => {
    setPage(val + 1);
    if (active === 2) {
      setKey(listCustomerServiceTeam.page, val + 1);
      await fetchAssistAdmin(
        val + 1,
        pageLimit,
        searchTerm,
        selectedWarehouse?._id || null
      );
    } else {
      await fetchDistrictMembers(
        val + 1,
        pageLimit,
        startDate,
        selectedWarehouse?._id || null
      );
    }
  };
  const handlePreviousPage = async (val: number) => {
    setPage(val - 1);
    if (active === 2) {
      setKey(listCustomerServiceTeam.page, val - 1);
      await fetchAssistAdmin(
        val - 1,
        pageLimit,
        searchTerm,
        selectedWarehouse?._id || null
      );
    } else {
      await fetchDistrictMembers(
        val - 1,
        pageLimit,
        startDate,
        selectedWarehouse?._id || null
      );
    }
  };
  const handlePageLimit = async (event: any) => {
    setPage(1);
    await setPageLimit(parseInt(event.target.value));
    if (active === 2) {
      setKey(listCustomerServiceTeam.page, 1);
      setKey(listCustomerServiceTeam.limit, parseInt(event.target.value));
      await fetchAssistAdmin(
        1,
        event.target.value,
        searchTerm,
        selectedWarehouse?._id || null
      );
    } else {
      await fetchDistrictMembers(
        1,
        event.target.value,
        startDate,
        selectedWarehouse?._id || null
      );
    }
  };
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body className="p-2 bg-black border-r10px text-white">
        <span>Assign Manager to district to view details</span>
      </Popover.Body>
    </Popover>
  );
  const openMenuOnClick = async (data: any) => {
    setId(id);
    setShow(true);
  };
  const onMenuClose = async () => {
    setId(-1);
    setShow(false);
  };
  const onMenuOpen = async (id: any) => {
    setId(id);
    setShow(true);
  };
  const handleOption = async (event: any, index: number, data: any) => {
    let currentScrollY = window.scrollY;
    setKey(listCustomerServiceTeam.scrollPosition, currentScrollY);
    if (event.value === 1) {
      navigate("/customer-service/edit-staff", { state: data });
    } else if (event.value === 2) {
      setDeleteLoader(true);
      let temp: any = [...assistAdmin];
      let total: any = totalRecords;
      let apiService = new APICallService(
        customerService.delete,
        data._id,
        "",
        "",
        "",
        "",
        CustomerServices,
        selectedWarehouse?._id || null
      );
      let response = await apiService.callAPI();
      if (response) {
        const newArray = temp.filter((obj: any) => obj._id !== data._id);
        setAssistAdmin(newArray);
        setTotalRecords(total - 1);
      }
      setId(-1);
      setShow(false);
      setDeleteLoader(false);
    }
  };
  const fetchWarehouse = async () => {
    let params = {
      skip: 0,
      sortKey: "name",
      sortOrder: 1,
      needCount: true,
    };
    setLoading(true);
    const apiService = new APICallService(
      multipleWarehouse.listWarehouse,
      params,
      "",
      "",
      "",
      "",
      AllModules
    );
    const response = await apiService.callAPI();
    let data: any = [...warehouse];
    if (response.records.length) {
      response.records.map((val: any) => {
        data.push({
          value: val._id,
          label: val.name,
          title: val.name,
          _id: val._id,
        });
      });
    }
    let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
    tempWarehouse = JSON.parse(tempWarehouse);
    if (tempWarehouse) {
      if (tempWarehouse?._id == "") {
        tempWarehouse._id = currentUser?.warehouse?._id;
        tempWarehouse.value = currentUser?.warehouse?._id || "";
        tempWarehouse.title = currentUser?.warehouse?.name || "";
        tempWarehouse.label = currentUser?.warehouse?.name || "";
      }
      const temp = data.find((item: any) => item._id === tempWarehouse._id);
      setSelectedWarehouse(temp);
      setWarehouseData(temp);
    }
    setWarehouse(data);
    setLoading(false);
  };
  const handleWarehouseChange = async (event: any) => {
    setLoading(true);
    setPage(1);
    // setKey(listDrivers.filterByType, event);
    setTotalRecords(0);
    if (event) {
      setSelectedWarehouse(event);
      setWarehouseData(event);
      setKey(warehouseStoreString.warehouseRef, event);
      if (active === 2) {
        await fetchAssistAdmin(1, pageLimit, searchTerm, event.value);
      } else {
        await fetchDistrictMembers(1, pageLimit, startDate, event.value);
      }
    } else {
      setSelectedWarehouse(null);
      if (active === 2) {
        await fetchAssistAdmin(1, pageLimit, searchTerm);
      } else {
        await fetchDistrictMembers(1, pageLimit, startDate);
      }
      removeKey(warehouseStoreString.warehouseRef);
    }
    setLoading(false);
  };
  return (
    <>
      {showPermissionModal && (
        <PermissionModal
          show={showPermissionModal}
          onHide={() => setShowPermissionModal(false)}
          error={
            "You do not have sufficient permission for edit customer service module"
          }
        />
      )}
      {managerAlreadyAssigned ? (
        <ManagerAlreadyAssigned
          show={managerAlreadyAssigned}
          onHide={() => setManagerAlreadyAssigned(false)}
          managerData={managerData}
          onSave={handleRemoveManager}
        />
      ) : (
        <ManagerAlreadyAssigned
          show={districtManagerAlreadyAssigned}
          onHide={() => setDistrictManagerAlreadyAssigned(false)}
          managerData={managerData}
          onSave={handleRemoveManager}
        />
      )}
      <div className="p-9">
        <Row className="align-items-center g-3 mb-8">
          <Col xs>
            <h1 className="fs-22 fw-bolder mb-0">Customer service</h1>
          </Col>
          <Col md={"auto"}>
            {currentUser?.userType === Admin ? (
              <div className="d-flex align-items-center gap-4">
                <FormLabel className="fs-16 fw-500 text-dark">
                  Filter by warehouse
                </FormLabel>
                <CustomSelect
                  backgroundColor="white"
                  value={selectedWarehouse}
                  // defaultValue={selectedDriverType}
                  onChange={(event: any) => {
                    handleWarehouseChange(event);
                  }}
                  hideSelectedOptions={true}
                  options={warehouse}
                  // isClearable={true}
                />
              </div>
            ) : (
              <></>
            )}
          </Col>
          <Col xs="auto">
            {Method.hasPermission(CustomerServices, Add, currentUser) ? (
              <Button
                variant="primary"
                className="btn-md h-50px"
                onClick={() => {
                  navigate("/customer-service/add-new-staff");
                  setKey(listCustomerServiceTeam.search, searchTerm, false);
                }}
              >
                Add new manager / member
              </Button>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col lg={12} className="custom-tabContainer">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={defaultValue}
              onSelect={handleSelect}
            >
              <Row className="variant-categories">
                <Col xs={12}>
                  <Row className="align-items-center g-3">
                    <Col xs md={8}>
                      <div className="bg-light border border-r8px p-3 px-md-6 w-fit-content">
                        <Nav variant="pills">
                          <Nav.Item>
                            <Nav.Link
                              eventKey="1"
                              onClick={() => {
                                handleActive(1);
                              }}
                              active={active === 1}
                            >
                              {" "}
                              District{" "}
                              {active === 1 ? "(" + totalRecords + ")" : ""}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="2"
                              onClick={() => {
                                handleActive(2);
                              }}
                              active={active === 2}
                            >
                              {" "}
                              Customer service team
                              {active === 2 ? "(" + totalRecords + ")" : ""}
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                    </Col>
                    {active === 1 ? (
                      <>
                        <Col
                          xs="auto"
                          md={4}
                          className="d-flex  justify-content-end"
                        >
                          <div>
                            <DatePicker
                              className="form-control bg-light mh-50px fs-16 fw-bold text-dark mw-lg-174px"
                              selected={startDate}
                              onChange={(data) => handleDateFilter(data)}
                              selectsStart
                              startDate={startDate}
                              dateFormat="MMMM yyyy"
                              showMonthYearPicker
                              fixedHeight
                            />
                          </div>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col xs="auto" md={4}>
                          <div className="position-relative border border-r8px">
                            <KTSVG
                              path="/media/icons/duotune/general/gen021.svg"
                              className="svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ps-14"
                            />
                            <input
                              type="text"
                              className="form-control form-control-custom form-control-solid ps-12"
                              name="Search Team"
                              placeholder="Search by member name…"
                              value={searchTerm}
                              onChange={(event: any) => {
                                handleSearch(event.target.value.trimStart());
                              }}
                            />
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
                <Col lg={12}>
                  <Tab.Content>
                    {/* District */}
                    <Tab.Pane eventKey="1" active={active.toString() === "1"}>
                      {!fetchLoader ? (
                        <>
                          {members && members.length ? (
                            <>
                              <Card className="border border-r10px mt-6">
                                <Card.Body className="p-0">
                                  <div className="table-responsive">
                                    <table className="table table-rounded table-row-bordered align-middle gs-7 gy-5 mb-0">
                                      <thead>
                                        <tr className="fs-16 fw-bold text-dark border-bottom h-70px align-middle">
                                          <th className="min-w-150px">
                                            District name
                                          </th>
                                          <th className="min-w-200px">
                                            Assigned manager
                                          </th>
                                          <th className="min-w-150px">
                                            Monthly target
                                          </th>
                                          <th className="min-w-100px">
                                            Current sales
                                          </th>
                                          <th className="min-w-100px">
                                            Members
                                          </th>
                                          <th className="w-100px text-end"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {members.map(
                                          (val: any, index: number) => {
                                            return (
                                              <tr>
                                                <td>
                                                  <span className="fs-15 fw-600">
                                                    {val.name}
                                                  </span>
                                                </td>
                                                <td>
                                                  <CustomSelectTable2
                                                    minWidth="176px"
                                                    minHeight="45px"
                                                    backgroundColor="#f9f9f9"
                                                    value={
                                                      val.manager &&
                                                      val.manager.map(
                                                        (val: any) => {
                                                          return {
                                                            label: val.name,
                                                            value: val._id,
                                                          };
                                                        }
                                                      )
                                                    }
                                                    options={
                                                      assistAdmin &&
                                                      assistAdmin
                                                        .filter(
                                                          (option: any) => {
                                                            // Check if the option is not selected
                                                            const isSelected =
                                                              val.manager &&
                                                              val.manager.find(
                                                                (
                                                                  selectedOption: any
                                                                ) => {
                                                                  return (
                                                                    selectedOption._id ===
                                                                    option._id
                                                                  );
                                                                }
                                                              );
                                                            return !isSelected;
                                                          }
                                                        )
                                                        .map(
                                                          (
                                                            val: any,
                                                            index: number
                                                          ) => {
                                                            return {
                                                              label: val.name,
                                                              value: val._id,
                                                            };
                                                          }
                                                        )
                                                    }
                                                    onChange={(event: any) => {
                                                      checkDistrictAssignManager(
                                                        event,
                                                        val._id,
                                                        index,
                                                        val.name
                                                      );
                                                    }}
                                                    isDisabled={
                                                      !Method.hasPermission(
                                                        CustomerServices,
                                                        Add,
                                                        currentUser
                                                      )
                                                    }
                                                  />
                                                </td>
                                                <td>
                                                  <div className="mw-146px">
                                                    <InputGroup className="bg-light border border-r5px">
                                                      <InputGroup.Text className="fs-14 fw-600 text-dark border-0 pe-3">
                                                        TSh
                                                      </InputGroup.Text>
                                                      <Form.Control
                                                        className="border-0 ps-0 form-control bg-light fs-14 fw-600 text-dark"
                                                        type="number"
                                                        value={
                                                          val.manager.length
                                                            ? val.manager[0]
                                                                .monthlyTarget
                                                              ? val.manager[0]
                                                                  .monthlyTarget
                                                              : ""
                                                            : ""
                                                        }
                                                        onChange={(
                                                          event: any
                                                        ) => {
                                                          handleMonthlyTarget(
                                                            event.target.value.trimStart(),
                                                            val.manager.length
                                                              ? val.manager[0]
                                                                  ._id
                                                              : "",
                                                            index
                                                          );
                                                        }}
                                                        disabled={
                                                          !val.manager.length ||
                                                          !Method.hasPermission(
                                                            CustomerServices,
                                                            Add,
                                                            currentUser
                                                          )
                                                        }
                                                      />
                                                    </InputGroup>
                                                  </div>
                                                </td>
                                                <td>
                                                  <span className="fs-15 fw-600">
                                                    TSh 0
                                                  </span>
                                                </td>
                                                <td>
                                                  {val.serviceMember.data
                                                    .length ? (
                                                    <div className="symbol-group symbol-hover flex-nowrap">
                                                      {val.serviceMember.data.map(
                                                        (member: any) => {
                                                          return (
                                                            <div className="symbol symbol-44px symbol-circle">
                                                              <img
                                                                alt={
                                                                  member.name
                                                                }
                                                                src={
                                                                  member.image
                                                                }
                                                              />
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                      {val.serviceMember.total >
                                                      val.serviceMember.data
                                                        .length ? (
                                                        <div className="symbol symbol-44px symbol-circle">
                                                          <span className="symbol-label bg-light-primary text-primary fs-15 fw-600">
                                                            +
                                                            {parseInt(
                                                              val.serviceMember
                                                                .total
                                                            ) -
                                                              parseInt(
                                                                val
                                                                  .serviceMember
                                                                  .data.length
                                                              )}
                                                          </span>
                                                        </div>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </div>
                                                  ) : (
                                                    <>
                                                      {" "}
                                                      <span className=" fs-15 fw-600">
                                                        -
                                                      </span>
                                                    </>
                                                  )}
                                                </td>
                                                <td className="text-end">
                                                  {Method.hasPermission(
                                                    CustomerServices,
                                                    View,
                                                    currentUser
                                                  ) ? (
                                                    <>
                                                      {!val.manager.length ? (
                                                        <OverlayTrigger
                                                          trigger="hover"
                                                          placement="bottom"
                                                          overlay={popover}
                                                        >
                                                          <Button
                                                            size="sm"
                                                            className="btn-table min-h-40px"
                                                          >
                                                            View details
                                                          </Button>
                                                        </OverlayTrigger>
                                                      ) : (
                                                        <Button
                                                          size="sm"
                                                          className="btn-table min-h-40px"
                                                          onClick={() => {
                                                            let currentScrollY =
                                                              window.scrollY;
                                                            setKey(
                                                              listCustomerServiceTeam.scrollPosition,
                                                              currentScrollY
                                                            );
                                                            setKey(
                                                              listCustomerServiceTeam.search,
                                                              searchTerm,
                                                              false
                                                            );
                                                            navigate(
                                                              "/customer-service/district-details",
                                                              {
                                                                state: {
                                                                  districtId:
                                                                    val._id,
                                                                  districtName:
                                                                    val.name,
                                                                  userId: val
                                                                    .manager
                                                                    .length
                                                                    ? val
                                                                        .manager[0]
                                                                        ._id
                                                                    : "",
                                                                  isAdmin: true,
                                                                },
                                                              }
                                                            );
                                                          }}
                                                        >
                                                          View details
                                                        </Button>
                                                      )}{" "}
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </Card.Body>
                              </Card>
                            </>
                          ) : (
                            <>
                              {" "}
                              <div className="border border-r10px mt-6">
                                <div className="d-flex flex-center h-160px">
                                  <span className="fs-18 fw-500">
                                    You have not assigned any manager or
                                    customer service member to this district.
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {" "}
                          <div className="border border-r10px mt-6">
                            <div className="d-flex flex-center h-160px">
                              <Loader loading={fetchLoader} />
                            </div>
                          </div>
                        </>
                      )}
                    </Tab.Pane>
                    {/* Customer service team */}
                    <Tab.Pane eventKey="2" active={active.toString() === "2"}>
                      {/* WHEN-NO-DATA */}
                      {!fetchLoader ? (
                        <>
                          {assistAdmin && assistAdmin.length ? (
                            <>
                              <Card className="border border-r10px mt-6">
                                <Card.Body className="p-0">
                                  <div className="table-responsive">
                                    <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4 mb-0">
                                      <thead>
                                        <tr className="fs-16 fw-bold text-dark border-bottom h-70px align-middle">
                                          <th className="min-w-250px">
                                            Member name
                                          </th>
                                          <th className="min-w-lg-350px min-w-lg-275px ">
                                            {" "}
                                            Assign districts
                                          </th>
                                          <th className="min-w-100px">
                                            Total sales
                                          </th>
                                          <th className="min-w-175px text-end"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {assistAdmin.map(
                                          (val: any, index: number) => {
                                            return (
                                              <tr>
                                                <td>
                                                  <div className="d-flex align-items-center">
                                                    <div className="symbol symbol-50px border me-5">
                                                      <img
                                                        src={val.image}
                                                        className="object-fit-contain"
                                                        alt=""
                                                      />
                                                    </div>
                                                    <div className="fs-15 fw-600">
                                                      {val.name} <br />{" "}
                                                      {val?.role &&
                                                      val?.role.some(
                                                        (obj: any) =>
                                                          obj.type ===
                                                          ServiceMember
                                                      ) ? (
                                                        <></>
                                                      ) : (
                                                        <span className="fw-500 text-gray">
                                                          Manager
                                                        </span>
                                                      )}
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="w-lg-320px w-250px">
                                                    {val?.role.some(
                                                      (obj: any) =>
                                                        obj.type ===
                                                        ServiceMember
                                                    ) ? (
                                                      <CustomSelectTable2
                                                        backgroundColor="#f9f9f9"
                                                        value={
                                                          val.districts &&
                                                          val.districts.map(
                                                            (val: any) => {
                                                              return {
                                                                label: val.name,
                                                                value:
                                                                  val.reference,
                                                              };
                                                            }
                                                          )
                                                        }
                                                        defaultValue={
                                                          val.districts &&
                                                          val.districts.map(
                                                            (val: any) => {
                                                              return {
                                                                label: val.name,
                                                                value:
                                                                  val.reference,
                                                              };
                                                            }
                                                          )
                                                        }
                                                        isSearchable={false}
                                                        onMenuScrollToBottom={
                                                          onMenuScrollToBottom
                                                        }
                                                        options={districts.filter(
                                                          (option: any) =>
                                                            option.value !==
                                                            val.districts[0]
                                                              ?.reference
                                                        )}
                                                        isMulti={false}
                                                        onChange={(
                                                          event: any
                                                        ) => {
                                                          val.districts.length
                                                            ? handleRemoveServiceManager(
                                                                event,
                                                                val.districts[0]
                                                                  .reference,
                                                                index,
                                                                val.name,
                                                                val._id
                                                              )
                                                            : handleServiceMemberAssign(
                                                                event,
                                                                val._id,
                                                                index
                                                              );
                                                        }}
                                                        isDisabled={
                                                          !Method.hasPermission(
                                                            CustomerServices,
                                                            Add,
                                                            currentUser
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      <>
                                                        <CustomSelectTable2
                                                          backgroundColor="#f9f9f9"
                                                          value={val.districts}
                                                          // value={districts.filter(
                                                          //   (option: any) =>
                                                          //     val.districts.find(
                                                          //       (
                                                          //         selectedOption: any
                                                          //       ) =>
                                                          //         selectedOption.value ===
                                                          //         option.value
                                                          //     )
                                                          // )}
                                                          isSearchable={false}
                                                          onMenuScrollToBottom={
                                                            onMenuScrollToBottom
                                                          }
                                                          options={districts.filter(
                                                            (option: any) =>
                                                              !val.districts.find(
                                                                (
                                                                  selectedOption: any
                                                                ) =>
                                                                  selectedOption.value ===
                                                                  option.value
                                                              )
                                                          )}
                                                          isMulti={true}
                                                          onChange={(
                                                            event: any
                                                          ) => {
                                                            handleAssign(
                                                              event,
                                                              val._id,
                                                              index
                                                            );
                                                          }}
                                                          isDisabled={
                                                            !Method.hasPermission(
                                                              CustomerServices,
                                                              Add,
                                                              currentUser
                                                            )
                                                          }
                                                        />
                                                      </>
                                                    )}
                                                  </div>
                                                </td>
                                                <td>
                                                  <span className="fs-15 fw-600">
                                                    TSh 0
                                                  </span>
                                                </td>
                                                <td className="text-end">
                                                  <div className="d-flex flex-nowrap flex-row justify-content-end">
                                                    {Method.hasPermission(
                                                      CustomerServices,
                                                      View,
                                                      currentUser
                                                    ) ? (
                                                      <Button
                                                        variant="primary"
                                                        className="fs-14 fw-600"
                                                        onClick={() => {
                                                          let currentScrollY =
                                                            window.scrollY;
                                                          setKey(
                                                            listCustomerServiceTeam.scrollPosition,
                                                            currentScrollY
                                                          );
                                                          setKey(
                                                            listCustomerServiceTeam.search,
                                                            searchTerm,
                                                            false
                                                          );
                                                          navigate(
                                                            "/customer-service/member-details",
                                                            {
                                                              state: {
                                                                id: val._id,
                                                                name: val.name,
                                                              },
                                                            }
                                                          );
                                                        }}
                                                      >
                                                        View details
                                                      </Button>
                                                    ) : (
                                                      <></>
                                                    )}
                                                    {Method.hasPermission(
                                                      CustomerServices,
                                                      Edit,
                                                      currentUser
                                                    ) ||
                                                    Method.hasPermission(
                                                      CustomerServices,
                                                      Delete,
                                                      currentUser
                                                    ) ? (
                                                      <CustomSelectTable
                                                        marginLeft={"-90px"}
                                                        width={"130px"}
                                                        placeholder={
                                                          <img
                                                            className="img-fluid"
                                                            width={18}
                                                            height={5}
                                                            src={threeDots}
                                                            alt=""
                                                          />
                                                        }
                                                        options={[
                                                          ...(Method.hasPermission(
                                                            CustomerServices,
                                                            Edit,
                                                            currentUser
                                                          )
                                                            ? [
                                                                {
                                                                  label: (
                                                                    <button className="btn btn-link fs-14 fw-500 text-black ms-3 p-4  ">
                                                                      Edit
                                                                      details
                                                                    </button>
                                                                  ),
                                                                  value: 1,
                                                                },
                                                              ]
                                                            : []),
                                                          ...(Method.hasPermission(
                                                            CustomerServices,
                                                            Delete,
                                                            currentUser
                                                          )
                                                            ? [
                                                                {
                                                                  label: (
                                                                    <button className="btn btn-link fs-14 fw-500 text-danger ms-3 p-4">
                                                                      {!deleteLoader && (
                                                                        <span className="indicator-label fs-14 fw-bold">
                                                                          Delete
                                                                          this
                                                                          member
                                                                        </span>
                                                                      )}
                                                                      {deleteLoader && (
                                                                        <span
                                                                          className="indicator-progress fs-16 fw-bold"
                                                                          style={{
                                                                            display:
                                                                              "block",
                                                                          }}
                                                                        >
                                                                          please
                                                                          wait...
                                                                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                        </span>
                                                                      )}{" "}
                                                                    </button>
                                                                  ),
                                                                  value: 2,
                                                                },
                                                              ]
                                                            : []),
                                                        ]}
                                                        backgroundColor="white"
                                                        show={
                                                          show && index === id
                                                        }
                                                        onMenuClose={() => {
                                                          onMenuClose();
                                                        }}
                                                        openMenuOnClick={() => {
                                                          openMenuOnClick(
                                                            index
                                                          );
                                                        }}
                                                        onMenuOpen={() => {
                                                          onMenuOpen(index);
                                                        }}
                                                        onChange={(
                                                          event: any
                                                        ) => {
                                                          handleOption(
                                                            event,
                                                            index,
                                                            val
                                                          );
                                                        }}
                                                      />
                                                    ) : (
                                                      <></>
                                                    )}
                                                    {/* <Button
                                                      variant="link"
                                                      className="btn-flush btn-sm ms-3"
                                                    >
                                                      <img
                                                        src={threeDots}
                                                        width={23}
                                                        height={7}
                                                        alt=""
                                                      />
                                                    </Button> */}
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </Card.Body>
                              </Card>
                              <>
                                <Pagination
                                  totalRecords={totalRecords}
                                  currentPage={page}
                                  handleCurrentPage={(event: any) => {
                                    handleCurrentPage(event);
                                  }}
                                  handleNextPage={(event: any) => {
                                    handleNextPage(event);
                                  }}
                                  handlePreviousPage={(event: any) => {
                                    handlePreviousPage(event);
                                  }}
                                  pageLimit={pageLimit}
                                  handlePageLimit={(event: any) => {
                                    handlePageLimit(event);
                                  }}
                                />
                              </>
                            </>
                          ) : (
                            <>
                              <div className="border border-r10px mt-6">
                                <div className="d-flex flex-center h-160px">
                                  <span className="fs-18 fw-500">
                                    A list of the customer service team members
                                    will be shown in this section.
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {" "}
                          <div className="border border-r10px mt-6">
                            <div className="d-flex flex-center h-160px">
                              <Loader loading={fetchLoader} />
                            </div>
                          </div>
                        </>
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
        {!fetchLoader && active === 2 && members.length ? (
          <>
            <Pagination
              totalRecords={totalRecords}
              currentPage={page}
              handleCurrentPage={(event: any) => {
                handleCurrentPage(event);
              }}
              handleNextPage={(event: any) => {
                handleNextPage(event);
              }}
              handlePreviousPage={(event: any) => {
                handlePreviousPage(event);
              }}
              pageLimit={pageLimit}
              handlePageLimit={(event: any) => {
                handlePageLimit(event);
              }}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default CustomerService;
