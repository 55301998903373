import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { CustomSelectTable } from '../../../custom/Select/CustomSelectTable';
import Loader from '../../../../Global/loader';
import APICallService from '../../../../api/apiCallService';
import {
  interTransferRequest,
  inventory,
  seller,
} from '../../../../api/apiEndPoints';
import threeDot from '../../../../_admin/assets/media/svg_uTrade/three-dot-round.svg';
import {
  Admin,
  AllModules,
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  Edit,
  GoodsRequest,
  Piece,
  TransferRequestCompleted,
  WarehouseInterTransfer,
} from '../../../../utils/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import Method from '../../../../utils/methods';
import TimelineForInterRequests from './transfer-request-timeline';
import SuccessActionModal from '../../../modals/success-action.';
import CommanCancelModal from '../../../modals/comman-cancel';
import { useAuth } from '../../auth';
import { getKey } from '../../../../Global/history';
import { warehouse as warehouseStoreString } from '../../../../utils/storeString';
import InstantOrderBatchInfo from '../../../modals/instant-order-batch-info';
const InterTransferDetail = () => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const navigate = useNavigate();
  // const [products, setProducts] = useState<any>();
  const { state }: any = useLocation();
  const [loading, setLoading] = useState(false);
  const [fetchLoader, setFetchLoader] = useState(true);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [goodsModalShow, setGoodsModalShow] = useState(false);
  const [batchInfo, setBatchInfo] = useState(false);
  const [details, setDetails] = useState<any>();
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showPickupModal, setShowPickupModal] = useState(false);
  const [showPickupStartModal, setShowPickupStartModal] = useState(false);
  const [showDeliverModal, setShowDeliverModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const { currentUser } = useAuth();
  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      // if (!state) {
      //   return window.history.back();
      // }
      await fetchDetails();
      setFetchLoader(false);
    })();
  }, []);
  const fetchDetails = async () => {
    let apiService = new APICallService(
      interTransferRequest.tranferDetail,
      state?._id || '',
      '',
      '',
      '',
      '',
      WarehouseInterTransfer,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    setDetails(response.record);
  };
  const openMenuOnClick = async () => {
    setShow(true);
  };
  const onMenuClose = async () => {
    setShow(false);
  };
  const onMenuOpen = async () => {
    setShow(true);
  };
  const products: any = [
    {
      variant: {
        title: 'Product A (Blue)',
        media: [{ url: 'https://via.placeholder.com/50' }],
        product: {
          business: { name: 'Business A' },
        },
        quantityTypes: [
          {
            amount: '10,000',
          },
        ],
      },
      totalAmount: '5,300,000',
      quantityDescription: '100 X Cartons',
    },
    {
      variant: {
        title: 'Product B (Red)',
        media: [{ url: 'https://via.placeholder.com/50' }],
        product: {
          business: { name: 'Business B' },
        },
        quantityTypes: [
          {
            amount: '15,000',
          },
        ],
      },
      totalAmount: '7,800,000',
      quantityDescription: '200 X Boxes',
    },
    {
      variant: {
        title: 'Product C (Green)',
        media: [{ url: 'https://via.placeholder.com/50' }],
        product: {
          business: { name: 'Business C' },
        },
        quantityTypes: [
          {
            amount: '12,500',
          },
        ],
      },
      totalAmount: '6,250,000',
      quantityDescription: '150 X Packets',
    },
    {
      variant: {
        title: 'Product D (Yellow)',
        media: [{ url: 'https://via.placeholder.com/50' }],
        product: {
          business: { name: 'Business D' },
        },
        quantityTypes: [
          {
            amount: '20,000',
          },
        ],
      },
      totalAmount: '10,000,000',
      quantityDescription: '300 X Bales',
    },
  ];
  const getQuantityTypes = (val: any) => {
    if (val == CartonWithDozens) {
      return 'Carton With Dozens';
    } else if (val == Dozen) {
      return 'Dozen';
    } else if (val == Piece) {
      return 'Piece';
    } else if (val == CartonWithPieces) {
      return 'Carton With Pieces';
    } else {
      return '';
    }
  };
  const getTotalAmount = (data: any) => {
    let total = 0;
    data.map((item: any) => {
      total += item?.totalAmount || 0;
    });
    return Method.getGeneralizedAmount(total);
  };
  const handleAcceptRequest = async () => {
    const apiCallService = new APICallService(
      interTransferRequest.changeStatus,
      {
        status: 2,
        warehouseTransferRequest: true,
      },
      {
        id: details._id,
      },
      '',
      '',
      '',
      WarehouseInterTransfer,
      tempWarehouse?._id
    );
    const response = await apiCallService.callAPI();
    if (response) {
      setShowAcceptModal(false);
      await fetchDetails();
    }
  };
  const handlePikcUpRequest = async () => {
    const apiCallService = new APICallService(
      interTransferRequest.changeStatus,
      {
        status: 5,
      },
      {
        id: details._id,
      },
      '',
      '',
      '',
      WarehouseInterTransfer,
      tempWarehouse?._id
    );
    const response = await apiCallService.callAPI();
    if (response) {
      setShowPickupModal(false);
      await fetchDetails();
    }
  };
  const handlePickUpStartedRequest = async () => {
    const apiCallService = new APICallService(
      interTransferRequest.changeStatus,
      {
        status: 8,
      },
      {
        id: details._id,
      },
      '',
      '',
      '',
      WarehouseInterTransfer,
      tempWarehouse?._id
    );
    const response = await apiCallService.callAPI();
    if (response) {
      setShowPickupStartModal(false);
      await fetchDetails();
    }
  };
  const handleDeliveredRequest = async () => {
    const apiCallService = new APICallService(
      interTransferRequest.changeStatus,
      {
        status: 6,
      },
      {
        id: details._id,
      },
      '',
      '',
      '',
      WarehouseInterTransfer,
      tempWarehouse?._id
    );
    const response = await apiCallService.callAPI();
    if (response) {
      setShowDeliverModal(false);
      await fetchDetails();
    }
  };
  const handleCancelRequest = async () => {
    const apiCallService = new APICallService(
      interTransferRequest.changeStatus,
      {
        status: 3,
      },
      {
        id: details._id,
      },
      '',
      '',
      '',
      WarehouseInterTransfer,
      tempWarehouse?._id
    );
    const response = await apiCallService.callAPI();
    if (response) {
      setShowCancelModal(false);
      await fetchDetails();
    }
  };
  const handleCompletedRequest = async () => {
    const apiCallService = new APICallService(
      interTransferRequest.changeStatus,
      {
        status: 7,
      },
      {
        id: details._id,
      },
      '',
      '',
      '',
      WarehouseInterTransfer,
      tempWarehouse?._id
    );
    const response = await apiCallService.callAPI();
    if (response) {
      setShowCompleteModal(false);
      await fetchDetails();
    }
  };
  const isRequestGetter = (details: any) => {
    if (details?.warehouse && details?.warehouse?.manager.length) {
      const user = details?.warehouse?.manager.find(
        (item: any) => item.reference == currentUser?._id
      );
      return user ? true : false;
    }
    return false;
  };
  const isRequestInitiater = (details: any) => {
    if (
      details?.receiverWarehouse &&
      details?.receiverWarehouse?.manager.length
    ) {
      const user = details?.receiverWarehouse?.manager.find(
        (item: any) => item.reference == currentUser?._id
      );
      return user ? true : false;
    }
    return false;
  };
  const handleAddToInventory = (data: any) => {
    let temp = { ...data };
    let variantTemp: any = [];
    temp.variants.map((val: any) => {
      variantTemp.push(val.variant._id);
    });
    if (temp.isGoodsRequest) {
      navigate('/inventory/add-goods-to-inventory', {
        state: details.shipment,
      });
    } else {
      navigate('/warehouse/all-inter-transfer-request/add-goods-to-inventory', {
        state: details._id,
      });
    }
  };
  const handleGoodsRequest = () => {
    let temp: any = { ...details };
    const groupedByBusiness: any = {};
    // Grouping data by business
    temp.businesses.forEach(async (business: any) => {
      const businessRef = business.reference;
      if (!groupedByBusiness[businessRef]) {
        groupedByBusiness[businessRef] = {
          shipment: {
            type: 2, // Example shipment type, replace as needed
          },
          variants: [],
          transferId: details._id,
          warehouseTransferRequest: true,
        };
      }
      // Iterate through all variants
      temp.variants.forEach((product: any) => {
        groupedByBusiness[businessRef].variants.push({
          variant: product.variant._id,
          quantityTypes: [
            {
              dimensions: product.dimensions,
              type: product.quantityType,
              amount: product.amount,
              discountAmtEnabled: false,
              discountByQuantitiesEnabled: false,
              discountsByQuantities: [],
              stockCount: product.stockCount.toString(), // Convert stockCount to string
            },
          ],
        });
      });
      // Convert the grouped data to an array
      const result = Object.values(groupedByBusiness);
      result.map(async (val) => {
        let apiService = new APICallService(
          inventory.sendShipmentRequest,
          val,
          {
            id: businessRef,
          },
          '',
          '',
          '',
          WarehouseInterTransfer,
          tempWarehouse?._id
        );
        let response = await apiService.callAPI();
        if (response) {
          navigate('/inventory/goods-requests');
        }
      });
    });
  };
  const getStatusForTimeline = (status: any) => {
    if (status == 8) return 3.5;
    return status;
  };
  const updateStatusArray = (statusArray: any, status: any) => {
    const temp: any = [...statusArray];
    const index = temp.findIndex((item: any) => item.status == 8);
    if (index != -1) {
      temp[index].status = 3.5;
    }
    // console.log("dddd", status, statusArray,index,temp[index]);
    // if (index != -1 && status == 8) {
    //   temp[index].status = 3.5;
    // }
    return temp;
  };
  return (
    <>
      {showAcceptModal ? (
        <SuccessActionModal
          show={showAcceptModal}
          onHide={() => setShowAcceptModal(false)}
          onSave={() => handleAcceptRequest()}
          title="Are you sure you want to accept this request "
        />
      ) : (
        <></>
      )}
      {details && batchInfo ? (
        <>
          <InstantOrderBatchInfo
            show={batchInfo}
            onClose={() => {
              setBatchInfo(!batchInfo);
            }}
            details={details}
          />
        </>
      ) : (
        <></>
      )}
      {showPickupModal ? (
        <SuccessActionModal
          show={showPickupModal}
          onHide={() => setShowPickupModal(false)}
          onSave={() => handlePikcUpRequest()}
          title="Are you sure you want to pick up this request "
        />
      ) : (
        <></>
      )}
      {showPickupStartModal ? (
        <SuccessActionModal
          show={showPickupStartModal}
          onHide={() => setShowPickupStartModal(false)}
          onSave={() => handlePickUpStartedRequest()}
          title="Are you sure you want to start pick up"
        />
      ) : (
        <></>
      )}
      {showDeliverModal ? (
        <SuccessActionModal
          show={showDeliverModal}
          onHide={() => setShowDeliverModal(false)}
          onSave={() => handleDeliveredRequest()}
          title="Are you sure you want to deliver "
        />
      ) : (
        <></>
      )}
      {showCancelModal ? (
        <CommanCancelModal
          show={showCancelModal}
          onHide={() => setShowCancelModal(false)}
          onSave={() => handleCancelRequest()}
          title="Are you sure you want to cancel this inter transfer request ?"
          warnMessage={`This request is initiated for ${
            details?.warehouse?.name || ''
          }, and goods are provided to ${
            details?.receiverWarehouse?.name || ''
          }.`}
          btnTitle="Yes,cancel request"
        />
      ) : (
        <></>
      )}
      {showCompleteModal ? (
        <SuccessActionModal
          show={showCompleteModal}
          onHide={() => setShowCompleteModal(false)}
          onSave={() => handleAddToInventory(details)}
          title="Are you sure you want to complete this request "
        />
      ) : (
        <></>
      )}
      <div className="p-9">
        <Row className="align-items-center">
          <Col
            xs={12}
            className="mb-5"
          >
            <div className="d-flex flex-wrap justify-content-between align-items-center">
              <h1 className="fs-22 fw-bolder">
                Inter transfer request details
              </h1>
              {/* <CustomSelectTable
              marginLeft={'0px'}
              menuMargin={'-60px'}
              width={'140px'}
              placeholder={
                <img
                  className="img-fluid"
                  width={47}
                  height={47}
                  src={threeDot}
                  alt=""
                />
              }
              options={[
                // {
                //   label: (
                //     <button className="btn btn-link fs-14 fw-500 text-black ms-3 p-4">
                //       Edit request details
                //     </button>
                //   ),
                //   value: 1,
                // },
                {
                  label: (
                    <button
                      onClick={() => setModalShow(true)}
                      className="btn btn-link fs-14 fw-500 text-danger ms-3 p-4"
                    >
                      Cancel this request
                    </button>
                  ),
                  value: 1,
                },
              ]}
              backgroundColor="white"
              show={show}
              onMenuClose={() => {
                setShow(false);
              }}
              openMenuOnClick={() => {
                setShow(true);
              }}
              onMenuOpen={() => {
                setShow(true);
              }}
              isOptionDisabled={(option: any) => option.value === 3}
            /> */}
              {!fetchLoader ? (
                <div>
                  {currentUser?.userType == Admin &&
                  details?.status == 1 &&
                  !details?.isGoodsRequest ? (
                    <>
                      <Button
                        className="me-2"
                        onClick={() => {
                          // handleGoodsRequest();
                          navigate(
                            '/warehouse/all-inter-transfer-request/send-goods-seller',
                            {
                              state: details,
                            }
                          );
                        }}
                      >
                        Create Goods Request
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                  {details.transferredStockDetails.length &&
                  isRequestGetter(details) ? (
                    <Button
                      variant="primary"
                      className="btn-lg me-2"
                      onClick={() => {
                        setBatchInfo(true);
                      }}
                    >
                      <span className="indicator-label fs-16 fw-bold">
                        View Batch Details
                      </span>
                    </Button>
                  ) : (
                    <></>
                  )}
                  {(currentUser?.userType == Admin ||
                    isRequestGetter(details)) &&
                  details?.status == 1 &&
                  !details?.isGoodsRequest &&
                  Method.hasPermission(
                    WarehouseInterTransfer,
                    Edit,
                    currentUser
                  ) ? (
                    <>
                      <Button
                        className="me-2"
                        onClick={() => setShowAcceptModal(true)}
                      >
                        Accept
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                  {details?.status == 1 &&
                  details?.isGoodsRequest !== true &&
                  Method.hasPermission(
                    WarehouseInterTransfer,
                    Edit,
                    currentUser
                  ) ? (
                    // <>
                    <Button
                      variant="danger"
                      onClick={() => setShowCancelModal(true)}
                    >
                      Cancel
                    </Button>
                  ) : (
                    // </>
                    <></>
                  )}
                  {(currentUser?.userType == Admin ||
                    isRequestGetter(details)) &&
                  details?.status == 8 &&
                  details?.isGoodsRequest !== true &&
                  Method.hasPermission(
                    WarehouseInterTransfer,
                    Edit,
                    currentUser
                  ) ? (
                    <Button
                      className="ms-2 me-2"
                      onClick={() => setShowPickupModal(true)}
                    >
                      Out for delivery
                    </Button>
                  ) : (
                    <></>
                  )}
                  {(currentUser?.userType == Admin ||
                    isRequestGetter(details)) &&
                  details?.status == 2 &&
                  details?.isGoodsRequest !== true &&
                  Method.hasPermission(
                    WarehouseInterTransfer,
                    Edit,
                    currentUser
                  ) ? (
                    <Button
                      className="me-2"
                      onClick={() => setShowPickupStartModal(true)}
                    >
                      Pickup Started
                    </Button>
                  ) : (
                    <></>
                  )}
                  {(currentUser?.userType == Admin ||
                    isRequestGetter(details)) &&
                  details?.status == 5 &&
                  details?.isGoodsRequest !== true &&
                  Method.hasPermission(
                    WarehouseInterTransfer,
                    Edit,
                    currentUser
                  ) ? (
                    <Button
                      className="me-2"
                      onClick={() => setShowDeliverModal(true)}
                    >
                      Delivered
                    </Button>
                  ) : (
                    <></>
                  )}
                  {(details?.status == 6 || details?.status == 5) &&
                  details?.status !== TransferRequestCompleted &&
                  currentUser?.userType !== Admin &&
                  isRequestInitiater(details) &&
                  Method.hasPermission(
                    WarehouseInterTransfer,
                    Edit,
                    currentUser
                  ) ? (
                    <Button
                      className="me-2"
                      onClick={() => handleAddToInventory(details)}
                    >
                      Complete
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </Col>
          {!fetchLoader ? (
            <>
              {' '}
              <Col xs={12}>
                <Row>
                  <Col>
                    <Card className="border mb-7">
                      <div
                        className=" bg-light d-flex align-items-center justify-content-between px-7"
                        style={{ height: '75px', borderRadius: '10px' }}
                      >
                        <span className="fs-22 fw-bolder"> Goods Details </span>
                        {details.isGoodsRequest ? (
                          <span className="badge bg-fff4d9 fs-14 fw-600 text-dark p-3 px-4">
                            Goods Request Sent by{' '}
                            {details.requestConvertedBy.name}
                          </span>
                        ) : (
                          <></>
                        )}
                        {/* <div
                        className="bg-e7f1fd d-flex align-items-center p-5 fw-bold"
                        style={{
                          height: '35px',
                          color: '#1b74e4',
                          borderRadius: '5px',
                        }}
                      >
                        Request created by admin
                      </div> */}
                      </div>
                      <Card.Body className="px-7">
                        <Row>
                          <Col lg={6}>
                            <Row className="mb-5">
                              <Col
                                md={5}
                                xs={4}
                              >
                                <label className=" fs-16 fw-500 text-dark">
                                  Request initiated by:
                                </label>
                              </Col>
                              <Col
                                md={7}
                                xs={8}
                              >
                                <span className="fw-bold fs-16 fw-600 text-dark">
                                  {details?.receiverWarehouse?.name || ''}
                                </span>
                              </Col>
                            </Row>
                            <Row className="mb-5">
                              <Col
                                md={5}
                                xs={4}
                              >
                                <label className=" fs-16 fw-500 text-dark">
                                  Request initiated for:
                                </label>
                              </Col>
                              <Col
                                md={7}
                                xs={8}
                              >
                                <span className="fw-bold fs-16 fw-600 text-dark">
                                  {details?.warehouse?.name || ''}
                                </span>
                              </Col>
                            </Row>
                            <Row className="mb-5">
                              <Col
                                md={5}
                                xs={4}
                              >
                                <label className=" fs-16 fw-500 text-dark">
                                  Request ID:
                                </label>
                              </Col>
                              <Col
                                md={7}
                                xs={8}
                              >
                                <span className="fw-bold fs-16 fw-600 text-dark">
                                  {details?.refKey || ''}
                                </span>
                              </Col>
                            </Row>
                            <Row className="mb-5">
                              <Col
                                md={5}
                                xs={4}
                              >
                                <label className=" fs-16 fw-500 text-dark">
                                  Request initiated on:
                                </label>
                              </Col>
                              <Col
                                md={7}
                                xs={8}
                              >
                                <span className="fw-bold fs-16 fw-600 text-dark">
                                  {details?._createdAt
                                    ? Method.convertDateToDDMMYYYYHHMM(
                                        details._createdAt
                                      )
                                    : '-'}
                                </span>
                              </Col>
                            </Row>
                            <Row className="mb-5">
                              <Col
                                md={5}
                                xs={4}
                              >
                                <label className=" fs-16 fw-500 text-dark">
                                  Quantity:
                                </label>
                              </Col>
                              <Col
                                md={7}
                                xs={8}
                              >
                                <span className="fw-bold fs-16 fw-600 text-dark">
                                  {details?.variants.map(
                                    (item: any, index: number) => {
                                      return `${
                                        item?.stockCount
                                      } ${getQuantityTypes(
                                        item?.quantityType
                                      )}${
                                        index < details?.variants.length-1
                                          ? ', '
                                          : ' '
                                      }`;
                                    }
                                  )}
                                </span>
                              </Col>
                            </Row>
                            <Row className="mb-5">
                              <Col
                                md={5}
                                xs={4}
                              >
                                <label className=" fs-16 fw-500 text-dark">
                                  Shipment Weight:
                                </label>
                              </Col>
                              <Col
                                md={7}
                                xs={8}
                              >
                                <span className="fw-bold fs-16 fw-600 text-dark">
                                  {details?.variants.reduce(
                                    (total: any, val: any) => {
                                      return total + val?.totalWeight || 0;
                                    },
                                    0
                                  )}{' '}
                                  Ton
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                md={5}
                                xs={4}
                              >
                                <label className=" fs-16 fw-500 text-dark">
                                  Goods Total amount:
                                </label>
                              </Col>
                              <Col
                                md={7}
                                xs={8}
                              >
                                <span className="fw-bold fs-16 fw-600 text-dark">
                                  Tsh {getTotalAmount(details?.variants || [])}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                <div className="mb-7">
                  <TimelineForInterRequests
                    status={getStatusForTimeline(details?.status)}
                    statusesLogs={updateStatusArray(
                      details?.statusesLogs,
                      details?.status
                    )}
                    details={details}
                  />
                </div>
              </Col>
              <Col xs={12}>
                <Card className="border mb-7">
                  <Card.Body className="px-7">
                    <div className="table-responsive">
                      <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4 mb-0">
                        <thead>
                          <tr className="fw-bold fs-16 fw-600 text-dark border-bottom h-70px align-middle">
                            <th className="min-w-300px">Product Name</th>
                            <th className="min-w-200px">Unit Price</th>
                            <th className="min-w-200px">Qty.& Type</th>
                            <th className="min-w-200px">Total amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            <tr>
                              <td colSpan={4}>
                                <div className="d-flex justify-content-center">
                                  <Loader />
                                </div>
                              </td>
                            </tr>
                          ) : (
                            details?.variants &&
                            details?.variants.length > 0 &&
                            details?.variants.map(
                              (productVal: any, index: number) => (
                                <tr key={index}>
                                  <td>
                                    <div className="d-flex align-items-center gap-5">
                                      <img
                                        height={50}
                                        width={50}
                                        src={productVal.variant.media[0].url}
                                        className="object-fit-contain"
                                        alt=""
                                      />
                                      <div className="d-flex flex-column">
                                        <span className="fs-15 fw-600">
                                          {productVal.variant.title.replace(
                                            /\s*\)\s*/g,
                                            ')'
                                          )}
                                        </span>
                                        <span className="fs-14 fw-500">
                                          {
                                            productVal.variant.product.business
                                              .name
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-600">
                                      Tsh {productVal.amount}
                                    </span>
                                  </td>
                                  <td>
                                    <span className="fs-15 fw-600">
                                      {/* 100 X Cartons */}
                                      {`${
                                        productVal?.stockCount
                                      } X ${getQuantityTypes(
                                        productVal?.quantityType
                                      )}`}
                                    </span>
                                  </td>
                                  <td className="fs-15 fw-600">
                                    <span>
                                      Tsh {productVal?.totalAmount || '0'}
                                    </span>
                                  </td>
                                </tr>
                              )
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </>
          ) : (
            <div className="w-100 d-flex justify-content-center">
              <Loader loading={fetchLoader} />
            </div>
          )}
          {/* <InterTransferRequestRejectModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        <GoodsReceived
          show={goodsModalShow}
          handleGoodsReceived={handleGoodsReceived}
          onHide={() => setGoodsModalShow(false)}
        /> */}
        </Row>
      </div>
    </>
  );
};
export default InterTransferDetail;
