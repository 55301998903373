import { Button, Card, Col, Form, Row } from "react-bootstrap";
import GrayClose from "../../../../_admin/assets/media/svg_uTrade/cross_gray.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import APICallService from "../../../../api/apiCallService";
import { interTransferRequest, inventory } from "../../../../api/apiEndPoints";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../custom/DateRange/dateRange.css";
import VerifiedPro from "../../../../_admin/assets/media/svg_uTrade/verified.svg";
import BatchIcon from "../../../../_admin/assets/media/product/batch.png";
import ThreeDotMenu from "../../../../_admin/assets/media/svg_uTrade/three-dot.svg";
import {
  Accepted,
  All,
  All_Products,
  ApprovedProduct,
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  Edit,
  GoodsRequest,
  NewRequests,
  Piece,
  Rejected,
  ShipmentCompleted,
  TransferRequestCompleted,
  UnderReview,
  View,
  WarehouseInterTransfer,
} from "../../../../utils/constants";
import { error, success } from "../../../../Global/toast";
import Loader from "../../../../Global/loader";
import { CustomSelectTable } from "../../../custom/Select/CustomSelectTable";
import Method from "../../../../utils/methods";
import { useAuth } from "../../auth";
import { getKey } from "../../../../Global/history";
import { warehouse as warehouseStoreString } from "../../../../utils/storeString";
const AddGoodsToInventory = () => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [show, setShow] = useState(false);
  const { state }: any = useLocation();
  const [sellerData, setSellerData] = useState<any>([]);
  const [requestId, setRequestId] = useState<any>();
  const [missingStock, setMissingStock] = useState<any>([]);
  const [validationMessage, setValidationMessage] = useState<any>([]);
  const [sellerProducts, setSellerProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState<any>();
  const [products, setProducts] = useState<any>([]);
  const [productValidation, setProductValidation] = useState<
    {
      variant: boolean;
      quantityTypes: boolean;
    }[]
  >([
    {
      variant: false,
      quantityTypes: false,
    },
  ]);
  const [initLoader, setInitLoader] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [initData, setInitData] = useState<any>([]);
  const [batchValidation, setBatchValidation] = useState<
    {
      quantityTypes: boolean;
    }[]
  >([
    {
      quantityTypes: false,
    },
  ]);
  useEffect(() => {
    (async () => {
      setInitLoader(true);
      await setRequestId(state);
      await fetchSeller();
      setInitLoader(false);
    })();
  }, []);
  function addBatchToVariants(variants: any, batches: any) {
    return variants.map((variant: any) => {
      const batchInfo = batches.find(
        (batch: any) => batch.variant === variant.variant._id
      );
      if (batchInfo) {
        return {
          ...variant,
          batchNo: batchInfo.batch,
          batch: [
            {
              batch: batchInfo.batch,
              expiry: new Date(),
              quantityTypes: variant.quantityTypes,
            },
          ],
        };
      } else {
        return {
          ...variant,
          batchNo: 1,
          batch: [
            {
              batch: 1,
              expiry: new Date(),
              quantityTypes: variant.quantityTypes,
            },
          ],
        };
      }
    });
  }
  const fetchSeller = async () => {
    let apiService = new APICallService(
      interTransferRequest.infoInventory,
      state,
      "",
      "",
      "",
      "",
      WarehouseInterTransfer,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    let temp = JSON.parse(JSON.stringify({ ...response }));
    let variantsTemp: any = [];
    temp.record.variants.map((val: any) => {
      variantsTemp.push({
        ...val,
        quantityTypes: [{ type: val.quantityType, stockCount: val.stockCount }],
      });
    });
    let variants = addBatchToVariants(variantsTemp, temp.batches);
    const arr = Array.from({ length: variants.length }, (_, index) => ({
      messages: "",
    }));
    setValidationMessage(arr);
    setProducts(variants);
    setInitData(JSON.parse(JSON.stringify([...variants])));
  };
  const findObjectById = (array: any, targetId: string) => {
    for (const item of array) {
      if (item.variant._id === targetId) {
        return item;
      }
    }
    return null; // Return null if no match is found
  };
  const handleCountChange = (
    index: number,
    batchIndex: number,
    quantityIndex: number,
    newValue: string
  ) => {
    let temp: any = JSON.parse(JSON.stringify([...products]));
    if (newValue) {
      if (newValue.startsWith("0")) {
        newValue = newValue.slice(1);
      }
      temp[index].batch[batchIndex].quantityTypes[quantityIndex].stockCount =
        newValue;
    } else {
      temp[index].batch[batchIndex].quantityTypes[quantityIndex].stockCount = 0;
    }
    updateStockCounts(temp[index]);
    compareStockCounts(initData, temp);
    setProducts(temp);
  };
  const handleAddMore = () => {
    if (products.length) {
      let productTemp = [...products];
      let productValidationTemp = [...productValidation];
      const allObjectsHaveQuantityTypeWithStock = productTemp.every(
        (obj: any) =>
          obj.quantityTypes.some(
            (quantityType: any) =>
              quantityType.stockCount && quantityType.stockCount > 0
          )
      );
      if (allObjectsHaveQuantityTypeWithStock) {
        productTemp.push({
          variant: "",
          quantityTypes: [],
          batch: [],
        });
        productValidationTemp.push({
          variant: false,
          quantityTypes: false,
        });
      } else {
        productTemp.forEach((product: any, index: number) => {
          const hasStockCountGreaterThanZero = product.quantityTypes.some(
            (quantityType: any) =>
              quantityType.stockCount !== undefined &&
              quantityType.stockCount > 0
          );
          if (!hasStockCountGreaterThanZero) {
            productValidationTemp[index].quantityTypes = true;
            error("At least one stock must have a value greater than 0.");
          } else {
          }
        });
      }
      setProductValidation(productValidationTemp);
      setProducts(productTemp);
    } else {
    }
  };
  const handleBatchAddMore = (productIndex: number) => {
    let temp = [...products];
    let batchValidationTemp = [...batchValidation];
    const allObjectsHaveQuantityTypeWithStock = temp[productIndex].batch.every(
      (obj: any) =>
        obj.quantityTypes.some(
          (quantityType: any) =>
            quantityType.stockCount && quantityType.stockCount > 0
        )
    );
    if (allObjectsHaveQuantityTypeWithStock) {
      const previousBatchQuantityType = getPreviousBatchQuantityType(
        temp[productIndex]
      );
      const nextBatchNumber = getNextBatchNumber(temp[productIndex]);
      const newQuantityTypes = temp[productIndex].batch[0].quantityTypes.map(
        (quantityType: any) => ({ ...quantityType, stockCount: "0" })
      );
      temp[productIndex].batch.push({
        batch: nextBatchNumber,
        quantityTypes: newQuantityTypes,
        expiry: new Date(),
      });
      temp.forEach((object: any) => {
        updateStockCounts(object);
      });
    } else {
      temp[productIndex].batch.forEach((product: any, index: number) => {
        const hasStockCountGreaterThanZero = product.quantityTypes.some(
          (quantityType: any) =>
            quantityType.stockCount !== undefined &&
            parseInt(quantityType.stockCount) > 0
        );
        if (!hasStockCountGreaterThanZero) {
          // batchValidationTemp[index].quantityTypes = true;
          error("At least one stock must have a value greater than 0.");
        }
      });
    }
    setBatchValidation(batchValidationTemp);
    setProducts(temp);
  };
  const handleBatchRemove = (productIndex: number, batchIndex: number) => {
    let tempProducts: any = [...products];
    let tempBatchValidation = [...batchValidation];
    // Remove the batch at the specified batchIndex from the product's batches array
    tempProducts[productIndex].batch.splice(batchIndex, 1);
    const findObject = findObjectById(
      initData,
      tempProducts[productIndex].variant._id
    );
    if (findObject) {
      let newBatchNumber: any = findObject.batchNo;
      tempProducts[productIndex].batch.forEach((batch: any) => {
        batch.batch = newBatchNumber;
        newBatchNumber++;
      });
    } else {
      tempProducts[productIndex].batch.forEach((batch: any, index: number) => {
        batch.batch = index + 1;
      });
    }
    setProducts(tempProducts);
    setBatchValidation(tempBatchValidation);
  };
  const getPreviousBatchQuantityType = (obj: any) => {
    const lastBatch = obj.batch[obj.batch.length - 1];
    const lastQuantityType =
      lastBatch.quantityTypes[lastBatch.quantityTypes.length - 1];
    return {
      type: lastQuantityType.type,
      stockCount: "0",
    };
  };
  const getNextBatchNumber = (obj: any) => {
    const lastBatch = obj.batch[obj.batch.length - 1];
    return lastBatch.batch + 1;
  };
  const handleRemove = (indexToRemove: number) => {
    let productTemp = [...products];
    let productValidationTemp = [...productValidation];
    productValidation.splice(indexToRemove, 1);
    productTemp.splice(indexToRemove, 1);
    setProducts(productTemp);
    setProductValidation(productValidationTemp);
  };
  const handleExpiryChange = (index: number, batchIndex: number, date: any) => {
    let temp = [...products];
    temp[index].batch[batchIndex] = {
      ...temp[index].batch[batchIndex],
      expiry: date,
    };
    setProducts(temp);
  };
  function updateStockCounts(product: any) {
    const quantityTypesMap = new Map();
    let temp: any = product;
    // Calculate the cumulative total stock count for each type across all batches
    product.batch.forEach((batch: any) => {
      batch.quantityTypes.forEach((quantityType: any) => {
        const type = quantityType.type;
        const stockCount = parseInt(quantityType.stockCount);
        if (quantityTypesMap.has(type)) {
          const currentStockCount = quantityTypesMap.get(type);
          quantityTypesMap.set(type, stockCount);
          quantityTypesMap.set(type, currentStockCount + stockCount);
        } else {
          quantityTypesMap.set(type, stockCount);
        }
      });
    });
    // Update the stockCount property for each quantityType based on the calculated cumulative total
    product.quantityTypes.forEach((quantityType: any) => {
      const type = quantityType.type;
      const totalStockCount = quantityTypesMap.get(type);
      const currentStockCount = parseInt(quantityType.stockCount);
      quantityType.stockCount = totalStockCount.toString();
    });
  }
  const handleSubmit = async () => {
    setLoading(true);
    let productTemp = [...products];
    let productValidationTemp = [...productValidation];
    const allObjectsHaveQuantityTypeWithStock =
      productTemp.every((obj: any) =>
        obj.quantityTypes.some(
          (quantityType: any) =>
            quantityType.stockCount && quantityType.stockCount > 0
        )
      ) &&
      productTemp.every((obj: any) =>
        obj.batch.every((batch: any) =>
          batch.quantityTypes.some(
            (quantityType: any) =>
              quantityType.stockCount && quantityType.stockCount > 0
          )
        )
      );
    if (allObjectsHaveQuantityTypeWithStock) {
      if (compareStockCounts(initData, productTemp)) {
        const filteredData = productTemp.map((item: any) => {
          const filteredQuantityTypes = item.quantityTypes.filter(
            (qType: any) => qType.stockCount !== "0"
          );
          const filteredBatch = item.batch.map((batchItem: any) => ({
            ...batchItem,
            expiry: new Date(batchItem.expiry).toISOString(),
            quantityTypes: batchItem.quantityTypes.filter(
              (qType: any) => qType.stockCount !== "0"
            ),
          }));
          return {
            ...item,
            quantityTypes: filteredQuantityTypes,
            batch: filteredBatch,
          };
        });
        if (filteredData.length) {
          const splitBatches = splitBatchesWithVariantId(filteredData);
          let apiService = new APICallService(
            interTransferRequest.changeStatus,
            { status: TransferRequestCompleted, variants: splitBatches },
            {
              id: requestId,
            },
            "",
            "",
            "",
            WarehouseInterTransfer,
            tempWarehouse?._id
          );
          let response = await apiService.callAPI();
          if (response) {
            success("Inventory updated successfully");
            window.history.back();
          }
        }
      }
    } else {
      error("At least one stock must have a value greater than 0.");
    }
    setProductValidation(productValidationTemp);
    setProducts(productTemp);
    setLoading(false);
  };
  const splitBatchesWithVariantId = (data: any) => {
    const result: any = [];
    data.forEach((product: any) => {
      const { variant, batch } = product;
      batch.forEach((batchItem: any) => {
        const quantityTypes = batchItem.quantityTypes
          .map((quantityType: any) => {
            const stockCountInt = parseInt(quantityType.stockCount);
            return stockCountInt > 0
              ? { ...quantityType, stockCount: stockCountInt }
              : null;
          })
          .filter((quantityType: any) => quantityType !== null);
        const batchWithVariantId = {
          variant: variant._id,
          quantityTypes: quantityTypes,
          expiry: batchItem.expiry,
          batch: batchItem.batch,
        };
        result.push(batchWithVariantId);
      });
    });
    return result;
  };
  function compareStockCounts(initial: any, updated: any) {
    let temp = [...validationMessage];
    if (initial.length && updated.length) {
      for (let i = 0; i < updated.length; i++) {
        const quantityTypes1 = initial[i].quantityTypes;
        const quantityTypes2 = updated[i].quantityTypes;
        for (let j = 0; j < quantityTypes1.length; j++) {
          if (quantityTypes2[j].stockCount > quantityTypes1[j].stockCount) {
            temp[
              i
            ].message = `Stock count must be equals to ${quantityTypes1[j].stockCount}.`;
            return false;
          }
        }
        temp[i].message = ``;
        return true;
      }
    }
  }
  useEffect(() => {
    if (products && products.length) {
      checkStockCount(products);
    }
  }, [products]);
  const checkStockCount = (data: any) => {
    let missingData: any = [];
    let added = [];
    data.forEach((batch: any, index: number) => {
      const stockCountMap = new Map();
      batch.quantityTypes.forEach((quantityType: any) => {
        const { type, stockCount } = quantityType;
        const count = stockCount === "" ? 0 : stockCount;
        const parsedStockCount = parseInt(count);
        if (!isNaN(parsedStockCount)) {
          if (stockCountMap.has(type)) {
            stockCountMap.set(type, stockCountMap.get(type) + parsedStockCount);
          } else {
            stockCountMap.set(type, parsedStockCount);
          }
        }
      });
      const missingStock: any = initData[index].quantityTypes
        .map((quantityType: any) => {
          const { type, stockCount } = quantityType;
          const mappedType = type; // Map type 4 to type 1
          const totalStockCount = stockCountMap.get(mappedType) || 0;
          const missing = stockCount - totalStockCount;
          missingData.push({
            type,
            missing: missing < 0 ? 0 : missing,
            variant: batch.variant._id,
          });
          return {
            type,
            missing: missing < 0 ? 0 : missing,
            variant: batch.variant._id,
          };
        })
        .filter((item: any) => item.missing > 0); // Exclude items with missing count 0
    });
    setMissingStock(missingData);
  };
  const openMenuOnClick = async (id: any) => {
    setId(id);
    setShow(true);
    // navigate('/master/edit-business-certificates', { state: data })
  };
  const onMenuClose = async () => {
    setId("");
    setShow(false);
  };
  const onMenuOpen = async (id: any) => {
    setId(id);
    setShow(true);
  };
  const handleOption = async (event: any, data: any, index: number) => {
    if (event.value === 1) {
      navigate("/products/edit-product-variant", {
        state: {
          _id: data.variant._id,
          isMaster: false,
          isInventory: true,
          moduleName: GoodsRequest,
        },
      });
    } else if (event.value === 2) {
      navigate("/all-products/product-details", {
        state: { id: data.variant._id },
      });
    } else if (event.value === 0) {
    }
  };
  return (
    <div className="p-9">
      <div className="mb-7">
        <h1 className="fs-22 fw-bolder">Add goods to inventory</h1>
      </div>
      {!initLoader ? (
        <>
          {Object.keys(products).length ? (
            <>
              <Card className="border border-r10px  mb-7">
                <Card.Body className="py-5">
                  <div className="table-responsive">
                    <table className="table align-middle gs-0 gy-3 mb-0">
                      <thead>
                        <tr className="fs-16 fw-600 border-bottom">
                          <th className="min-w-150px text-start">
                            Product name
                          </th>
                          <th className="w-144px text-center">Expires on</th>
                          <th className="w-100px text-center">Cartons</th>
                          <th className="w-100px text-center">Dozens</th>
                          <th className="w-100px text-center">Pieces</th>
                          <th className="w-15px text-end"></th>
                        </tr>
                      </thead>
                      <tbody className="mt-10">
                        {products.length ? (
                          <>
                            {products.map((val: any, index: number) => {
                              return (
                                <>
                                  <tr>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <div className="symbol symbol-50px border me-3">
                                          <img
                                            src={val.variant.media[0].url}
                                            className="object-fit-contain"
                                            alt=""
                                          />
                                        </div>
                                        <span className="fs-15 fw-600">
                                          {val.variant.title.replace(
                                            /\s*\)\s*/g,
                                            ")"
                                          )}
                                        </span>
                                      </div>
                                    </td>
                                    <td className="text-center">
                                      <span className="fw-600 fs-15">-</span>
                                    </td>
                                    <td className="text-center">
                                      {initData[index].quantityTypes.some(
                                        (item: any, idx: number) =>
                                          item.type === CartonWithDozens ||
                                          item.type === CartonWithPieces
                                      ) ? (
                                        <span className="fw-600 fs-15">
                                          {
                                            initData[index].quantityTypes.find(
                                              (item: any) =>
                                                item.type ===
                                                  CartonWithDozens ||
                                                item.type === CartonWithPieces
                                            ).stockCount
                                          }{" "}
                                        </span>
                                      ) : (
                                        <span className="fw-600 fs-15">-</span>
                                      )}
                                    </td>
                                    <td className="text-center">
                                      {initData[index].quantityTypes.some(
                                        (item: any, idx: number) =>
                                          item.type === Dozen
                                      ) ? (
                                        <span className="fw-600 fs-15">
                                          {
                                            initData[index].quantityTypes.find(
                                              (item: any) => item.type === Dozen
                                            ).stockCount
                                          }{" "}
                                        </span>
                                      ) : (
                                        <span className="fw-600 fs-15">-</span>
                                      )}
                                    </td>
                                    <td className="text-center">
                                      {initData[index].quantityTypes.some(
                                        (item: any, idx: number) =>
                                          item.type === Piece
                                      ) ? (
                                        <span className="fw-600 fs-15">
                                          {
                                            initData[index].quantityTypes.find(
                                              (item: any) => item.type === Piece
                                            ).stockCount
                                          }{" "}
                                        </span>
                                      ) : (
                                        <span className="fw-600 fs-15">-</span>
                                      )}
                                    </td>
                                    <td className="text-end">
                                      {products.length > 1 ? (
                                        <Button
                                          variant="link"
                                          className="btn-flush"
                                          onClick={() => {
                                            handleRemove(index);
                                          }}
                                          disabled={loading}
                                        >
                                          <img
                                            className="w-17px h-17px"
                                            src={GrayClose}
                                            alt=""
                                          />
                                        </Button>
                                      ) : (
                                        <Button
                                          variant=""
                                          className="btn-flush w-17px h-17px"
                                          disabled={loading}
                                        ></Button>
                                      )}
                                      {/* {Method.hasPermission(
                                        All_Products,
                                        Edit,
                                        currentUser
                                      ) ||
                                      Method.hasPermission(
                                        All_Products,
                                        View,
                                        currentUser
                                      ) ? (
                                        <CustomSelectTable
                                          marginLeft={"-90px"}
                                          placeholder={
                                            <img
                                              className="me-3"
                                              width={18}
                                              height={5}
                                              src={ThreeDotMenu}
                                              alt=""
                                            />
                                          }
                                          backgroundColor="white"
                                          default={defaultValue}
                                          options={[
                                            ...(Method.hasPermission(
                                              All_Products,
                                              Edit,
                                              currentUser
                                            )
                                              ? [
                                                  {
                                                    label: (
                                                      <button className="btn btn-link fs-14 fw-500 text-black ms-3 p-4">
                                                        Edit details
                                                      </button>
                                                    ),
                                                    value: 1,
                                                  },
                                                ]
                                              : []),
                                            ...(Method.hasPermission(
                                              All_Products,
                                              View,
                                              currentUser
                                            )
                                              ? [
                                                  {
                                                    label: (
                                                      <button className="btn btn-link fs-14 fw-500 text-danger ms-3 p-4">
                                                        View details
                                                      </button>
                                                    ),
                                                    value: 2,
                                                  },
                                                ]
                                              : []),
                                          ]}
                                          show={show && val.variant._id === id}
                                          onMenuClose={() => {
                                            onMenuClose();
                                          }}
                                          openMenuOnClick={() => {
                                            openMenuOnClick(val.variant._id);
                                          }}
                                          onMenuOpen={() => {
                                            onMenuOpen(val.variant._id);
                                          }}
                                          onChange={(event: any) => {
                                            handleOption(event, val, index);
                                          }}
                                          isOptionDisabled={(option: any) =>
                                            option.value === 3
                                          }
                                        />
                                      ) : (
                                        <></>
                                      )} */}
                                    </td>
                                  </tr>
                                  <>
                                    {val.batch.length ? (
                                      <>
                                        {val.batch.map(
                                          (
                                            batchVal: any,
                                            batchIndex: number
                                          ) => {
                                            return (
                                              <>
                                                <tr>
                                                  <td>
                                                    <div className="d-flex align-items-center">
                                                      <div className="symbol symbol-50px border me-3">
                                                        <img
                                                          className="img-fluid border-r8px object-fit-contain"
                                                          src={BatchIcon}
                                                          alt=""
                                                        />
                                                      </div>
                                                      <span className="fs-15 fw-600">
                                                        Batch {batchVal.batch}
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td className="text-center">
                                                    {/* <Form.Control
                                                className="form-control-custom bg-white fs-14 fw-600 min-w-192px min-h-60px px-3 text-center"
                                                placeholder="Select date"
                                              /> */}
                                                    <DatePicker
                                                      className="form-control-custom bg-white fs-14 fw-600 min-w-192px min-h-60px px-3 text-center"
                                                      selected={
                                                        new Date(
                                                          batchVal.expiry
                                                        )
                                                      }
                                                      onChange={(date) =>
                                                        handleExpiryChange(
                                                          index,
                                                          batchIndex,
                                                          date
                                                        )
                                                      }
                                                      disabled={loading}
                                                      selectsStart
                                                      minDate={new Date()}
                                                      placeholderText="Select date"
                                                      fixedHeight
                                                      dateFormat={"dd/MM/yyyy"}
                                                    />
                                                  </td>
                                                  <td className="text-center">
                                                    <Form.Control
                                                      className="form-control-custom bg-white fs-14 fw-600 w-88px min-h-60px px-3 text-center"
                                                      value={
                                                        batchVal.quantityTypes.find(
                                                          (item: any) =>
                                                            item.type ===
                                                              CartonWithDozens ||
                                                            item.type ===
                                                              CartonWithPieces
                                                        )?.stockCount ?? 0
                                                      }
                                                      disabled={
                                                        !batchVal.quantityTypes.some(
                                                          (item: any) =>
                                                            item.type ===
                                                              CartonWithDozens ||
                                                            item.type ===
                                                              CartonWithPieces
                                                        ) || loading
                                                      }
                                                      type="number"
                                                      onChange={(event) => {
                                                        handleCountChange(
                                                          index,
                                                          batchIndex,
                                                          batchVal.quantityTypes.findIndex(
                                                            (item: any) =>
                                                              item.type ===
                                                                CartonWithDozens ||
                                                              item.type ===
                                                                CartonWithPieces
                                                          ),
                                                          event.target.value
                                                        );
                                                      }}
                                                    />
                                                  </td>
                                                  <td className="text-center">
                                                    <Form.Control
                                                      className="form-control-custom bg-white fs-14 fw-600 w-88px min-h-60px px-3 text-center"
                                                      value={
                                                        batchVal.quantityTypes.find(
                                                          (item: any) =>
                                                            item.type === Dozen
                                                        )?.stockCount ?? 0
                                                      }
                                                      disabled={
                                                        !batchVal.quantityTypes.some(
                                                          (item: any) =>
                                                            item.type === Dozen
                                                        ) || loading
                                                      }
                                                      onChange={(event) => {
                                                        handleCountChange(
                                                          index,
                                                          batchIndex,
                                                          batchVal.quantityTypes.findIndex(
                                                            (item: any) =>
                                                              item.type ===
                                                              Dozen
                                                          ),
                                                          event.target.value
                                                        );
                                                      }}
                                                    />
                                                  </td>
                                                  <td className="text-center">
                                                    <Form.Control
                                                      className="form-control-custom bg-white fs-14 fw-600 w-88px min-h-60px px-3 text-center"
                                                      placeholder="0"
                                                      value={
                                                        batchVal.quantityTypes.find(
                                                          (item: any) =>
                                                            item.type === Piece
                                                        )?.stockCount ?? 0
                                                      }
                                                      disabled={
                                                        !batchVal.quantityTypes.some(
                                                          (item: any) =>
                                                            item.type === Piece
                                                        ) || loading
                                                      }
                                                      onChange={(event) => {
                                                        handleCountChange(
                                                          index,
                                                          batchIndex,
                                                          batchVal.quantityTypes.findIndex(
                                                            (item: any) =>
                                                              item.type ===
                                                              Piece
                                                          ),
                                                          event.target.value
                                                        );
                                                      }}
                                                    />
                                                  </td>
                                                  <td className="text-end">
                                                    {val.batch.length > 1 ? (
                                                      <>
                                                        {" "}
                                                        <Button
                                                          variant="link"
                                                          className="btn-flush"
                                                          onClick={() => {
                                                            handleBatchRemove(
                                                              index,
                                                              batchIndex
                                                            );
                                                          }}
                                                          disabled={loading}
                                                        >
                                                          <img
                                                            className="w-17px h-17px"
                                                            src={GrayClose}
                                                            alt=""
                                                          />
                                                        </Button>
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </td>
                                                </tr>
                                                {batchIndex ===
                                                val.batch.length - 1 ? (
                                                  <>
                                                    {validationMessage[index]
                                                      .message !== "" ? (
                                                      <tr>
                                                        <td colSpan={6}>
                                                          <span className="fw-600 fs-15 text-danger">
                                                            {
                                                              validationMessage[
                                                                index
                                                              ].message
                                                            }
                                                          </span>
                                                        </td>
                                                      </tr>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                                {batchIndex ===
                                                val.batch.length - 1 ? (
                                                  <>
                                                    {missingStock.length &&
                                                    missingStock.find(
                                                      (item: any) =>
                                                        item.variant ===
                                                        val.variant._id
                                                    ).missing ? (
                                                      <tr>
                                                        <td colSpan={6}>
                                                          <span className="fw-600 fs-15 text-danger">
                                                            {
                                                              missingStock.find(
                                                                (item: any) =>
                                                                  item.variant ===
                                                                  val.variant
                                                                    ._id
                                                              ).missing
                                                            }{" "}
                                                            {missingStock.find(
                                                              (item: any) =>
                                                                item.variant ===
                                                                val.variant._id
                                                            ).type ===
                                                              CartonWithPieces ||
                                                            missingStock.find(
                                                              (item: any) =>
                                                                item.variant ===
                                                                val.variant._id
                                                            ).type ===
                                                              CartonWithDozens
                                                              ? "Cartons"
                                                              : ""}
                                                            {missingStock.find(
                                                              (item: any) =>
                                                                item.variant ===
                                                                val.variant._id
                                                            ).type === Piece
                                                              ? "Pieces"
                                                              : ""}
                                                            {missingStock.find(
                                                              (item: any) =>
                                                                item.variant ===
                                                                val.variant._id
                                                            ).type === Dozen
                                                              ? "Dozens"
                                                              : ""}{" "}
                                                            are missing
                                                          </span>
                                                        </td>
                                                      </tr>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                                {!loading ? (
                                                  <>
                                                    {batchIndex ===
                                                    val.batch.length - 1 ? (
                                                      <>
                                                        <div className="d-flex flex-column mb-2">
                                                          <Link
                                                            to="#"
                                                            className="text-primary fs-16 fw-bolder"
                                                            onClick={() => {
                                                              handleBatchAddMore(
                                                                index
                                                              );
                                                            }}
                                                          >
                                                            + Add new batch
                                                          </Link>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            );
                                          }
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                  {index === products.length - 1 ? (
                                    <></>
                                  ) : (
                                    <tr>
                                      <td colSpan={6}>
                                        <div className="separator" />
                                      </td>
                                    </tr>
                                  )}
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {sellerProducts.filter(
                    (val: any) =>
                      !products.some((item: any) => item.variant === val._id)
                  ).length ? (
                    <>
                      <div className="separator" />
                      <div className="d-flex flex-column mt-4">
                        <Link
                          to="#"
                          className="text-primary fs-16 fw-bolder"
                          onClick={handleAddMore}
                        >
                          + Add new product
                        </Link>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </Card.Body>
              </Card>
              <div className="d-flex justify-content-end">
                <Button
                  size="lg"
                  className="min-h-60px"
                  onClick={handleSubmit}
                  disabled={
                    missingStock.some((val: any) => val.missing > 0) || loading
                  }
                >
                  {!loading && (
                    <span className="indicator-label fs-16 fw-bold">
                      Save & add to inventory
                    </span>
                  )}
                  {loading && (
                    <span
                      className="indicator-progress fs-16 fw-bold"
                      style={{ display: "block" }}
                    >
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <div className="d-flex justify-content-center">
            <Loader loading={initLoader} />
          </div>
        </>
      )}
    </div>
  );
};
export default AddGoodsToInventory;
