import React, { useEffect, useState } from 'react';
import { Col, Row, Card, Button, FormLabel } from 'react-bootstrap';
import { KTSVG } from '../../../../_admin/helpers';
import { BrandAndTotalSales } from '../../../../utils/dummyJSON';
import { useNavigate } from 'react-router-dom';
import {
  Admin,
  AllModules,
  AssistAdmin,
  PAGE_LIMIT,
  RefundsToCustomer,
  View,
  warehouseRef,
} from '../../../../utils/constants';
import { buyer, multipleWarehouse } from '../../../../api/apiEndPoints';
import APICallService from '../../../../api/apiCallService';
import { customerJSON } from '../../../../api/apiJSON/customer';
import Loader from '../../../../Global/loader';
import Pagination from '../../../../Global/pagination';
import Method from '../../../../utils/methods';
import { getKey, removeKey, setKey } from '../../../../Global/history';
import {
  listCustomerFinanceDetails,
  listRefundCustomers,
  warehouse as warehouseStoreString,
} from '../../../../utils/storeString';
import secureLocalStorage from 'react-secure-storage';
import { ElementAnimateUtil } from '../../../../_admin/assets/ts/_utils';
import { useAuth } from '../../auth';
import { CustomSelect } from '../../../custom/Select/CustomSelect';
const RefundsToCustomers = () => {
  const { currentUser }: any = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [refundList, setRefundList] = useState([]);
  const [page, setPage] = useState<any>(getKey(listRefundCustomers.page) || 1);
  const [pageLimit, setPageLimit] = useState<any>(
    getKey(listRefundCustomers.limit) || PAGE_LIMIT
  );
  const [totalRecords, setTotalRecords] = useState(0);
  const [warehouse, setWarehouse] = useState<any>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>(null);
  const [warehouseData, setWarehouseData] = useState<any>({
    value: currentUser?.warehouse.reference || '',
    title: currentUser?.warehouse.name || '',
    label: currentUser?.warehouse.name || '',
  });
  useEffect(() => {
    (async () => {
      setLoading(true);
      if (!Method.hasModulePermission(RefundsToCustomer, currentUser)) {
        return window.history.back();
      }
      secureLocalStorage.removeItem('customerDetails');
      let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
      tempWarehouse = JSON.parse(tempWarehouse);
      await fetchRefundList(page, pageLimit, tempWarehouse?._id || null);
      fetchWarehouse(tempWarehouse?._id);
      setDataFetched(true);
      setLoading(false);
    })();
  }, []);
  useEffect(() => {
    if (dataFetched) {
      let initialScrollPosition: any = getKey(
        listRefundCustomers.scrollPosition
      );
      if (initialScrollPosition) {
        ElementAnimateUtil.scrollTo(parseInt(initialScrollPosition));
        setDataFetched(false);
      }
      removeKey(listCustomerFinanceDetails.filter);
    }
  }, [dataFetched]);
  const fetchRefundList = async (
    pageNo: number,
    limit: number,
    warehouse?: any
  ) => {
    setLoading(true);
    let params: any = {
      pageNo: pageNo,
      limit: limit,
      sortKey: 'name',
      sortOrder: 1,
    };
    if (warehouse) {
      params.warehouse = warehouse;
    }
    let apiService = new APICallService(
      buyer.refundOrderList,
      customerJSON.listRefundOrder(params),
      '',
      '',
      '',
      '',
      RefundsToCustomer,
      warehouse
    );
    let response = await apiService.callAPI();
    // else {
    //   let prevTotal = totalRecords;
    //   setTotalRecords(prevTotal);
    // }
    if (response.records) {
      if (response.total) {
        setTotalRecords(response.total);
      }
      setRefundList(response.records);
    }
    setLoading(false);
  };
  const handleCurrentPage = async (val: number) => {
    setPage(val);
    setKey(listRefundCustomers.page, val);
    await fetchRefundList(val, pageLimit,selectedWarehouse?._id || null);
  };
  const handleNextPage = async (val: number) => {
    setPage(val + 1);
    setKey(listRefundCustomers.page, val + 1);
    await fetchRefundList(val + 1, pageLimit, selectedWarehouse?._id || null);
  };
  const handlePreviousPage = async (val: number) => {
    setPage(val - 1);
    setKey(listRefundCustomers.page, val - 1);
    await fetchRefundList(val - 1, pageLimit, selectedWarehouse?._id || null);
  };
  const handlePageLimit = async (event: any) => {
    setPage(1);
    setPageLimit(event.target.value);
    setKey(listRefundCustomers.page, 1);
    setKey(listRefundCustomers.limit, parseInt(event.target.value));
    await fetchRefundList(
      1,
      event.target.value,
      selectedWarehouse?._id || null
    );
  };
  const fetchWarehouse = async (warehouseId:any) => {
    let params:any = {
      skip: 0,
      sortKey: 'name',
      sortOrder: 1,
      needCount: true,
    };
    if (warehouseId) {
      params = {
        ...params,
        warehouse: warehouseId,
      };
    }
    setLoading(true);
    const apiService = new APICallService(
      multipleWarehouse.listWarehouse,
      params,
      '',
      '',
      '',
      '',
      AllModules,
      warehouseId
    );
    const response = await apiService.callAPI();
    let data: any = [...warehouse];
    if (response.records.length) {
      response.records.map((val: any) => {
        data.push({
          value: val._id,
          label: val.name,
          title: val.name,
          _id: val._id,
        });
      });
    }
    let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
    tempWarehouse = JSON.parse(tempWarehouse);
    data.unshift({
      value :'',
      label : 'All',
      title : 'All',
      _id : ''
    })
    if (tempWarehouse) {
      const temp = data.find((item: any) => item._id === tempWarehouse._id);
      setSelectedWarehouse(temp);
      setWarehouseData(temp);
    }
    setWarehouse(data);
    setLoading(false);
  };
  const handleWarehouseChange = async (event: any) => {
    setLoading(true);
    setPage(1);
    setTotalRecords(0);
    if (event) {
      setSelectedWarehouse(event);
      setWarehouseData(event);
      setKey(warehouseStoreString.warehouseRef, event);
      await fetchRefundList(1, pageLimit, event.value);
    } else {
      setSelectedWarehouse(null);
      removeKey(warehouseStoreString.warehouseRef);
      await fetchRefundList(1, pageLimit);
    }
    setLoading(false);
  };
  return (
    <>
      <div className="p-9">
        <Row className="align-items-center g-5">
          <Col
            xs
            className="fs-22 fw-bolder"
          >
            Refunds to customers
          </Col>
          <Col md={'auto'}>
            {currentUser?.userType === Admin ? (
              <div className="d-flex gap-4 align-items-center justify-content-center">
                <FormLabel className="fs-16 fw-500 text-dark">
                  Filter by warehouse
                </FormLabel>
                <CustomSelect
                  backgroundColor="white"
                  value={selectedWarehouse}
                  // defaultValue={selectedDriverStatus}
                  hideSelectedOptions={true}
                  onChange={(event: any) => {
                    handleWarehouseChange(event);
                  }}
                  options={warehouse}
                  // isClearable={true}
                />
              </div>
            ) : (
              <></>
            )}
          </Col>
          {/* <Col xs="auto">
            <div className="d-flex align-items-center min-md-264px position-relative">
              <KTSVG
                path="/media/icons/duotune/general/gen021.svg"
                className="svg-icon-3 position-absolute ms-4"
              />
              <input
                type="text"
                id="kt_filter_search"
                className="form-control form-control-custom min-h-50px ps-12 text-dark"
                placeholder="Search by customer name…"
              />
            </div>
          </Col> */}
        </Row>
        <Card className="border border-r10px mt-7">
          <Card.Body className="p-0">
            <div className="table-responsive">
              <table className="table table-row-bordered datatable align-middle gs-7 gy-4 mb-0">
                <thead>
                  <tr className="fs-16 fw-bold text-dark h-70px align-middle">
                    <th className="min-w-200px">Business name</th>
                    <th className="min-w-115px">Total amount refunded</th>
                    <th className="min-w-115px">Refunds pending</th>
                    <th className="min-w-125px text-end"></th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <>
                      <tr>
                        <td colSpan={4}>
                          <div className="d-flex justify-content-center">
                            <Loader loading={loading} />
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {' '}
                      {refundList.length > 0 ? (
                        refundList.map((refundVal: any, index: number) => (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-50px border me-3">
                                  <img
                                    width={50}
                                    height={50}
                                    src={refundVal.business.image}
                                    alt=""
                                    className="object-fit-contain"
                                  />
                                </div>
                                <div className="d-flex justify-content-start flex-column">
                                  <span className="fw-600 fs-15">
                                    {' '}
                                    {refundVal.business.name}
                                  </span>
                                  <span className="fs-14 fw-semibold d-block ">
                                    {refundVal.name}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <span className="fs-15 fw-600">
                                {'TSh ' +
                                  Method.getGeneralizedAmount(
                                    refundVal.analytics[0].refundedAmount
                                  )}
                              </span>
                            </td>
                            <td>
                              <span className="fs-15 fw-600 text-danger">
                                {'TSh ' +
                                  Method.getGeneralizedAmount(
                                    refundVal.analytics[0].pendingRefundAmount
                                  )}
                              </span>
                            </td>
                            <td className="text-end">
                              {Method.hasPermission(
                                RefundsToCustomer,
                                View,
                                currentUser
                              ) ? (
                                <Button
                                  variant="primary"
                                  className="fs-14 fw-600"
                                  onClick={() => {
                                    let currentScrollY = window.scrollY;
                                    setKey(
                                      listRefundCustomers.scrollPosition,
                                      currentScrollY
                                    );
                                    navigate(
                                      '/customers/refunds-to-customers/customer-refund-details',
                                      {
                                        state: {
                                          _id: refundVal._id,
                                          refundVal: refundVal,
                                        },
                                      }
                                    );
                                  }}
                                >
                                  View details
                                </Button>
                              ) : (
                                <></>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={4}>
                            <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                              No Data found
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  )}{' '}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
        {!loading ? (
          <>
            {refundList.length ? (
              <Pagination
                totalRecords={totalRecords}
                currentPage={page}
                handleCurrentPage={(event: any) => {
                  handleCurrentPage(event);
                }}
                handleNextPage={(event: any) => {
                  handleNextPage(event);
                }}
                handlePreviousPage={(event: any) => {
                  handlePreviousPage(event);
                }}
                pageLimit={pageLimit}
                handlePageLimit={(event: any) => {
                  handlePageLimit(event);
                }}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default RefundsToCustomers;
