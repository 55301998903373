import { Button, Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CrossSvg from "../../_admin/assets/media/close.png";
import { ordersDelivery, routePlanning } from "../../api/apiEndPoints";
import APICallService from "../../api/apiCallService";
import { useEffect, useState } from "react";
import { PAGE_LIMIT } from "../../utils/constants";
import Loader from "../../Global/loader";
import Method from "../../utils/methods";
import { routePlanningJSON } from "../../api/apiJSON/routePlanning";
import { KTSVG } from "../../_admin/helpers";
import { getKey } from "../../Global/history";
import { warehouse as warehouseStoreString } from "../../utils/storeString";
const AddOrderPlan = (props: any) => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<any>(-1);
  const [fetchLoader, setFetchLoader] = useState(false);
  const [page, setPage] = useState<any>(1);
  const [pageLimit, setPageLimit] = useState<any>(PAGE_LIMIT);
  const [orders, setOrders] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selected, setSelected] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [selectedId, setSelectedId] = useState<any>([]);
  useEffect(() => {
    (async () => {
      await fetchDetails([1], search);
    })();
  }, []);
  const fetchDetails = async (status?: any, searchTerm?: string) => {
    setFetchLoader(true);
    let params: any = {
      sortKey: "_createdAt",
      sortOrder: -1,
      needCount: true,
      searchTerm: searchTerm,
      fromDate: Method.getDateBeforeDays(
        Method.getTodayDate("YYYY-MM-DD"),
        7,
        "YYYY-MM-DD"
      ),
      toDate: Method.getTodayDate("YYYY-MM-DD"),
    };
    if (status.length > 0) {
      status.map((val: any) => {
        params = { ...params, listType: val };
      });
    }
    if (tempWarehouse?._id) {
      params = { ...params, warehouse: tempWarehouse._id };
    }
    let endpoint = ordersDelivery.list;
    let apiService = new APICallService(
      endpoint,
      params,
      "",
      "",
      "",
      "",
      props.moduleName,
      tempWarehouse?._id
    );
    let response: any = await apiService.callAPI();
    if (response.records) {
      if (response.total) {
        setTotalRecords(response.total);
      }
      setOrders(response.records);
    }
    setFetchLoader(false);
  };
  const kgToTons = (kilograms: number) => {
    // Conversion factor
    const conversionFactor = 1000;
    // Calculate metric tons
    const tons = kilograms / conversionFactor;
    return tons.toFixed(3);
  };
  const handleAddOrderToList = async (data: any, index: number) => {
    setCurrentIndex(index);
    setLoading(true);
    let params = {
      order: data,
    };
    let apiService = new APICallService(
      routePlanning.addOrder,
      routePlanningJSON.addOrder(params),
      { routeId: props.routeId, vehicleId: props.vehicleId },
      "",
      "",
      "",
      props.moduleName,
      tempWarehouse?._id || null
    );
    let response: any = await apiService.callAPI();
    if (response) {
      let ordersTemp: any = [...orders];
      ordersTemp.splice(index, 1);
      let temp: any = [...selectedId];
      temp.push(data);
      setOrders(ordersTemp);
      setSelectedId(temp);
    }
    setCurrentIndex(-1);
    setLoading(false);
  };
  const handleRemoveOrderFromList = async (data: any, index: number) => {
    setLoading(true);
    setCurrentIndex(index);
    let params = {
      order: data,
    };
    let apiService = new APICallService(
      routePlanning.removeOrder,
      routePlanningJSON.addOrder(params),
      { routeId: props.routeId, vehicleId: props.vehicleId },
      "",
      "",
      "",
      props.moduleName,
      tempWarehouse?._id || null
    );
    let response: any = await apiService.callAPI();
    if (response) {
      let temp: any = [...selectedId];
      temp = temp.filter((id: any) => id !== data);
      setSelectedId(temp);
    }
    setCurrentIndex(-1);
    setLoading(false);
  };
  const handleSearch = async (value: string) => {
    setSearch(value);
    setFetchLoader(true);
    await fetchDetails([1], value);
    setFetchLoader(false);
  };
  return (
    <Modal
      {...props}
      show={props.show}
      onHide={() => {
        props.onHide(selectedId);
      }}
      dialogClassName="modal-dialog-centered min-w-lg-1000px min-w-md-700px"
      className="border-r10px"
      centered
    >
      <Modal.Header className="border-bottom-0 pb-0 text-center ">
        <img
          className="close-inner-top cursor-pointer"
          width={40}
          height={40}
          src={CrossSvg}
          alt="closebutton"
          onClick={() => {
            props.onHide(selectedId);
          }}
        />
        <Row className="fs-26 fw-bolder w-100">
          <Col lg={12} className="d-flex pe-0">
            <Col className="mb-md-0 mb-5 d-flex justify-content-end">
              <div className="d-flex align-items-center">Orders List</div>
            </Col>
            <Col md className="mb-md-0 mb-5 d-flex justify-content-end">
              <div className="d-flex align-items-center mw-lg-400px   position-relative ">
                <KTSVG
                  path="/media/icons/duotune/general/gen021.svg"
                  className="svg-icon-3 position-absolute ms-3"
                />
                <input
                  type="text"
                  id="kt_filter_search"
                  className="form-control form-control-white min-h-60px form-control-lg ps-10"
                  placeholder="Search by order ID"
                  value={search}
                  onChange={(event: any) => {
                    handleSearch(event.target.value.trimStart());
                  }}
                />
              </div>
            </Col>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <div className="mh-300px scroll-y me-n7 pe-7">
          {fetchLoader ? (
            <>
              <div className="d-flex flex-stack py-4 border border-gray">
                <div className="d-flex justify-content-center">
                  {" "}
                  <Loader loading={fetchLoader} />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="table-responsive">
                <table className="table table-rounded table-bordered align-middle gs-7 gy-6 mb-0">
                  <thead>
                    <tr className="fw-bold fs-16 fw-600 text-dark border-bottom align-middle">
                      <th className="min-w-md-175px min-w-150px">
                        Order Date & ID
                      </th>
                      <th className="min-w-md-150px min-w-125px">
                        Customer name
                      </th>
                      <th className="min-w-md-125px">Quantity</th>
                      <th className="min-w-md-125px">Weight</th>
                      <th className="min-w-md-125px min-w-100px">
                        Total amount
                      </th>
                      <th className="min-w-md-200px min-w-300px text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.length ? (
                      orders.map((orderVal: any, orderIndex: number) => (
                        <tr key={orderIndex}>
                          <td className="fs-15 fw-500">
                            <div className="form-check form-check-sm form-check-custom form-check-solid">
                              <div className="d-flex align-items-start flex-column ">
                                <div className="d-flex flex-row">
                                  <span className="text-dark d-block">
                                    {Method.convertDateToDDMMYYYYHHMM(
                                      orderVal._createdAt,
                                      "-"
                                    )}
                                  </span>
                                </div>
                                <span className="text-gray d-block">
                                  {orderVal.refKey}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="fs-15 fw-500 d-block">
                              {orderVal.customer.name}
                            </span>
                          </td>
                          <td>
                            <span className="fs-15 fw-500">
                              {/* {goodVal.quantity + ' items'} */}
                              {orderVal.totalQuantities.reduce(
                                (total: number, item: any) =>
                                  total + item.stockCount,
                                0
                              ) -
                                orderVal.mProcessedQuantities +
                                " items"}
                            </span>
                          </td>
                          <td>
                            <span className="fs-15 fw-500">
                              {/* {goodVal.quantity + ' items'} */}
                              {kgToTons(
                                orderVal.totalQuantities.reduce(
                                  (total: number, item: any) =>
                                    total + item.weight,
                                  0
                                ) - orderVal.mProcessedWeight
                              ) + " tons"}
                            </span>
                          </td>
                          <td>
                            <span className="fs-15 fw-500">
                              {"TSh " +
                                Method.getGeneralizedAmount(
                                  orderVal.payment.totalCharge
                                )}
                            </span>
                          </td>
                          <td className="text-center">
                            {selectedId.includes(orderVal._id) ? (
                              <Button
                                className="fs-14 fw-600"
                                variant="danger"
                                onClick={() =>
                                  handleRemoveOrderFromList(
                                    orderVal._id,
                                    orderIndex
                                  )
                                }
                              >
                                {loading && orderIndex == currentIndex && (
                                  <span
                                    className="indicator-progress"
                                    style={{
                                      display: "block",
                                    }}
                                  >
                                    Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                  </span>
                                )}
                                {(!loading || orderIndex !== currentIndex) && (
                                  <>Remove</>
                                )}
                              </Button>
                            ) : (
                              <Button
                                className="fs-14 fw-600"
                                onClick={() =>
                                  handleAddOrderToList(orderVal._id, orderIndex)
                                }
                              >
                                {loading && orderIndex == currentIndex && (
                                  <span
                                    className="indicator-progress"
                                    style={{
                                      display: "block",
                                    }}
                                  >
                                    Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                  </span>
                                )}
                                {(!loading || orderIndex !== currentIndex) && (
                                  <>Add to plan</>
                                )}
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <>
                        <tr>
                          <td colSpan={6}>
                            <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                              No Data found
                            </div>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AddOrderPlan;
