/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card } from 'react-bootstrap';
import { CustomSelect } from '../../../custom/Select/CustomSelect';
import CustomDatePicker from '../../../custom/DateRange/DatePicker';
import APICallService from '../../../../api/apiCallService';
import { customerService, dashboard } from '../../../../api/apiEndPoints';
import Loader from '../../../../Global/loader';
import Method from '../../../../utils/methods';
import moment from 'moment';
import { error } from '../../../../Global/toast';
import { AllModules, Dashboard } from '../../../../utils/constants';
import { useAuth } from '../../auth';
import { warehouse as warehouseStoreString } from '../../../../utils/storeString';
import { getKey } from '../../../../Global/history';
const ActiveSellersCustomers = () => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const {currentUser} = useAuth();
  const [startDate, setStartDate] = useState<any>(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [endDate, setEndDate] = useState<any>(new Date());
  const [chartSeries, setChartSeries] = useState<any>([]);
  const [chartData, setChartData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState<any>();
  useEffect(() => {
    (async () => {
      // await fetchDistrict(1, 10);
      const temp = currentUser?.warehouse?.district || [];
      const tempDistrict = temp.map((val:any)=>{
        return {
          value: val.reference,
          label: val.name,
          title: val.name,
          _id: val.reference,
          name: val.name,
        };
      })
      setDistricts(tempDistrict);
      setSelectedDistrict(tempDistrict[0]);
      await fetchReports(startDate, endDate, tempDistrict[0] || selectedDistrict);

    })();
  }, []);
  const fetchDistrict = async (pageNo: number, limit: number) => {
    let params = {};
    if (tempWarehouse?._id) {
      params = {
        ...params,
        warehouse: tempWarehouse?._id,
      };
    }
    let apiService = new APICallService(customerService.listDistrict, params,'','','','',Dashboard,tempWarehouse?._id || null);
    let response = await apiService.callAPI();
    let data: any = [];
    response.records.map((val: any) => {
      data.push({
        value: val._id,
        label: val.name,
        title: val.name,
        _id: val._id,
        name: val.name,
      });
    });
    setDistricts(data);
  };
  const fetchReports = async (fromDate: any, toDate: any, district: any) => {
    setLoading(true);
    let params: any = {
      fromDate: fromDate,
      toDate: toDate,
      // utcOffset: -330,
      utcOffset: new Date().getTimezoneOffset(),
    };
    if (district !== undefined) {
      params = { ...params, 'districts[]': district._id };
    }
    if (tempWarehouse?._id) {
      params = {
        ...params,
        warehouse: tempWarehouse?._id,
      };
    }
    let apiService = new APICallService(dashboard.userReport, params,'','','','',Dashboard,tempWarehouse?._id || null);
    let response = await apiService.callAPI();
    if (response.data.length) {
      response.data = Method.populateMissingDates(
        response.data,
        Method.convertDateToFormat(fromDate, 'DD'),
        Method.convertDateToFormat(toDate, 'DD')
      );
      let temp = [
        {
          name: 'Customers',
          data: response.data.map((entry: any) => ({
            x: entry.date,
            y: entry.customers,
          })),
        },
        {
          name: 'Sellers',
          data: response.data.map((entry: any) => ({
            x: entry.date,
            y: entry.sellers,
          })),
        },
      ];
      setChartData(response.data);
      setChartSeries(temp);
    } else {
      setChartData([]);
      setChartSeries([]);
    }
    setLoading(false);
  };
  const handleChange = async ([startDate, endDate]: any) => {
    setStartDate(startDate);
    setEndDate(endDate);
    const startMonth = moment(new Date(startDate)).format('MM');
    const endMonth = moment(new Date(endDate)).format('MM');
    if (startDate && endDate) {
      if (startMonth === endMonth) {
        if (Method.dayDifference(startDate, endDate) >= 6) {
          await fetchReports(
            Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
            Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
            selectedDistrict
          );
        } else {
          error(
            'Please select a date range with a minimum one-week difference'
          );
        }
      } else {
        error('Please select a date range within a single month');
      }
    }
  };
  const handleDistrictChange = async (event: any) => {
    if (event) {
      setSelectedDistrict(event);
      await fetchReports(
        Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
        Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
        event
      );
    } else {
      setSelectedDistrict(undefined);
      await fetchReports(
        Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
        Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
        undefined
      );
    }
  };
  return (
    <>
      <Card className="mb-7 bg-f9f9f9">
        <Card.Header className="pt-7 border-bottom-0">
          <div className="card-title">
            <h2 className="fs-22 fw-bolder">Active sellers & customers</h2>
          </div>
          <div className="card-toolbar">
          {currentUser?.warehouse?.defaultWarehouse && (
            <div className="min-w-md-193px">
              <CustomSelect
                backgroundColor="#ffff"
                minHeight="50px"
                disabled={loading}
                default={selectedDistrict}
                options={districts}
                loadingMessage={'Fetching Data'}
                isClearable={true}
                onChange={(e: any) => handleDistrictChange(e)}
                value={selectedDistrict}
              />
            </div>
          )}
            <div className="ms-3">
              <CustomDatePicker
                className="bg-white min-h-50px fs-16 fw-bold text-dark min-w-md-260px"
                onChange={handleChange}
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd/MM/yyyy"
                showFullMonthYearPicker={true}
                maxDate={new Date()}
                inputTextBG="bg-white"
              />
            </div>
          </div>
        </Card.Header>
        <Card.Body className="py-0"></Card.Body>
        <Card.Body className="pt-7">
          {loading ? (
            <>
              <div className="d-flex flex-center min-h-140px">
                <Loader loading={loading} />
              </div>
            </>
          ) : (
            <>
              {chartData && chartData.length ? (
                <>
                  <Chart
                    options={{
                      chart: {
                        type: 'bar' as 'bar',
                        fontFamily: 'inherit',
                        height: '275',
                        toolbar: { show: false },
                        dropShadow: { enabled: false },
                      },
                      plotOptions: {
                        bar: {
                          horizontal: false,
                          columnWidth: '38%',
                          borderRadius: 6,
                        },
                      },
                      dataLabels: { enabled: false },
                      legend: {
                        show: true,
                        horizontalAlign: 'center',
                        fontSize: '16px',
                        fontWeight: 500,
                        markers: {
                          width: 17,
                          height: 17,
                          radius: 10,
                        },
                        itemMargin: {
                          horizontal: 8,
                          vertical: 0,
                        },
                      },
                      stroke: {
                        show: true,
                        width: 3,
                        colors: ['transparent'],
                      },
                      xaxis: {
                        type: 'datetime',
                        tickAmount: 10,
                        categories: chartData.map((entry: any) => entry.date),
                        axisBorder: {
                          show: false,
                        },
                        axisTicks: {
                          show: false,
                        },
                        labels: {
                          style: {
                            colors: '#1a1a1a',
                            fontSize: '13px',
                            fontWeight: 500,
                          },
                        },
                      },
                      yaxis: {
                        labels: {
                          style: {
                            colors: '#1a1a1a',
                            fontSize: '13px',
                            fontWeight: 500,
                          },
                        },
                      },
                      fill: {
                        opacity: 1,
                      },
                      states: {
                        normal: {
                          filter: {
                            type: 'none',
                            value: 0,
                          },
                        },
                        hover: {
                          filter: {
                            type: 'none',
                            value: 0,
                          },
                        },
                        active: {
                          allowMultipleDataPointsSelection: false,
                          filter: {
                            type: 'none',
                            value: 0,
                          },
                        },
                      },
                      tooltip: {
                        style: {
                          fontSize: '13px',
                        },
                        y: {
                          formatter: function (val) {
                            return val + ' ';
                          },
                        },
                      },
                      colors: ['#74ca8f', '#5795f7'],
                      grid: {
                        borderColor: '#e0e0df',
                        strokeDashArray: 4,
                        yaxis: {
                          lines: {
                            show: true,
                          },
                        },
                      },
                    }}
                    series={chartSeries}
                    type="bar"
                    height="350"
                  />
                </>
              ) : (
                <>
                  <div className="d-flex flex-center min-h-140px">
                    <span className="fs-18 fw-medium">
                      No details available for these dates.
                    </span>
                  </div>
                </>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
export default ActiveSellersCustomers;
