import React, { useEffect, useState } from 'react';
import Pagination from '../../../Global/pagination';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../Global/loader';
import { CustomSelectTable } from '../../custom/Select/CustomSelectTable';
import ThreeDotMenu from '../../../_admin/assets/media/svg_uTrade/three-dot.svg';
import AddCustomerCategoryModal from '../../modals/add-customer-category';
import { PAGE_LIMIT } from '../../../utils/constants';
import APICallService from '../../../api/apiCallService';
import { customerCategory } from '../../../api/apiEndPoints';
import EditCustomerCategoryModal from '../../modals/edit-customer-category';
import DeleteModal from '../../modals/delete';
import { success } from '../../../Global/toast';
const CustomerCategory = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [customerCategoryData, setCustomerCategoryData] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>('');
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState<any>(1);
  const [pageLimit, setPageLimit] = useState<any>(PAGE_LIMIT);
  useEffect(() => {
    (async () => {
      fetchCustomerCategory(page, pageLimit);
    })();
  }, []);
  const fetchCustomerCategory = async (page: any, pageLimit: any) => {
    let params: any = {
      page: page,
      limit: pageLimit,
      sortKey: 'name',
      sortOrder: 1,
      needCount: true,
    };
    setLoading(true);
    const apiService = new APICallService(
      customerCategory.listCustomerCategory,
      params,
      '',
      '',
      '',
      '',
      ''
      //  tempWarehouse?._id || null
    );
    const response = await apiService.callAPI();
    let data: any = [];
    if (response) {
      setCustomerCategoryData(response.records);
      setTotalRecords(response.total);
    }
    setLoading(false);
  };
  const handleCurrentPage = async (val: number) => {
    if (val === page || val.toString() === '...') return;
    setLoading(true);
    setPage(val);
    await fetchCustomerCategory(val, pageLimit);
    setLoading(false);
  };
  const handleNextPage = async (val: number) => {
    setLoading(true);
    setPage(val + 1);
    await fetchCustomerCategory(val + 1, pageLimit);
    setLoading(false);
  };
  const handlePreviousPage = async (val: number) => {
    setLoading(true);
    setPage(val - 1);
    await fetchCustomerCategory(val - 1, pageLimit);
    setLoading(false);
  };
  const handlePageLimit = async (event: any) => {
    setLoading(true);
    setPage(1);
    setTotalRecords(0);
    await setPageLimit(parseInt(event.target.value));
    await fetchCustomerCategory(1, event.target.value);
    setLoading(false);
  };
  const handleCloseAdd = async () => {
    setShowAddModal(false);
    await fetchCustomerCategory(page, pageLimit);
  };
  const handleCloseEdit = async () => {
    setShowEditModal(false);
    await fetchCustomerCategory(page, pageLimit);
  };
  const handleDelete = async () => {
    setDeleteLoader(true);
    let apiService = new APICallService(
      customerCategory.deleteCustomerCategory,
      selectedCategory?._id,
      '',
      '',
      '',
      '',
      ''
      //  selectedWarehouse?._id || null
    );
    let response = await apiService.callAPI();
    if (response) {
      setPage(1);
      fetchCustomerCategory(1, pageLimit);
      success('Customer Category deleted successfully');
      setDeleteModalOpen(false);
    }
    setDeleteLoader(false);
  };
  return (
    <>
      {showAddModal && (
        <AddCustomerCategoryModal
          show={showAddModal}
          handleClose={() => setShowAddModal(false)}
          handleFetchCategory={handleCloseAdd}
        />
      )}
      {showEditModal && (
        <EditCustomerCategoryModal
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          selectedCategory={selectedCategory}
          handleFetchCategory={handleCloseEdit}
        />
      )}
      {deleteModalOpen && (
        <DeleteModal
          show={deleteModalOpen}
          onHide={() => setDeleteModalOpen(false)}
          handleDelete={handleDelete}
          loading={deleteLoader}
          serviceName="catgeory"
          message={`Are you sure you want to delete ${selectedCategory.title} category?`}
          warnMessage={`By continuing, the ${selectedCategory.title} category will
          be deleted permanently`}
        />
      )}
      <div className="p-9">
        <Row className="align-items-center">
          <Col
            xs={12}
            className="mb-5"
          >
            <div className="d-flex flex-wrap justify-content-between align-items-center">
              <h1 className="fs-22 fw-bolder">Customer Category</h1>
              <Button onClick={() => setShowAddModal(true)}>
                Add Customer Category
              </Button>
            </div>
          </Col>
          <Card className="border border-r10px">
            <Card.Body className="p-0">
              <div className="table-responsive">
                <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4">
                  <thead>
                    <tr className="fw-bold fs-16 fw-600 text-dark border-bottom h-70px align-middle">
                      <th className="min-w-200px">Customer Catgeory</th>
                      <th className="min-w-200px">Discount</th>
                      <th className="min-w-200px">Rounded Value</th>
                      <th className="min-w-200px text-end">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={4}>
                          <div className="d-flex justify-content-center">
                            <Loader loading={loading} />
                          </div>
                        </td>
                      </tr>
                    ) : customerCategoryData?.length > 0 ? (
                      customerCategoryData.map((category: any) => (
                        <tr key={category._id}>
                          <td className="fs-15 fw-600">{category.title}</td>
                          <td className="fs-15 fw-600">
                            {' '}
                            {category.discountType === 1 ? (
                              <span className="text-success">+</span>
                            ) : (
                              <span className="text-danger">-</span>
                            )}{' '}
                            {category.discount}
                          </td>
                          <td className="fs-15 fw-600">
                            {category.roundValue}
                          </td>
                          <td className="fs-15 fw-600">
                            <div className="d-flex flex-nowrap justify-content-end align-items-center">
                              <CustomSelectTable
                                marginLeft={'0px'}
                                menuMargin={'-60px'}
                                width={'140px'}
                                placeholder={
                                  <img
                                    className="img-fluid"
                                    width={18}
                                    height={5}
                                    src={ThreeDotMenu}
                                    alt=""
                                  />
                                }
                                options={[
                                  {
                                    label: (
                                      <button
                                        className="btn btn-link fs-14 fw-500 text-black d-flex justify-content-center align-items-center ms-3"
                                        onClick={() => {
                                          setShowEditModal(true);
                                          setSelectedCategory(category);
                                        }}
                                      >
                                        Edit details
                                      </button>
                                    ),
                                    value: 1,
                                  },
                                  {
                                    label: (
                                      <button
                                        className="btn btn-link fs-14 fw-500 text-danger d-flex justify-content-center align-items-center ms-3"
                                        onClick={() => {
                                          setDeleteModalOpen(true);
                                          setSelectedCategory(category);
                                        }}
                                      >
                                        Delete
                                      </button>
                                    ),
                                    value: 2,
                                  },
                                ]}
                                isOptionDisabled={(option: any) =>
                                  option.value === 3
                                }
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          className="fs-15 fw-600 text-center"
                          colSpan={4}
                        >
                          No Data Found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Row>
        {!loading && totalRecords > 0 ? (
          <Pagination
            totalRecords={totalRecords}
            currentPage={page}
            handleCurrentPage={handleCurrentPage}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            handlePageLimit={handlePageLimit}
            pageLimit={pageLimit}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default CustomerCategory;
