import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import CrossSvg from '../../_admin/assets/media/close.png';
import p1 from '../../_admin/assets/media/product/p-8.png';
import Method from '../../utils/methods';
import {
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  Piece,
} from '../../utils/constants';
const BatchExpiry = (props: any) => {
  const calculateTotal = (quantityTypes: any, type: any) => {
    const totalItem = quantityTypes.find((item: any) => item.type === type);
    return totalItem ? totalItem.remainingQty : 0;
  };
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        dialogClassName="modal-dialog-centered min-w-md-794px"
        className="border-r10px"
        contentClassName="p-5"
      >
        {props.show ? (
          <>
            <Modal.Header className="border-bottom-0 text-center pb-6 mx-auto">
              <div className="symbol symbol-md-40px symbol-35px close-inner-top-3">
                <img
                  width={40}
                  height={40}
                  src={CrossSvg}
                  alt=""
                  onClick={props.onHide}
                />
              </div>
              <Modal.Title className="fs-26 fw-bolder mw-lg-550px">
                {props.product.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0 pb-5">
              <Row className="align-items-center g-6 mb-6">
                <Col
                  md={12}
                  className="text-center"
                >
                  <span className="fs-18 fw-bolder">
                    {`${calculateTotal(
                      props.product.inventoryInfo[0].quantityTypes,
                      CartonWithDozens
                    )} Cartons, 
            ${calculateTotal(
              props.product.inventoryInfo[0].quantityTypes,
              Dozen
            )} Dozens & 
            ${calculateTotal(
              props.product.inventoryInfo[0].quantityTypes,
              Piece
            )} Pieces available`}
                  </span>
                </Col>
              </Row>
              <Card className="border-0 border-r10px">
                <Card.Body className="p-0">
                  <div className="table-responsive">
                    <table className="table table-rounded table-bordered align-middle gy-4 gs-5 mb-0">
                      <thead>
                        <tr className="fs-16 fw-600 align-middle">
                          <th className="min-w-150px">Products added on</th>
                          <th className="min-w-150px">Batch & Expiery</th>
                          <th className="min-w-75px">Carton</th>
                          <th className="min-w-75px">Dozen</th>
                          <th className="min-w-75px">Pieces</th>
                        </tr>
                      </thead>
                      <tbody className="fs-15 fw-500">
                        {props.batches.length ? (
                          <>
                            {props.batches.map(
                              (batchVal: any, index: number) => {
                                return (
                                  <tr>
                                    <td>
                                      {' '}
                                      {Method.convertDateToDDMMYYYY(
                                        props.product._createdAt
                                      )}
                                    </td>
                                    <td>
                                      Batch {batchVal.batch} -{' '}
                                      {Method.convertDateToDDMMYYYY(
                                        batchVal.expiry
                                      )}
                                    </td>
                                    <td>
                                      {batchVal.quantityTypes.some(
                                        (item: any, idx: number) =>
                                          item.type === CartonWithDozens ||
                                          item.type === CartonWithPieces
                                      ) ? (
                                        <span>
                                          {
                                            batchVal.quantityTypes.find(
                                              (item: any) =>
                                                item.type ===
                                                  CartonWithDozens ||
                                                item.type === CartonWithPieces
                                            ).stockCount
                                          }{' '}
                                        </span>
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </td>
                                    <td>
                                      {batchVal.quantityTypes.some(
                                        (item: any, idx: number) =>
                                          item.type === Dozen
                                      ) ? (
                                        <span>
                                          {
                                            batchVal.quantityTypes.find(
                                              (item: any) => item.type === Dozen
                                            ).stockCount
                                          }{' '}
                                        </span>
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </td>
                                    <td>
                                      {batchVal.quantityTypes.some(
                                        (item: any, idx: number) =>
                                          item.type === Piece
                                      ) ? (
                                        <span>
                                          {
                                            batchVal.quantityTypes.find(
                                              (item: any) => item.type === Piece
                                            ).stockCount
                                          }{' '}
                                        </span>
                                      ) : (
                                        <span>-</span>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            </Modal.Body>
          </>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};
export default BatchExpiry;
