import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { CustomSelect } from "../../../custom/Select/CustomSelect";
import GrayCross from "../../../../_admin/assets/media/svg_uTrade/cross-red.svg";
import { useLocation, useNavigate } from "react-router-dom";
import WarningIcon from "../../../../_admin/assets/media/svg_uTrade/error-warning.svg";
import APICallService from "../../../../api/apiCallService";
import { inventory, manageProduct, seller } from "../../../../api/apiEndPoints";
import { useEffect, useState } from "react";
import {
  Admin,
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  GoodsRequest,
  PAGE_LIMIT,
  Piece,
  SelfTransport,
  Shipping,
  UTradeTransport,
  WarehouseInterTransfer,
  WarehouseStorage,
} from "../../../../utils/constants";
import Loader from "../../../../Global/loader";
import Method from "../../../../utils/methods";
import { useAuth } from "../../auth";
import SuccessActionModal from "../../../modals/success-action.";
import { success } from "../../../../Global/toast";
import { warehouse as warehouseStoreString } from "../../../../utils/storeString";
import { getKey } from "../../../../Global/history";
const SendGoodsSeller = () => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const { currentUser } = useAuth();
  const { state }: any = useLocation();
  const [details, setDetails] = useState(state || {});
  const navigate = useNavigate();
  const [sellerData, setSellerData] = useState([]);
  const [page, setPage] = useState(1);
  const [productPage, setProductPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [businessActivationDate, setBusinessActivationDate] = useState("");
  const [selectedSeller, setSelectedSeller] = useState(
    state?.businesses[0]?.reference || ""
  );
  const [sellerProducts, setSellerProducts] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [shipmentType, setShipmentType] = useState(UTradeTransport);
  const [loading, setLoading] = useState(false);
  const [fetchLoader, setFetchLoader] = useState(false);
  const [productFetchLoader, setProductsFetchLoader] = useState(false);
  const [products, setProducts] = useState<
    {
      variant: string;
      quantityTypes: any;
    }[]
  >([
    {
      variant: "",
      quantityTypes: [],
    },
  ]);
  const [productValidation, setProductValidation] = useState<
    {
      variant: boolean;
      quantityTypes: boolean;
    }[]
  >([
    {
      variant: false,
      quantityTypes: false,
    },
  ]);
  const [totalSellers, setTotalSellers] = useState(0);
  const [costDetails, setCostDetails] = useState<any>();
  const [initLoader, setInitLoader] = useState(false);
  const [productOptionsList, setProductOptionsList] = useState<any>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  useEffect(() => {
    (async () => {
      setInitLoader(true);
      if (!Method.hasModulePermission(GoodsRequest, currentUser)) {
        return window.history.back();
      }
      await fetchSeller(1, PAGE_LIMIT);
      if (state && state.variants) {
        const temp = state.variants.map((item: any) => {
          return {
            variant: item?.variant?._id,
            quantityTypes: [
              {
                amount: item?.amount || "",
                type: item?.quantityType || "",
                dimensions: item?.dimensions || {},
                stockCount: item?.stockCount || 0,
              },
            ],
          };
        });
        const tempList = state.variants.map((val: any) => {
          return {
            label: (
              <>
                <div className="symbol symbol-30px border me-3">
                  <img
                    src={val?.variant?.media[0]?.url || ""}
                    className="object-fit-contain"
                    alt=""
                  />
                </div>
                <span className="fs-16 fw-600 text-black mb-0">
                  {val?.variant?.title.replace(/\s*\)\s*/g, ")")}
                </span>
              </>
            ),
            value: val?.variant?._id || "",
            title: val?.variant?.title || "",
          };
        });
        const tempValidation = temp.map((item: any) => {
          return {
            variant: false,
            quantityTypes: false,
          };
        });
        setProducts(temp);
        setProductOptionsList(tempList);
        setProductValidation(tempValidation);
      }
      setInitLoader(false);
    })();
  }, []);
  const fetchSeller = async (
    page: number,
    limit: number,
    search: string = ""
  ) => {
    setFetchLoader(true);
    let params : any = {
      pageNo: page,
      limit: limit,
      searchTerm: search,
      sortKey: "name",
      sortOrder: 1,
      ["status[0]"]: 4,
      needCount: true,
    };
    if(tempWarehouse?._id){
      params.warehouse = tempWarehouse?._id;
    }
    let apiService = new APICallService(
      seller.listSeller,
      params,
      "",
      "",
      "",
      "",
      WarehouseInterTransfer,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    if (response.records) {
      setTotalSellers(response.total);
      let data: any = (prevResults: any) =>
        page === 1 ? response.records : [...prevResults, ...response.records];
      setSellerData(data);
    }
    setFetchLoader(false);
  };
  const onMenuScrollToBottom = async () => {
    if (!(sellerData.length === totalSellers)) {
      let tempPage = page;
      tempPage = tempPage + 1;
      setPage(tempPage);
      await fetchSeller(tempPage, PAGE_LIMIT, searchTerm);
    }
  };
  const onMenuClose = async () => {
    setSearchTerm("");
  };
  const onMenuOpen = async () => {
    setPage(1);
    await fetchSeller(1, PAGE_LIMIT, searchTerm);
  };
  const handleInputChange = async (inputValue: string) => {
    setPage(1);
    setSearchTerm(inputValue);
    await fetchSeller(1, PAGE_LIMIT, inputValue);
  };
  const handleSeller = async (event: any) => {
    if (event.value !== selectedSeller) {
    }
    setSelectedSeller(event.value);
    setBusinessActivationDate(event.creationDate);
    await fetchProducts(event.value, productPage, PAGE_LIMIT, searchTerm);
  };
  const handleProductSelect = async (event: any, index: number) => {
    let temp = [...products];
    let productTemp = [...sellerProducts];
    const filteredArray: any = productTemp.filter(
      (obj: any) => obj._id === event.value
    );
    temp[index].quantityTypes = filteredArray[0].quantityTypes;
    temp[index].variant = event.value;
    setProducts(temp);
  };
  const fetchProducts = async (
    businessId: string,
    page: number,
    limit: number,
    search: string = ""
  ) => {
    setProductsFetchLoader(true);
    let params :any = {
      pageNo: page,
      limit: limit,
      searchTerm: search,
      sortKey: "title",
      sortOrder: 1,
      ["status[0]"]: 2,
      state: 2,
      ["business[0]"]: businessId,
      needCount: true,
    };
    if(tempWarehouse?._id){
      params.warehouse = tempWarehouse?._id;
    }
    let apiService = new APICallService(
      manageProduct.listVariant,
      params,
      "",
      "",
      "",
      "",
      WarehouseInterTransfer,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    setTotalRecords(response.total);
    let data: any = (prevResults: any) =>
      page === 1 ? response.records : [...prevResults, ...response.records];
    setSellerProducts(data);
    setProductsFetchLoader(false);
  };
  const onMenuProductScrollToBottom = async () => {
    if (!(sellerProducts.length === totalRecords)) {
      let tempPage = productPage;
      tempPage = tempPage + 1;
      setProductPage(tempPage);
      await fetchProducts(selectedSeller, tempPage, PAGE_LIMIT, searchTerm);
    }
  };
  const onMenuProductClose = async () => {
    setSearchTerm("");
  };
  const onMenuProductOpen = async () => {
    setProductPage(1);
    await fetchProducts(selectedSeller, 1, PAGE_LIMIT, searchTerm);
  };
  const handleProductInputChange = async (inputValue: string) => {
    setProductPage(1);
    setSearchTerm(inputValue);
    await fetchProducts(selectedSeller, 1, PAGE_LIMIT, inputValue);
  };
  const handleQuantityChange = (
    value: string,
    index: number,
    quantityIndex: number,
    type: number
  ) => {
    let productTemp = [...products];
    let productValidationTemp = [...productValidation];
    productValidationTemp[index].quantityTypes = true;
    if (parseInt(value) > 0) {
      productValidationTemp[index].quantityTypes = false;
    }
    productTemp[index].quantityTypes[quantityIndex]["stockCount"] = value;
    productTemp[index].quantityTypes[quantityIndex]["type"] = type;
    setProducts(productTemp);
    setProductValidation(productValidationTemp);
  };
  const handleAddMore = () => {
    let productTemp = [...products];
    let productValidationTemp = [...productValidation];
    const allObjectsHaveQuantityTypeWithStock = productTemp.every((obj: any) =>
      obj.quantityTypes.some(
        (quantityType: any) =>
          quantityType.stockCount && quantityType.stockCount > 0
      )
    );
    if (allObjectsHaveQuantityTypeWithStock) {
      productTemp.push({
        variant: "",
        quantityTypes: [],
      });
      productValidationTemp.push({
        variant: false,
        quantityTypes: false,
      });
    } else {
      productTemp.forEach((product: any, index: number) => {
        const hasStockCountGreaterThanZero = product.quantityTypes.some(
          (quantityType: any) =>
            quantityType.stockCount !== undefined && quantityType.stockCount > 0
        );
        if (!hasStockCountGreaterThanZero) {
          productValidationTemp[index].quantityTypes = true;
        }
      });
    }
    setProductValidation(productValidationTemp);
    setProducts(productTemp);
  };
  const handleRemove = (indexToRemove: number) => {
    let productTemp = [...products];
    let productValidationTemp = [...productValidation];
    productValidation.splice(indexToRemove, 1);
    productTemp.splice(indexToRemove, 1);
    setProducts(productTemp);
    setProductValidation(productValidationTemp);
  };
  useEffect(() => {
    (async () => {
      if (products.length && selectedSeller) {
        let temp: any = {
          businessActivationDate: businessActivationDate,
          shipment: { type: shipmentType },
        };
        let productTemp: any = [...products];
        productTemp = productTemp.map((item: any) => ({
          ...item,
          quantityTypes: item.quantityTypes.filter(
            (qType: any) => parseInt(qType.stockCount) > 0
          ),
        }));
        if (productTemp.length) {
          temp = { ...temp, variants: productTemp };
          if(tempWarehouse?._id){
            temp.warehouse = tempWarehouse?._id;
          }
          let apiService = new APICallService(
            inventory.addCostInfo,
            temp,
            {
              id: selectedSeller,
            },
            "",
            "",
            "",
            WarehouseInterTransfer,
            tempWarehouse?._id
          );
          let response = await apiService.callAPI();
          setCostDetails(response);
        } else {
          setCostDetails({});
        }
      } else {
        setCostDetails({});
      }
    })();
  }, [products]);
  const handleSubmit = async () => {
    setLoading(true);
    let productTemp = [...products];
    let productValidationTemp = [...productValidation];
    const allObjectsHaveQuantityTypeWithStock = productTemp.every((obj: any) =>
      obj.quantityTypes.some(
        (quantityType: any) =>
          quantityType.stockCount && quantityType.stockCount > 0
      )
    );
    if (allObjectsHaveQuantityTypeWithStock) {
      let temp: any = {
        businessActivationDate: businessActivationDate,
        shipment: { type: shipmentType },
      };
      if (productTemp.length) {
        const filteredData = productTemp.map((item) => ({
          ...item,
          quantityTypes: item.quantityTypes.filter(
            (qType: any) => parseInt(qType.stockCount) > 0
          ),
        }));
        temp = { ...temp, variants: filteredData };
        let apiService = new APICallService(
          inventory.sendShipmentRequest,
          temp,
          {
            id: selectedSeller,
          },
          "",
          "",
          "",
          WarehouseInterTransfer,
          tempWarehouse?._id
        );
        let response = await apiService.callAPI();
        if (response) {
          navigate("/inventory/goods-requests");
        }
      } else {
        setCostDetails({});
      }
    } else {
      productTemp.forEach((product: any, index: number) => {
        const hasStockCountGreaterThanZero = product.quantityTypes.some(
          (quantityType: any) =>
            quantityType.stockCount !== undefined && quantityType.stockCount > 0
        );
        if (!hasStockCountGreaterThanZero) {
          productValidationTemp[index].quantityTypes = true;
        }
      });
    }
    setProductValidation(productValidationTemp);
    setProducts(productTemp);
    setLoading(false);
  };
  const handleGoodsRequest = async () => {
    let temp: any = { ...details };
    const groupedByBusiness: any = {};
    // Grouping data by business
    temp.businesses.forEach(async (business: any) => {
      const businessRef = business.reference;
      if (!groupedByBusiness[businessRef]) {
        groupedByBusiness[businessRef] = {
          shipment: {
            type: shipmentType, // Example shipment type, replace as needed
          },
          variants: [],
          transferId: details._id,
          warehouseTransferRequest: true,
        };
      }
      // Iterate through all variants
      temp.variants.forEach((product: any) => {
        groupedByBusiness[businessRef].variants.push({
          variant: product.variant._id,
          quantityTypes: [
            {
              dimensions: product.dimensions,
              type: product.quantityType,
              amount: product.amount,
              discountAmtEnabled: false,
              discountByQuantitiesEnabled: false,
              discountsByQuantities: [],
              stockCount: product.stockCount.toString(), // Convert stockCount to string
            },
          ],
        });
      });
      // Convert the grouped data to an array
      const result = Object.values(groupedByBusiness);
      result.map(async (val) => {
        let apiService = new APICallService(
          inventory.sendShipmentRequest,
          val,
          {
            id: businessRef,
          },
          "",
          "",
          "",
          WarehouseInterTransfer,
          tempWarehouse?._id
        );
        let response = await apiService.callAPI();
        if (response) {
          setShowConfirmModal(false);
          success("Goods request created successfully");
          if(currentUser?.userType == Admin){
            navigate('/inventory/goods-requests');
          }else {
            window.history.back();
          }
        }
      });
    });
  };

  return (
    <>
      {showConfirmModal ? (
        <SuccessActionModal
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
          onSave={handleGoodsRequest}
          title="Are you sure you want to confirm this request"
        />
      ) : (
        <></>
      )}
      <div className="p-9">
        <div className="mb-7">
          <h1 className="fs-22 fw-bolder">Ask seller to send goods</h1>
        </div>
        {!initLoader ? (
          <>
            <Card className="border bg-f9f9f9 mb-7">
              <Card.Body>
                <Row className="align-items-center">
                  <Col md={6} lg={4}>
                    <Form.Label className="fs-16 fw-500">
                      Seller name
                    </Form.Label>
                    <CustomSelect
                      disabled={true}
                      backgroundColor="#ffff"
                      isLoading={fetchLoader}
                      options={
                        sellerData && sellerData.length
                          ? sellerData.map((val: any) => {
                              return {
                                label: (
                                  <>
                                    <div className="symbol symbol-30px border me-3">
                                      <img
                                        src={val.image}
                                        className="object-fit-contain"
                                        alt=""
                                      />
                                    </div>
                                    <span className="fs-16 fw-600 text-black mb-0">
                                      {val.name}
                                    </span>
                                  </>
                                ),
                                value: val._id,
                                creationDate: val._createdAt,
                                title: val.name,
                              };
                            })
                          : []
                      }
                      isDisabled={loading}
                      onMenuScrollToBottom={onMenuScrollToBottom}
                      onMenuClose={() => {
                        onMenuClose();
                      }}
                      onMenuOpen={() => {
                        onMenuOpen();
                      }}
                      onInputChange={(newValue: any, { action }: any) => {
                        if (action === "input-change") {
                          handleInputChange(newValue);
                        }
                      }}
                      onChange={(event: any) => {
                        handleSeller(event);
                      }}
                      value={
                        sellerData && sellerData.length
                          ? sellerData
                              .map((val: any) => {
                                return {
                                  label: (
                                    <>
                                      <div className="symbol symbol-30px border me-3">
                                        <img
                                          src={val.image}
                                          className="object-fit-contain"
                                          alt=""
                                        />
                                      </div>
                                      <span className="fs-16 fw-600 text-black mb-0">
                                        {val.name}
                                      </span>
                                    </>
                                  ),
                                  value: val._id,
                                  creationDate: val._createdAt,
                                  title: val.name,
                                };
                              })
                              .find(
                                (item: any) => item.value === selectedSeller
                              )
                          : undefined
                      }
                    />
                  </Col>
                  <Col md={6} lg={8}>
                    <Form.Label className="fs-16 fw-500 text-dark pb-2">
                      Transportation service provided by
                    </Form.Label>
                    <div className="d-flex flex-wrap gap-2">
                      <div className="form-check form-check-custom form-check-solid me-6 mb-lg-0">
                        <input
                          className="form-check-input h-30px w-30px h-md-35px w-md-35px"
                          type="checkbox"
                          checked={shipmentType === UTradeTransport}
                          onClick={() => {
                            setShipmentType(UTradeTransport);
                          }}
                          disabled={loading}
                        />
                        <label className="form-check-label text-dark fs-16 fw-600 ms-3">
                          U Trade transport service
                        </label>
                      </div>
                      <div className="form-check form-check-custom form-check-solid me-6 mb-lg-0">
                        <input
                          className="form-check-input h-30px w-30px h-md-35px w-md-35px"
                          type="checkbox"
                          checked={shipmentType === SelfTransport}
                          onClick={() => {
                            setShipmentType(SelfTransport);
                          }}
                          disabled={loading}
                        />
                        <label className="form-check-label text-dark fs-16 fw-600 ms-3">
                          Self transport
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {!selectedSeller ? (
              <Card className="border bg-f9f9f9 mb-9">
                <Card.Header className="border-bottom-0">
                  <Card.Title className="fs-22 fw-bolder">
                    Products & quantities
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <div className="fs-18 fw-500 text-center min-h-50px">
                    Please select a seller to see products.
                  </div>
                </Card.Body>
              </Card>
            ) : (
              <>
                <Card className="border bg-f9f9f9 py-3">
                  <Card.Header className="border-bottom-0 mb-3">
                    <Card.Title className="fs-22 fw-bolder">
                      Products & quantities
                    </Card.Title>
                  </Card.Header>
                  <Card.Body className="pt-0 pb-5">
                    <>
                      {/* {productFetchLoader ? (
                    <>
                      <div className="fs-18 fw-500 text-center min-h-50px">
                        <Loader loading={productFetchLoader} />
                      </div>
                    </>
                  ) : ( */}
                      <>
                        {products.length ? (
                          <>
                            {products.map((val: any, index: number) => {
                              return (
                                <>
                                  <Row className="align-items-center g-3 g-lg-5">
                                    <Col md={5} lg={4}>
                                      <Form.Label className="fs-16 fw-500">
                                        Select product
                                      </Form.Label>
                                      <CustomSelect
                                        backgroundColor="#ffff"
                                        isLoading={productFetchLoader}
                                        options={productOptionsList}
                                        value={
                                          productOptionsList.find(
                                            (item: any) =>
                                              item.value === val.variant
                                          ) || undefined
                                        }
                                        // options={
                                        //   sellerProducts && sellerProducts.length
                                        //     ? sellerProducts
                                        //         .filter(
                                        //           (val: any) =>
                                        //             !products.some(
                                        //               (item: any) =>
                                        //                 item.variant === val._id
                                        //             )
                                        //         )
                                        //         .map((val: any) => {
                                        //           return {
                                        //             label: (
                                        //               <>
                                        //                 <div className="symbol symbol-30px border me-3">
                                        //                   <img
                                        //                     src={val.media[0].url}
                                        //                     className="object-fit-contain"
                                        //                     alt=""
                                        //                   />
                                        //                 </div>
                                        //                 <span className="fs-16 fw-600 text-black mb-0">
                                        //                   {val.title.replace(
                                        //                     /\s*\)\s*/g,
                                        //                     ')'
                                        //                   )}
                                        //                 </span>
                                        //               </>
                                        //             ),
                                        //             value: val._id,
                                        //             title: val.title,
                                        //           };
                                        //         })
                                        //     : []
                                        // }
                                        // value={
                                        //   sellerProducts && sellerProducts.length
                                        //     ? sellerProducts
                                        //         .filter(
                                        //           (item: any) =>
                                        //             item._id === val.variant
                                        //         )
                                        //         .map((val: any) => ({
                                        //           label: (
                                        //             <>
                                        //               <div className="symbol symbol-30px border me-3">
                                        //                 <img
                                        //                   src={val.media[0].url}
                                        //                   className="object-fit-contain"
                                        //                   alt=""
                                        //                 />
                                        //               </div>
                                        //               <span className="fs-16 fw-600 text-black mb-0">
                                        //                 {val.title.replace(
                                        //                   /\s*\)\s*/g,
                                        //                   ')'
                                        //                 )}
                                        //               </span>
                                        //             </>
                                        //           ),
                                        //           value: val._id,
                                        //           title: val.title,
                                        //         }))
                                        //     : null // Set to null when no match is found
                                        // }
                                        onMenuScrollToBottom={
                                          onMenuProductScrollToBottom
                                        }
                                        onMenuClose={() => {
                                          onMenuProductClose();
                                        }}
                                        onMenuOpen={() => {
                                          onMenuProductOpen();
                                        }}
                                        onInputChange={(
                                          newValue: any,
                                          { action }: any
                                        ) => {
                                          if (action === "input-change") {
                                            handleProductInputChange(newValue);
                                          }
                                        }}
                                        onChange={(event: any) => {
                                          handleProductSelect(event, index);
                                        }}
                                        disabled={true}
                                      />
                                      {/* {productValidation[index].quantityTypes ? (
                                    <Form.Label className="fs-16 fw-500"></Form.Label>
                                  ) : (
                                    <></>
                                  )} */}
                                    </Col>
                                    {val.quantityTypes ? (
                                      //  &&val.quantityTypes.length
                                      <>
                                        <Col xs={11} md={6} lg={7}>
                                          <Form.Label className="fs-16 fw-500">
                                            Quantity
                                          </Form.Label>
                                          <Row className="align-items-center g-3">
                                            <Col sm={4} md={6} lg={4}>
                                              <InputGroup className="border border-r8px">
                                                <Form.Control
                                                  className="form-control-custom fs-16 fw-600 border-0 text-end bg-white ps-3"
                                                  placeholder="cartons"
                                                  value={
                                                    val.quantityTypes.find(
                                                      (item: any) =>
                                                        item.type ===
                                                          CartonWithDozens ||
                                                        item.type ===
                                                          CartonWithPieces
                                                    )?.stockCount ?? 0
                                                  }
                                                  // disabled={
                                                  //   !val.quantityTypes.some(
                                                  //     (item: any) =>
                                                  //       item.type ===
                                                  //         CartonWithDozens ||
                                                  //       item.type ===
                                                  //         CartonWithPieces
                                                  //   )
                                                  // }
                                                  disabled={true}
                                                  onChange={(event: any) => {
                                                    handleQuantityChange(
                                                      event?.target.value.trimStart(),
                                                      index,
                                                      val.quantityTypes.findIndex(
                                                        (item: any) =>
                                                          item.type ===
                                                            CartonWithDozens ||
                                                          item.type ===
                                                            CartonWithPieces
                                                      ),
                                                      val.quantityTypes.find(
                                                        (item: any) =>
                                                          item.type ===
                                                            CartonWithDozens ||
                                                          item.type ===
                                                            CartonWithPieces
                                                      )?.type
                                                    );
                                                  }}
                                                  type="number"
                                                />
                                                <InputGroup.Text className="fs-16 fw-600 text-dark bg-white border-0 pe-1">
                                                  cartons
                                                </InputGroup.Text>
                                              </InputGroup>
                                            </Col>
                                            <Col sm={4} md={6} lg={4}>
                                              <InputGroup className="border border-r8px">
                                                <Form.Control
                                                  className="form-control-custom fs-16 fw-600 border-0 text-end bg-white ps-3"
                                                  placeholder="dozens"
                                                  value={
                                                    val.quantityTypes.find(
                                                      (item: any) =>
                                                        item.type === Dozen
                                                    )?.stockCount ?? 0
                                                  }
                                                  // disabled={
                                                  //   !val.quantityTypes.some(
                                                  //     (item: any) =>
                                                  //       item.type === Dozen
                                                  //   )
                                                  // }
                                                  disabled={true}
                                                  onChange={(event: any) => {
                                                    handleQuantityChange(
                                                      event?.target.value.trimStart(),
                                                      index,
                                                      val.quantityTypes.findIndex(
                                                        (item: any) =>
                                                          item.type === Dozen
                                                      ),
                                                      Dozen
                                                    );
                                                  }}
                                                  type="number"
                                                />
                                                <InputGroup.Text className="fs-16 fw-600 text-dark bg-white border-0 pe-1">
                                                  dozens
                                                </InputGroup.Text>
                                              </InputGroup>
                                            </Col>
                                            <Col sm={4} md={6} lg={4}>
                                              <InputGroup className="border border-r8px">
                                                <Form.Control
                                                  className="form-control-custom fs-16 fw-600 border-0 text-end bg-white "
                                                  placeholder="pieces"
                                                  value={
                                                    val.quantityTypes.find(
                                                      (item: any) =>
                                                        item.type === Piece
                                                    )?.stockCount ?? 0
                                                  }
                                                  // disabled={
                                                  //   !val.quantityTypes.some(
                                                  //     (item: any) =>
                                                  //       item.type === Piece
                                                  //   )
                                                  // }
                                                  disabled={true}
                                                  onChange={(event: any) => {
                                                    handleQuantityChange(
                                                      event?.target.value.trimStart(),
                                                      index,
                                                      val.quantityTypes.findIndex(
                                                        (item: any) =>
                                                          item.type === Piece
                                                      ),
                                                      Piece
                                                    );
                                                  }}
                                                  type="number"
                                                />{" "}
                                                <InputGroup.Text className="fs-16 fw-600 text-dark bg-white border-0 pe-1">
                                                  pieces
                                                </InputGroup.Text>
                                              </InputGroup>
                                            </Col>
                                          </Row>
                                          {/* {productValidation[index]
                                        .quantityTypes ? (
                                        <Form.Label className="fs-12 fw-500 mt-2 text-danger">
                                          At least one stock must have a value
                                          greater than 0 .
                                        </Form.Label>
                                      ) : (
                                        <></>
                                      )} */}
                                        </Col>
                                      </>
                                    ) : (
                                      <Col xs={11} md={6} lg={7}></Col>
                                    )}
                                    {/* {products.length === 1 ? (
                                      <></>
                                    ) : (
                                      <>
                                        <Col
                                          xs={1}
                                          className="text-end mt-13"
                                        >
                                          <Button
                                            variant="link"
                                            className="btn-flush"
                                            onClick={() => {
                                              handleRemove(index);
                                            }}
                                            disabled={loading}
                                          >
                                            <img
                                              width={22}
                                              height={22}
                                              className="img-fluid"
                                              src={GrayCross}
                                              alt=""
                                            />
                                          </Button>
                                        </Col>
                                      </>
                                    )} */}
                                  </Row>
                                  {index === products.length - 1 ? (
                                    <>
                                      <>
                                        {sellerProducts &&
                                        sellerProducts.length ? (
                                          sellerProducts.filter(
                                            (val: any) =>
                                              !products.some(
                                                (item: any) =>
                                                  item.variant === val._id
                                              )
                                          ).length ? (
                                            <Row className="d-flex justify-content-start">
                                              {/* <Col className="d-flex justify-content-start mt-13">
                                            <Button
                                              variant="link"
                                              className="btn-flush "
                                              disabled={loading}
                                              onClick={() => {
                                                handleAddMore();
                                              }}
                                            >
                                              <span className="text-primary fs-16 fw-600">
                                                + Add new goods
                                              </span>
                                            </Button>
                                          </Col> */}
                                            </Row>
                                          ) : (
                                            <></>
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    </>
                                  ) : (
                                    <>
                                      <div className="separator my-6"></div>
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <div className="fs-18 fw-500 text-center min-h-50px">
                              No products available
                            </div>
                          </>
                        )}
                      </>
                      {/* )} */}
                    </>
                  </Card.Body>
                </Card>
                {costDetails && Object.keys(costDetails).length ? (
                  <Row className="g-7 mt-auto">
                    {costDetails.totalQuantities.length ? (
                      <>
                        <Col md={6}>
                          <Card className="card-stretch py-3 bg-light border border-r10px">
                            <Card.Header className="border-bottom-0">
                              <Card.Title>
                                <h5 className="fs-22 fw-bolder">
                                  Goods details
                                </h5>
                              </Card.Title>
                            </Card.Header>
                            <Card.Body className="pt-0 pb-auto">
                              <Row className="g-4">
                                {costDetails.totalQuantities.map((val: any) => {
                                  return (
                                    <Col xs={12}>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <span className="fs-16 fw-500">
                                          {val.quantityType ===
                                            CartonWithDozens ||
                                          val.quantityType === CartonWithPieces
                                            ? val.quantityType ===
                                              CartonWithPieces
                                              ? "Cartons with pieces:"
                                              : "Cartons with dozens:"
                                            : ""}{" "}
                                          {val.quantityType === Dozen
                                            ? "Dozens:"
                                            : ""}{" "}
                                          {val.quantityType === Piece
                                            ? "Pieces:"
                                            : ""}{" "}
                                        </span>
                                        <span className="fs-16 fw-600">
                                          {val.stockCount}
                                        </span>
                                      </div>
                                    </Col>
                                  );
                                })}
                                <Col xs={12}>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <span className="fs-16 fw-500">
                                      Total weight:
                                    </span>
                                    <span className="fs-16 fw-600">
                                      {costDetails.totalWeight.toFixed(2)} Kg
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                    <>
                      {costDetails && Object.keys(costDetails).length ? (
                        <>
                          <Col md={6}>
                            <Card className="card-stretch py-3 bg-light border border-r10px">
                              <Card.Header className="border-bottom-0">
                                <Card.Title>
                                  <h5 className="fs-22 fw-bolder">
                                    Cost details
                                  </h5>
                                </Card.Title>
                              </Card.Header>
                              <Card.Body className="pt-0 pb-auto">
                                <Row className="g-4">
                                  <Col xs={12}>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <span className="fs-16 fw-500">
                                        Warehouse storage fees:
                                      </span>
                                      <span className="fs-16 fw-600">
                                        {costDetails?.costInfo.fees.find(
                                          (item: any) =>
                                            item.type === WarehouseStorage
                                        )?.discountAmt ? (
                                          <>
                                            {costDetails?.costInfo.fees.find(
                                              (item: any) =>
                                                item.type === WarehouseStorage
                                            )?.discountReason === 1 ? (
                                              <></>
                                            ) : (
                                              <>
                                                {" "}
                                                <span>
                                                  TSh{" "}
                                                  {Method.getGeneralizedAmount(
                                                    costDetails?.costInfo.fees.find(
                                                      (item: any) =>
                                                        item.type ===
                                                        WarehouseStorage
                                                    )?.discountAmt
                                                  )}
                                                </span>{" "}
                                              </>
                                            )}
                                            <span className="text-decoration-line-through">
                                              TSh{" "}
                                              {Method.getGeneralizedAmount(
                                                costDetails?.costInfo.fees.find(
                                                  (item: any) =>
                                                    item.type ===
                                                    WarehouseStorage
                                                )?.amount
                                              )}
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            <span className="">
                                              TSh{" "}
                                              {costDetails?.costInfo.fees.find(
                                                (item: any) =>
                                                  item.type === WarehouseStorage
                                              )?.amount
                                                ? Method.getGeneralizedAmount(
                                                    costDetails?.costInfo.fees.find(
                                                      (item: any) =>
                                                        item.type ===
                                                        WarehouseStorage
                                                    )?.amount
                                                  )
                                                : 0}
                                            </span>
                                          </>
                                        )}
                                      </span>
                                    </div>
                                  </Col>
                                  {/* {shipmentType === UTradeTransport ? (
                        <Col xs={12}>
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="fs-16 fw-500">
                              Shipping fees:
                            </span>
                            <span className="fs-16 fw-600 text-decoration-line-through">
                              TSh{' '}
                              {costDetails?.costInfo.fees.find(
                                (item: any) => item.type === Shipping
                              )?.discountAmt ??
                                costDetails?.costInfo.fees.find(
                                  (item: any) => item.type === Shipping
                                )?.amount}
                            </span>
                          </div>
                        </Col>
                      ) : (
                        <></>
                      )}
                       */}
                                  {shipmentType === UTradeTransport ? (
                                    <Col xs={12}>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <span className="fs-16 fw-500">
                                          Shipping fees:
                                        </span>
                                        <span className="fs-16 fw-500">
                                          {costDetails?.costInfo.fees.find(
                                            (item: any) =>
                                              item.type === Shipping
                                          )?.discountAmt ? (
                                            <>
                                              {costDetails?.costInfo.fees.find(
                                                (item: any) =>
                                                  item.type === Shipping
                                              )?.discountReason === 1 ? (
                                                <></>
                                              ) : (
                                                <>
                                                  <span>
                                                    TSh{" "}
                                                    {Method.getGeneralizedAmount(
                                                      costDetails?.costInfo.fees.find(
                                                        (item: any) =>
                                                          item.type === Shipping
                                                      )?.discountAmt
                                                    )}
                                                  </span>
                                                </>
                                              )}
                                              <span className="fs-16 fw-500 text-decoration-line-through">
                                                TSh{" "}
                                                {costDetails?.costInfo.fees.find(
                                                  (item: any) =>
                                                    item.type === Shipping
                                                )?.amount
                                                  ? Method.getGeneralizedAmount(
                                                      costDetails?.costInfo.fees.find(
                                                        (item: any) =>
                                                          item.type === Shipping
                                                      )?.amount
                                                    )
                                                  : 0}
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              <span>
                                                TSh{" "}
                                                {costDetails?.costInfo.fees.find(
                                                  (item: any) =>
                                                    item.type === Shipping
                                                )?.amount
                                                  ? Method.getGeneralizedAmount(
                                                      costDetails?.costInfo.fees.find(
                                                        (item: any) =>
                                                          item.type === Shipping
                                                      )?.amount
                                                    )
                                                  : 0}
                                              </span>
                                            </>
                                          )}{" "}
                                        </span>
                                      </div>
                                    </Col>
                                  ) : (
                                    <></>
                                  )}{" "}
                                  {costDetails?.costInfo.fees.some(
                                    (item: any) => item.discountReason === 1
                                  ) ? (
                                    <div className="d-flex">
                                      <div className="alert alert-warning border-0  d-flex align-items-center p-5 mb-0">
                                        <div className="me-4">
                                          <img src={WarningIcon} alt="" />
                                        </div>
                                        <div className="d-flex flex-column">
                                          <h4 className="text-dark">
                                            {costDetails?.costInfo.fees.find(
                                              (item: any) =>
                                                item.type === WarehouseStorage
                                            )?.discountReason === 1
                                              ? "No warehouse storage fees "
                                              : ""}
                                            {shipmentType ===
                                            UTradeTransport ? (
                                              <>
                                                {costDetails?.costInfo.fees.find(
                                                  (item: any) =>
                                                    item.type === Shipping
                                                )?.discountReason === 1
                                                  ? "and No shipping fees "
                                                  : ""}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            for 6 months.
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </Row>
                              </Card.Body>
                              <Card.Footer>
                                <div className="d-flex align-items-center justify-content-between">
                                  <span className="fs-22 fw-bolder">
                                    Total cost
                                  </span>
                                  <span className="fs-16 fw-700">
                                    TSh{" "}
                                    {Method.getGeneralizedAmount(
                                      costDetails.costInfo.charge
                                    )}
                                  </span>
                                </div>
                              </Card.Footer>
                            </Card>
                          </Col>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  </Row>
                ) : (
                  <></>
                )}
              </>
            )}
            {/* BUTTON */}
            <div className="d-flex justify-content-end  mt-6">
              <Button
                size="lg"
                className="min-h-lg-60px"
                onClick={() => setShowConfirmModal(true)}
                disabled={loading}
              >
                {!loading && (
                  <span className="indicator-label fs-16 fw-bold">
                    Confirm request
                  </span>
                )}
                {loading && (
                  <span
                    className="indicator-progress fs-16 fw-bold"
                    style={{ display: "block" }}
                  >
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </Button>
            </div>
          </>
        ) : (
          <div className="d-flex justify-content-center">
            <Loader loading={initLoader} />
          </div>
        )}
      </div>
    </>
  );
};
export default SendGoodsSeller;
