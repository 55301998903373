import { Button, Card, Col, Form, Row } from "react-bootstrap";
import GrayClose from "../../../_admin/assets/media/svg_uTrade/cross_gray.svg";
import AddPlus from "../../../_admin/assets/media/svg_uTrade/plus-blue.svg";
import { useEffect, useState } from "react";
import APICallService from "../../../api/apiCallService";
import { useNavigate, useLocation } from "react-router-dom";
import { orderReturn } from "../../../api/apiEndPoints";
import { orderDeliveryJSON } from "../../../api/apiJSON/order";
import Loader from "../../../Global/loader";
import Method from "../../../utils/methods";
import {
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  OrdersDelivery,
  Piece,
  ReceivedDirectGood,
} from "../../../utils/constants";
import { CustomSelect } from "../../custom/Select/CustomSelect";
import Validations from "../../../utils/validations";
import { getKey } from "../../../Global/history";
import { warehouse as warehouseStoreString } from "../../../utils/storeString";
const AdjustReturnOrder = (props: any) => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const [markAsGoodsCollected, setMarkAsGoodsCollected] = useState(false);
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [fetchLoader, setFetchLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<any>();
  const [variants, setVariants] = useState<any>([]);
  const [batchValidation, setBatchValidation] = useState<any>([]);
  useEffect(() => {
    (async () => {
      await fetchDraftProducts();
    })();
  }, []);
  const fetchDraftProducts = async () => {
    setFetchLoader(true);
    let apiService = new APICallService(
      orderReturn.list,
      orderDeliveryJSON.returnList({
        route: state.routeId,
        vehicle: state.vehicle,
        warehouse: tempWarehouse?._id || null,
      }),
      { _id: state.orderId },
      "",
      "",
      "",
      OrdersDelivery,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    let temp: any = [];
    let tempValidation: any = [];
    await response.routeVehicle.variants.map((val: any) => {
      if (val.returnedStockCount) {
        temp.push({
          id: val.variant._id,
          title: val.variant.title,
          image: val.variant.media[0].url,
          businessName: val.business.name,
          batch: val.batches,
          selectedBatch: [],
          quantityType: val.quantityType,
          returnedStockCount: val.returnedStockCount
            ? val.returnedStockCount
            : val.stockCount,
        });
        tempValidation.push({ quantityGreater: false, selection: false });
      }
    });
    await setBatchValidation(tempValidation);
    await setVariants(temp);
    await setDetails(response);
    setFetchLoader(false);
  };
  const handleSelect = (event: any, index: number) => {
    let temp = [...variants];
    let tempValidation = [...batchValidation];
    tempValidation[index].selection = false;
    temp[index].selectedBatch.push({
      batch: event.batch,
      expiry: event.expiry,
      quantityType: event.quantityType,
      maxStockCount: event.stockCount,
      stockCount: 0,
    });
    setBatchValidation(tempValidation);
    setVariants(temp);
  };
  const handleChangeBatch = (event: any, index: number, batchIndex: number) => {
    let temp = [...variants];
    let tempValidation = [...batchValidation];
    tempValidation[index].selection = false;
    temp[index].selectedBatch[batchIndex] = {
      batch: event.batch,
      expiry: event.expiry,
      quantityType: event.quantityType,
      maxStockCount: event.stockCount,
      stockCount: 0,
    };
    setBatchValidation(tempValidation);
    setVariants(temp);
  };
  const handleAddMore = (index: number) => {
    let temp = [...variants];
    let notSelected = temp[index].batch.filter((batch: any) => {
      const isSelected = !temp[index].selectedBatch.some(
        (selected: any) => parseInt(selected.batch) === parseInt(batch.batch)
      );
      return isSelected;
    });
    if (notSelected.length) {
      temp[index].selectedBatch.push({
        ...notSelected[0],
        maxStockCount: notSelected[0].stockCount,
        stockCount: 0,
      });
    }
    setVariants(temp);
  };
  const handleRemove = (index: number, batchIndex: number) => {
    let temp = [...variants];
    temp[index].selectedBatch.splice(batchIndex, 1);
    setVariants(temp);
  };
  const handleQuantityChange = (
    value: any,
    index: number,
    batchIndex: number
  ) => {
    let temp = JSON.parse(JSON.stringify([...variants]));
    temp[index].selectedBatch[batchIndex].stockCount = value;
    setVariants(temp);
  };
  useEffect(() => {
    let temp = [...batchValidation];
    let tempVariants = JSON.parse(JSON.stringify([...variants]));
    if (tempVariants.length) {
      tempVariants.forEach((product: any, index: number) => {
        if (product.selectedBatch.length) {
          const totalSelectedBatchStock = product.selectedBatch.reduce(
            (total: any, batch: any) => total + parseInt(batch.stockCount),
            0
          );
          if (totalSelectedBatchStock === product.returnedStockCount) {
            temp[index].quantityGreater = false;
          } else {
            temp[index].quantityGreater = true;
          }
        }
      });
      setBatchValidation(temp);
    }
  }, [variants]);
  const handleOnKeyPress = async (event: any) => {
    // Allow only numbers
    const key = event.key;
    let validations = await Validations.allowOnlyNumbers(key);
    if (!validations) {
      event.preventDefault();
      return false;
    }
    return true;
  };
  const handleSubmit = async () => {
    let temp = [...batchValidation];
    if (variants.length) {
      variants.forEach((product: any, index: number) => {
        if (product.selectedBatch.length) {
          temp[index].selection = false;
          const totalSelectedBatchStock = product.selectedBatch.reduce(
            (total: any, batch: any) => total + parseInt(batch.stockCount),
            0
          );
          if (totalSelectedBatchStock === product.returnedStockCount) {
            temp[index].quantityGreater = false;
          } else {
            temp[index].quantityGreater = true;
          }
        } else {
          temp[index].selection = true;
        }
      });
    }
    const allTrue = temp.every(
      (obj) => obj.quantityGreater === false && obj.selection === false
    );
    if (allTrue) {
      setLoading(true);
      let data: any = [];
      variants.map((val: any) => {
        val.selectedBatch.map((batchVal: any) => {
          data.push({
            variant: val.id,
            quantityType: batchVal.quantityType,
            stockCount: batchVal.stockCount,
            maxStockCount: batchVal.maxStockCount,
            expiry: batchVal.expiry,
            batch: batchVal.batch,
          });
        });
      });
      const filteredData = data.filter(
        (item: any) => parseInt(item.stockCount) > 0
      );
      if (validateObjects(filteredData)) {
        let apiService = new APICallService(
          orderReturn.stockAdjust,
          orderDeliveryJSON.returnAdjust({
            route: state.routeId,
            vehicle: state.vehicle,
            variants: filteredData,
          }),
          { _id: state.orderId },
          "",
          "",
          "",
          OrdersDelivery,
          tempWarehouse?._id
        );
        let response = await apiService.callAPI();
        if (response) {
          window.history.back();
        }
      }
      setLoading(false);
    }
    setBatchValidation(temp);
    setLoading(false);
  };
  function validateObjects(data: any) {
    for (const item of data) {
      if (item.stockCount > item.maxStockCount) {
        return false; // Validation failed
      }
    }
    return true; // All objects passed validation
  }
  return (
    <>
      <div className="p-9">
        {/* <MarkAsGoodsCollected
          show={markAsGoodsCollected}
          onHide={() => setMarkAsGoodsCollected(false)}
        /> */}
        <Row className="align-items-center g-3 mb-7">
          <Col xs>
            <h1 className="fs-22 fw-bolder mb-0">Add Goods to Inventory</h1>
          </Col>
        </Row>
        <Card className="border border-r10px">
          <Card.Body className="px-0 py-3">
            <div className="table-responsive">
              <table className="table table-row table-rounded align-middle gs-8 gy-4 mb-0">
                <thead>
                  <tr className="fs-16 fw-600 align-middle">
                    <th className="min-w-150px text-start">Product name</th>
                    <th className="w-125px text-center">Cartons</th>
                    <th className="w-125px text-center">Dozens</th>
                    <th className="w-125px text-center">Pieces</th>
                    <th className="text-end"></th>
                  </tr>
                </thead>
                <tbody>
                  {fetchLoader ? (
                    <>
                      <tr>
                        <td colSpan={5}>
                          <div className="d-flex justify-content-center">
                            <Loader loading={fetchLoader} />
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {variants.length &&
                        variants.map((val: any, index: number) => {
                          return (
                            <>
                              <tr>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-50px border me-3">
                                      <img
                                        src={val.image}
                                        className="object-fit-contain"
                                        alt=""
                                      />
                                    </div>
                                    <div className="d-flex justify-content-start flex-column">
                                      <span className="fs-16 fw-500 text-dark">
                                        {val.title}
                                      </span>
                                      <span className="d-block fs-15 fw-500 text-gray">
                                        {val.businessName}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td className="text-center">
                                  <span className="fs-16 fw-500">
                                    {`${
                                      val.quantityType === CartonWithDozens ||
                                      val.quantityType === CartonWithPieces
                                        ? val.returnedStockCount
                                        : "-"
                                    }`}
                                  </span>
                                </td>
                                <td className="text-center">
                                  <span className="fs-16 fw-500">
                                    {`${
                                      val.quantityType === Dozen
                                        ? val.returnedStockCount
                                        : "-"
                                    }`}
                                  </span>
                                </td>
                                <td className="text-center">
                                  <span className="fs-16 fw-500">
                                    {`${
                                      val.quantityType === Piece
                                        ? val.returnedStockCount
                                        : "-"
                                    }`}
                                  </span>
                                </td>
                              </tr>
                              {val.selectedBatch.length ? (
                                <>
                                  {val.selectedBatch.map(
                                    (batchVal: any, batchIndex: number) => {
                                      return (
                                        <>
                                          {" "}
                                          <tr>
                                            <td>
                                              <div className="w-300px mt-4">
                                                <CustomSelect
                                                  onChange={(event: any) => {
                                                    handleChangeBatch(
                                                      event,
                                                      index,
                                                      batchIndex
                                                    );
                                                  }}
                                                  border={
                                                    batchValidation[index]
                                                      .selection
                                                      ? "#e55451"
                                                      : ""
                                                  }
                                                  options={
                                                    val.batch.length
                                                      ? variants[index].batch
                                                          .filter(
                                                            (batch: any) => {
                                                              const isSelected =
                                                                !variants[
                                                                  index
                                                                ].selectedBatch.some(
                                                                  (
                                                                    selected: any
                                                                  ) =>
                                                                    parseInt(
                                                                      selected.batch
                                                                    ) ===
                                                                    parseInt(
                                                                      batch.batch
                                                                    )
                                                                );
                                                              return isSelected;
                                                            }
                                                          )
                                                          .map(
                                                            (catval: any) => {
                                                              return {
                                                                value:
                                                                  catval.batch,
                                                                label: `Batch ${
                                                                  catval.batch
                                                                } - ${Method.convertDateToDDMMYYYY(
                                                                  catval.expiry
                                                                )}`,
                                                                batch:
                                                                  catval.batch,
                                                                expiry:
                                                                  catval.expiry,
                                                                quantityType:
                                                                  catval.quantityType,
                                                                stockCount:
                                                                  catval.stockCount,
                                                                title: `Batch ${
                                                                  catval.batch
                                                                } - ${Method.convertDateToDDMMYYYY(
                                                                  catval.expiry
                                                                )}`,
                                                              };
                                                            }
                                                          )
                                                      : []
                                                  }
                                                  value={
                                                    val.batch.length
                                                      ? val.batch
                                                          .filter(
                                                            (item: any) =>
                                                              batchVal.batch ===
                                                              item.batch
                                                          )
                                                          .map(
                                                            (catval: any) => {
                                                              return {
                                                                value:
                                                                  catval.batch,
                                                                label: `Batch ${
                                                                  catval.batch
                                                                } - ${Method.convertDateToDDMMYYYY(
                                                                  catval.expiry
                                                                )}`,
                                                                batch:
                                                                  catval.batch,
                                                                expiry:
                                                                  catval.expiry,
                                                                quantityType:
                                                                  catval.quantityType,
                                                                stockCount:
                                                                  catval.stockCount,
                                                                title: `Batch ${
                                                                  catval.batch
                                                                } - ${Method.convertDateToDDMMYYYY(
                                                                  catval.expiry
                                                                )}`,
                                                              };
                                                            }
                                                          )
                                                      : []
                                                  }
                                                />
                                              </div>
                                            </td>
                                            <td className="text-center">
                                              <div className="stepperInput">
                                                <Button
                                                  size="sm"
                                                  className="button button--addOnLeft"
                                                  disabled={
                                                    !(
                                                      val.quantityType ===
                                                        CartonWithDozens ||
                                                      val.quantityType ===
                                                        CartonWithPieces
                                                    )
                                                  }
                                                  onClick={(event) => {
                                                    const currentStockCount =
                                                      parseInt(
                                                        batchVal.stockCount !==
                                                          undefined
                                                          ? batchVal.stockCount
                                                          : 0
                                                      );
                                                    if (
                                                      currentStockCount > 0 &&
                                                      currentStockCount - 1 !==
                                                        0
                                                    ) {
                                                      handleQuantityChange(
                                                        currentStockCount - 1,
                                                        index,
                                                        batchIndex
                                                      );
                                                    }
                                                  }}
                                                >
                                                  -
                                                </Button>
                                                <input
                                                  type="number"
                                                  disabled={
                                                    !(
                                                      val.quantityType ===
                                                        CartonWithDozens ||
                                                      val.quantityType ===
                                                        CartonWithPieces
                                                    )
                                                  }
                                                  value={
                                                    val.quantityType ===
                                                      CartonWithDozens ||
                                                    val.quantityType ===
                                                      CartonWithPieces
                                                      ? batchVal.stockCount
                                                      : "0"
                                                  }
                                                  className="input stepperInput__input form-control"
                                                  onChange={(event: any) => {
                                                    const currentStockCount =
                                                      parseInt(
                                                        event.target.value
                                                      );
                                                    handleQuantityChange(
                                                      currentStockCount,
                                                      index,
                                                      batchIndex
                                                    );
                                                  }}
                                                  onKeyPress={(event: any) => {
                                                    handleOnKeyPress(event);
                                                  }}
                                                />
                                                <Button
                                                  size="sm"
                                                  className="button button--addOnRight"
                                                  disabled={
                                                    !(
                                                      val.quantityType ===
                                                        CartonWithDozens ||
                                                      val.quantityType ===
                                                        CartonWithPieces
                                                    )
                                                  }
                                                  onClick={() => {
                                                    const currentStockCount =
                                                      parseInt(
                                                        batchVal.stockCount !==
                                                          undefined
                                                          ? batchVal.stockCount
                                                          : 0
                                                      );
                                                    if (
                                                      currentStockCount >= 0 &&
                                                      currentStockCount + 1 <=
                                                        batchVal.maxStockCount
                                                    ) {
                                                      handleQuantityChange(
                                                        currentStockCount + 1,
                                                        index,
                                                        batchIndex
                                                      );
                                                    }
                                                  }}
                                                >
                                                  +
                                                </Button>
                                              </div>
                                              {/* <Form.Control
                                                className="form-control-custom fs-14 fw-600 w-88px min-h-45px px-3 text-center"
                                                disabled={
                                                  !(
                                                    val.quantityType ===
                                                      CartonWithDozens ||
                                                    val.quantityType ===
                                                      CartonWithPieces
                                                  )
                                                }
                                                value={
                                                  val.quantityType ===
                                                    CartonWithDozens ||
                                                  val.quantityType ===
                                                    CartonWithPieces
                                                    ? batchVal.stockCount
                                                    : '0'
                                                }
                                                onChange={(event: any) => {
                                                  handleQuantityChange(
                                                    event,
                                                    index,
                                                    batchIndex
                                                  );
                                                }}
                                                onKeyPress={(event: any) => {
                                                  handleOnKeyPress(event);
                                                }}
                                                type="text"
                                              /> */}
                                            </td>
                                            <td className="text-center">
                                              {/* <Form.Control
                                                className="form-control-custom fs-14 fw-600 w-88px min-h-45px px-3 text-center"
                                                disabled={
                                                  !(val.quantityType === Dozen)
                                                }
                                                value={
                                                  val.quantityType === Dozen
                                                    ? batchVal.stockCount
                                                    : '0'
                                                }
                                                onChange={(event: any) => {
                                                  handleQuantityChange(
                                                    event,
                                                    index,
                                                    batchIndex
                                                  );
                                                }}
                                                onKeyPress={(event: any) => {
                                                  handleOnKeyPress(event);
                                                }}
                                                type="text"
                                              /> */}
                                              <div className="stepperInput">
                                                <Button
                                                  size="sm"
                                                  className="button button--addOnLeft"
                                                  disabled={
                                                    !(
                                                      val.quantityType === Dozen
                                                    )
                                                  }
                                                  onClick={(event) => {
                                                    const currentStockCount =
                                                      parseInt(
                                                        batchVal.stockCount !==
                                                          undefined
                                                          ? batchVal.stockCount
                                                          : 0
                                                      );
                                                    if (
                                                      currentStockCount > 0 &&
                                                      currentStockCount - 1 !==
                                                        0
                                                    ) {
                                                      handleQuantityChange(
                                                        currentStockCount - 1,
                                                        index,
                                                        batchIndex
                                                      );
                                                    }
                                                  }}
                                                >
                                                  -
                                                </Button>
                                                <input
                                                  type="number"
                                                  disabled={
                                                    !(
                                                      val.quantityType === Dozen
                                                    )
                                                  }
                                                  value={
                                                    val.quantityType === Dozen
                                                      ? batchVal.stockCount
                                                      : "0"
                                                  }
                                                  className="input stepperInput__input form-control"
                                                  onChange={(event: any) => {
                                                    const currentStockCount =
                                                      parseInt(
                                                        event.target.value
                                                      );
                                                    handleQuantityChange(
                                                      currentStockCount,
                                                      index,
                                                      batchIndex
                                                    );
                                                  }}
                                                  onKeyPress={(event: any) => {
                                                    handleOnKeyPress(event);
                                                  }}
                                                />
                                                <Button
                                                  size="sm"
                                                  className="button button--addOnRight"
                                                  disabled={
                                                    !(
                                                      val.quantityType === Dozen
                                                    )
                                                  }
                                                  onClick={() => {
                                                    const currentStockCount =
                                                      parseInt(
                                                        batchVal.stockCount !==
                                                          undefined
                                                          ? batchVal.stockCount
                                                          : 0
                                                      );
                                                    if (
                                                      currentStockCount >= 0 &&
                                                      currentStockCount + 1 <=
                                                        batchVal.maxStockCount
                                                    ) {
                                                      handleQuantityChange(
                                                        currentStockCount + 1,
                                                        index,
                                                        batchIndex
                                                      );
                                                    }
                                                  }}
                                                >
                                                  +
                                                </Button>
                                              </div>
                                            </td>
                                            <td className="text-center">
                                              {/* <Form.Control
                                                className="form-control-custom fs-14 fw-600 w-88px min-h-45px px-3 text-center"
                                                disabled={
                                                  !(val.quantityType === Piece)
                                                }
                                                onChange={(event: any) => {
                                                  handleQuantityChange(
                                                    event,
                                                    index,
                                                    batchIndex
                                                  );
                                                }}
                                                value={
                                                  val.quantityType === Piece
                                                    ? batchVal.stockCount
                                                    : '0'
                                                }
                                                onKeyPress={(event: any) => {
                                                  handleOnKeyPress(event);
                                                }}
                                                type="text"
                                              /> */}
                                              <div className="stepperInput">
                                                <Button
                                                  size="sm"
                                                  className="button button--addOnLeft"
                                                  disabled={
                                                    !(
                                                      val.quantityType === Piece
                                                    )
                                                  }
                                                  onClick={(event) => {
                                                    const currentStockCount =
                                                      parseInt(
                                                        batchVal.stockCount !==
                                                          undefined
                                                          ? batchVal.stockCount
                                                          : 0
                                                      );
                                                    if (
                                                      currentStockCount > 0 &&
                                                      currentStockCount - 1 !==
                                                        0
                                                    ) {
                                                      handleQuantityChange(
                                                        currentStockCount - 1,
                                                        index,
                                                        batchIndex
                                                      );
                                                    }
                                                  }}
                                                >
                                                  -
                                                </Button>
                                                <input
                                                  type="number"
                                                  disabled={
                                                    !(
                                                      val.quantityType === Piece
                                                    )
                                                  }
                                                  value={
                                                    val.quantityType === Piece
                                                      ? batchVal.stockCount
                                                      : "0"
                                                  }
                                                  className="input stepperInput__input form-control"
                                                  onChange={(event: any) => {
                                                    const currentStockCount =
                                                      parseInt(
                                                        event.target.value
                                                      );
                                                    handleQuantityChange(
                                                      currentStockCount,
                                                      index,
                                                      batchIndex
                                                    );
                                                  }}
                                                  onKeyPress={(event: any) => {
                                                    handleOnKeyPress(event);
                                                  }}
                                                />
                                                <Button
                                                  size="sm"
                                                  className="button button--addOnRight"
                                                  disabled={
                                                    !(
                                                      val.quantityType === Piece
                                                    )
                                                  }
                                                  onClick={() => {
                                                    const currentStockCount =
                                                      parseInt(
                                                        batchVal.stockCount !==
                                                          undefined
                                                          ? batchVal.stockCount
                                                          : 0
                                                      );
                                                    if (
                                                      currentStockCount >= 0 &&
                                                      currentStockCount + 1 <=
                                                        batchVal.maxStockCount
                                                    ) {
                                                      handleQuantityChange(
                                                        currentStockCount + 1,
                                                        index,
                                                        batchIndex
                                                      );
                                                    }
                                                  }}
                                                >
                                                  +
                                                </Button>
                                              </div>
                                            </td>
                                            {/* {batchVal.batch >= in
                                              itialDetails.batch ? ( */}
                                            <td className="text-end">
                                              <div className="d-flex justify-content-start">
                                                {val.batch.filter(
                                                  (val: any) =>
                                                    val.batch !== batchVal.batch
                                                ).length ? (
                                                  <>
                                                    <Button
                                                      variant=""
                                                      className="text-primary"
                                                      onClick={() => {
                                                        handleAddMore(index);
                                                      }}
                                                    >
                                                      <img
                                                        className="w-17px h-17px"
                                                        src={AddPlus}
                                                        alt=""
                                                      />
                                                    </Button>
                                                  </>
                                                ) : (
                                                  <Button variant=""></Button>
                                                )}
                                                <Button
                                                  variant="link"
                                                  className="btn-flush"
                                                  onClick={() => {
                                                    handleRemove(
                                                      index,
                                                      batchIndex
                                                    );
                                                  }}
                                                >
                                                  <img
                                                    className="w-17px h-17px"
                                                    src={GrayClose}
                                                    alt=""
                                                  />
                                                </Button>
                                              </div>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td colSpan={5}>
                                              {batchVal.stockCount >= 0 &&
                                              batchVal.stockCount <=
                                                batchVal.maxStockCount ? (
                                                ""
                                              ) : (
                                                <span className="fs-14 fw-500 text-danger">
                                                  Max allowed quantity in Batch-
                                                  {batchVal.batch} is{" "}
                                                  {batchVal.maxStockCount}
                                                </span>
                                              )}
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    }
                                  )}
                                  <>
                                    {batchValidation[index].quantityGreater ? (
                                      <tr>
                                        <td colSpan={5}>
                                          <span className="fs-14 fw-500 text-danger">
                                            Quantity must be equal to returned
                                            quantity
                                          </span>
                                        </td>
                                      </tr>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                </>
                              ) : (
                                <>
                                  <tr>
                                    <td>
                                      <div className="w-300px ">
                                        <CustomSelect
                                          // defaultValue={se.find((val: any) => {
                                          //   return val.value === generalData.brand
                                          // })}
                                          border={
                                            batchValidation[index].selection
                                              ? "#e55451"
                                              : ""
                                          }
                                          onChange={(event: any) => {
                                            handleSelect(event, index);
                                          }}
                                          options={
                                            val.batch.length
                                              ? val.batch.map((catval: any) => {
                                                  return {
                                                    value: catval.batch,
                                                    label: `Batch ${
                                                      catval.batch
                                                    } - ${Method.convertDateToDDMMYYYY(
                                                      catval.expiry
                                                    )}`,
                                                    batch: catval.batch,
                                                    expiry: catval.expiry,
                                                    quantityType:
                                                      catval.quantityType,
                                                    stockCount:
                                                      catval.stockCount,
                                                    title: `Batch ${
                                                      catval.batch
                                                    } - ${Method.convertDateToDDMMYYYY(
                                                      catval.expiry
                                                    )}`,
                                                  };
                                                })
                                              : []
                                          }
                                        />
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    {/* {batchVal.batch >= initialDetails.batch ? ( */}
                                    <td></td>
                                    {/* ) : (
                                <td className="text-end"></td>
                              )} */}
                                  </tr>
                                </>
                              )}
                              <tr>
                                <td colSpan={5}>
                                  <div className="separator my-5"></div>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
        {!fetchLoader ? (
          <Col className="mt-4">
            <Button
              className="btn-lg"
              onClick={() => {
                handleSubmit();
              }}
            >
              {!loading && <span className="indicator-label"> Submit</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </Button>
          </Col>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default AdjustReturnOrder;
