import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Nav,
  Row,
  Tab,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import crossIcon from "../../../_admin/assets/media/svg_uTrade/cross_gray.png";
import GreenCheck from "../../../_admin/assets/media/svg_uTrade/green_check.svg";
import CrossIcon from "../../../_admin/assets/media/svg_uTrade/cross_gray.svg";
import UnCheck from "../../../_admin/assets/media/svg_uTrade/unchecked.svg";
import { KTSVG } from "../../../_admin/helpers";
import { CustomSelect } from "../../custom/Select/CustomSelect";
import AddNewAddress from "../../modals/add-new-address";
import SelectProduct from "../../modals/select-offfer";
import APICallService from "../../../api/apiCallService";
import {
  buyer,
  customerService,
  master,
  ordersDelivery,
  platformFees,
  seller,
} from "../../../api/apiEndPoints";
import {
  Add,
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  GeneralTax,
  Manager,
  OrderCash,
  OrderQueueFailed,
  OrderQueueProcessed,
  OrdersDelivery,
  PAGE_LIMIT,
  Piece,
  ServiceMember,
} from "../../../utils/constants";
import Loader from "../../../Global/loader";
import clsx from "clsx";
import { orderDeliveryJSON } from "../../../api/apiJSON/order";
import { error, success } from "../../../Global/toast";
import { useAuth } from "../auth";
import { customerServiceJSON } from "../../../api/apiJSON/customerService";
import Method from "../../../utils/methods";
import InstantOrderBatchInfo from "../../modals/instant-order-batch-info";
import { getKey } from "../../../Global/history";
import { warehouse as warehouseStoreString } from "../../../utils/storeString";
const AddNewOrderSubAdmin = (props: any) => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const { currentUser } = useAuth();
  const [active, setActive] = useState<any>(0);
  const [district, setDistrict] = useState<string>(
    currentUser &&
      currentUser.districts &&
      currentUser.districts[0] &&
      currentUser.districts.find((val: any) => val.flag === true).reference
  );
  const [selectedCustomer, setSelectedCustomer] = useState<any>("");
  const [discount, setDiscount] = useState<boolean>(false);
  const [discountValue, setDiscountValue] = useState<any>([]);
  const [fetchLoader, setFetchLoader] = useState(false);
  const [fetchCustomerLoader, setFetchCustomerLoader] = useState(false);
  const [fetchCategoryLoader, setFetchCategoryLoader] = useState(false);
  const [productLoader, setProductLoader] = useState(false);
  const [customerData, setCustomerData] = useState<any>([]);
  const [totalCustomer, setTotalCustomer] = useState<any>(0);
  const [categoryData, setCategoryData] = useState<any>([]);
  const [productData, setProductData] = useState<any>([]);
  const [subCategoryData, setSubCategoryData] = useState<any>([]);
  const [groupCategoryData, setGroupCategoryData] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<any>([]);
  const [selectedGroupCategory, setSelectedGroupCategory] = useState<any>([]);
  const [totalCategory, setTotalCategory] = useState<any>(0);
  const [addressModal, setAddressModal] = useState(false);
  const [selectOfferModal, setSelectOfferModal] = useState(false);
  const [variantId, setVariantId] = useState("");
  const [selectedVariant, setSelectedVariant] = useState<any>([]);
  const [selectedCustomerInfo, setSelectedCustomerInfo] = useState<any>([]);
  const [selectedAddress, setSelectedAddress] = useState<string>("");
  const [selectedProduct, setSelectedProduct] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [initData, setInitData] = useState<any>();
  const [orderValue, setOrderValue] = useState<any>();
  const [discountValidation, setDiscountValidation] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [team, setTeam] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [instantOrder, setInstantOrder] = useState(false);
  const [batchInfo, setBatchInfo] = useState(false);
  const [batchDetails, setBatchDetails] = useState<any>();
  const [paymentMode, setPaymentMode] = useState(OrderCash);
  const [cost, setCost] = useState<any>();
  const [orderId, setOrderId] = useState<any>();
  const [isOrderPlaced, setIsOrderPlaced] = useState<any>(false);
  const [validations, setValidations] = useState<{
    [key: string]: {
      isInvalid: boolean;
      remainingQty: number;
    };
  }>({});
  const [validation, setValidation] = useState<{
    customer: boolean;
    product: boolean;
    address: boolean;
  }>({
    customer: false,
    product: false,
    address: false,
  });
  const fetchOtherFees = async () => {
    let apiService = new APICallService(
      platformFees.listOtherFees,
      "",
      "",
      "",
      "",
      "",
      OrdersDelivery,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    if (response) {
      setCost(response);
    }
  };
  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      if (!Method.hasPermission(OrdersDelivery, Add, currentUser)) {
        return window.history.back();
      }
      await fetchInit();
      await fetchOtherFees();
      setFetchLoader(false);
      setFetchCustomerLoader(true);
      await fetchCustomerData(page, PAGE_LIMIT);
      setFetchCustomerLoader(false);
      setFetchCategoryLoader(true);
      await fetchCategory(categoryPage, PAGE_LIMIT);
      setFetchCategoryLoader(false);
    })();
  }, []);
  const fetchCustomerData = async (pageNo: number, limit: number) => {
    // let params = {
    //   pageNo: pageNo,
    //   limit: limit,
    //   sortKey: 'name',
    //   sortOrder: 1,
    //   needCount: pageNo === 1,
    // };
    if (currentUser?.role.some((obj: any) => obj.type === Manager)) {
      let params: any = {
        sortKey: "name",
        sortOrder: 1,
        district: district,
      };
      params = { ...params, ["addedBy[" + 0 + "]"]: currentUser?._id };
      if (tempWarehouse?._id) {
        params.warehouse = tempWarehouse._id;
      }
      let apiService = new APICallService(
        customerService.myTeam,
        customerServiceJSON.listTeam(params),
        {
          districtId: district,
          id:
            props && props.userId
              ? props.userId
              : state && state.userId
              ? state.userId
              : currentUser && currentUser._id,
        },
        "",
        "",
        "",
        OrdersDelivery,
        tempWarehouse?._id
      );
      let response = await apiService.callAPI();
      let temp: any = [];
      if (response.records.length) {
        if (response.records.length) {
          response.records.map((val: any, index: number) => {
            params = { ...params, ["addedBy[" + (index + 1) + "]"]: val._id };
          });
        }
        if (tempWarehouse?._id) {
          params.warehouse = tempWarehouse._id;
        }
        let apiService = new APICallService(
          buyer.listOnBoardedBuyers,
          params,
          "",
          "",
          "",
          "",
          OrdersDelivery,
          tempWarehouse?._id
        );
        let buyerResponse = await apiService.callAPI();
        let data: any = [...customerData];
        if (page === 1) {
          setTotalCustomer(buyerResponse.total);
        } else {
          let prevTotal = totalCustomer;
          setTotalCustomer(prevTotal);
        }
        buyerResponse.records.map((val: any) => {
          data.push(val);
        });
        setCustomerData(data);
      } else {
        if (tempWarehouse?._id) {
          params.warehouse = tempWarehouse._id;
        }
        let apiService = new APICallService(
          buyer.listOnBoardedBuyers,
          params,
          "",
          "",
          "",
          "",
          OrdersDelivery,
          tempWarehouse?._id
        );
        let buyerResponse = await apiService.callAPI();
        let data: any = [...customerData];
        if (page === 1) {
          setTotalCustomer(buyerResponse.total);
        } else {
          let prevTotal = totalCustomer;
          setTotalCustomer(prevTotal);
        }
        buyerResponse.records.map((val: any) => {
          data.push(val);
        });
        setCustomerData(data);
      }
      setTeam(temp);
    } else {
      let params: any = {
        sortKey: "name",
        sortOrder: 1,
        district: district,
      };
      params = { ...params, ["addedBy[" + 0 + "]"]: currentUser?._id };
      if (tempWarehouse?._id) {
        params.warehouse = tempWarehouse._id;
      }
      let apiService = new APICallService(
        buyer.listOnBoardedBuyers,
        params,
        "",
        "",
        "",
        "",
        OrdersDelivery,
        tempWarehouse?._id
      );
      let buyerResponse = await apiService.callAPI();
      let data: any = [...customerData];
      if (page === 1) {
        setTotalCustomer(buyerResponse.total);
      } else {
        let prevTotal = totalCustomer;
        setTotalCustomer(prevTotal);
      }
      buyerResponse.records.map((val: any) => {
        data.push(val);
      });
      setCustomerData(data);
    }
  };
  const fetchInit = async () => {
    let apiService = new APICallService(
      ordersDelivery.init,
      "",
      "",
      "",
      "",
      "",
      OrdersDelivery,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    setInitData(response);
  };
  const fetchCategory = async (page: number, limit: number) => {
    let params: any = {
      pageNo: page,
      limit: limit,
      sortKey: "title",
      sortOrder: 1,
      needCount: page === 1,
      state: 1,
    };
    if (tempWarehouse?._id) {
      params.warehouse = tempWarehouse._id;
    }
    let apiService = new APICallService(
      master.categoryList,
      params,
      "",
      "",
      "",
      "",
      OrdersDelivery,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    let data: any = [...categoryData];
    response.records.map((val: any) => {
      data.push(val);
    });
    if (page === 1) {
      setTotalCategory(response.total);
    } else {
      let prevTotal = totalCategory;
      setTotalCategory(prevTotal);
    }
    setCategoryData(data);
  };
  const onMenuCategoryScrollToBottom = async () => {
    if (!(categoryData && categoryData.length === totalCategory)) {
      let tempPage = categoryPage;
      tempPage = tempPage + 1;
      setCategoryPage(tempPage);
      await fetchCategory(tempPage, PAGE_LIMIT);
    }
  };
  const onMenuScrollToBottom = async () => {
    setFetchCustomerLoader(true);
    if (!(customerData && customerData.length === totalCustomer)) {
      let tempPage = page;
      tempPage = tempPage + 1;
      setPage(tempPage);
      await fetchCustomerData(tempPage, PAGE_LIMIT);
    }
    setFetchCustomerLoader(false);
  };
  const handleActive = (event: any) => {
    if (event == 0) {
      fetchProduct(selectedSubCategory, 2, searchTerm);
    } else {
      fetchProduct(event, 3, searchTerm);
    }
    setActive(event);
  };
  const handlePrimaryCategoryChange = (event: any) => {
    setSelectedCategory(event.value);
    setActive(0);
    fetchProduct(event.value, 1, searchTerm);
    if (event.categories.length) {
      setSubCategoryData(event.categories);
      setSelectedSubCategory("");
      setGroupCategoryData([]);
      setSelectedGroupCategory("");
    } else {
      setSubCategoryData([]);
      setSelectedSubCategory("");
      setGroupCategoryData([]);
      setSelectedGroupCategory("");
    }
  };
  const handleSubCategoryChange = (event: any) => {
    setSelectedSubCategory(event.value);
    setActive(0);
    fetchProduct(event.value, 2, searchTerm);
    if (event.categories.length) {
      setGroupCategoryData(event.categories);
    } else {
      setGroupCategoryData([]);
      setSelectedGroupCategory("");
    }
  };
  const handleCustomerChange = async (event: any) => {
    setSelectedCustomerInfo(null);
    setSelectedAddress("");
    setValidation({ ...validation, customer: false });
    await fetchCustomerInfo(event.value);
    setSelectedCustomer(event.value);
  };
  const fetchCustomerInfo = async (id: string) => {
    let apiService = new APICallService(
      buyer.details,
      id,
      "",
      "",
      "",
      "",
      OrdersDelivery,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    setSelectedCustomerInfo(response);
  };
  const fetchProduct = async (
    category: string,
    depth: number,
    search?: string
  ) => {
    setProductLoader(true);
    let params: any = {
      category: category,
      categoryDepth: depth,
      searchTerm: search ? search : "",
    };
    if (tempWarehouse?._id) {
      params.warehouse = tempWarehouse._id;
    }
    let apiService = new APICallService(
      seller.listSellableProduct,
      params,
      "",
      "",
      "",
      "",
      OrdersDelivery,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    // let data: any = [...categoryData];
    // response.records.map((val: any) => {
    //   data.push(val);
    // });
    // if (page === 1) {
    //   setTotalCategory(response.total);
    // } else {
    //   let prevTotal = totalCategory;
    //   setTotalCategory(prevTotal);
    // }
    setProductData(response.records);
    setProductLoader(false);
  };
  const handleAddProduct = async (product: any) => {
    let temp: any = [...selectedProduct];
    // product.map((val: any) => {
    //   temp.push(val);
    // });
    for (const obj1 of product) {
      // Check if there is a matching object in array2 based on _id
      const matchingObj2 = temp.find((obj2: any) => obj2._id === obj1._id);
      if (matchingObj2) {
        // Find the corresponding quantityType in obj2 based on the type property
        const quantityType1 = obj1.quantityTypes.find(
          (qt1: any) => qt1.type === matchingObj2.quantityTypes[0].type
        );
        if (quantityType1) {
          // Update stockCount in array2
          const quantityType2 = matchingObj2.quantityTypes.find(
            (qt2: any) => qt2.type === quantityType1.type
          );
          const quantityType2Index = matchingObj2.quantityTypes.findIndex(
            (qt2: any) => qt2.type === quantityType1.type
          );
          if (quantityType2) {
            quantityType2.stockCount = parseInt(quantityType1.stockCount);
            // const newValidation: any = {
            //   ...validation,
            //   [quantityType2Index]: {
            //     isInvalid:
            //       quantityType2.stockCount >
            //       validations[quantityType2Index].remainingQty,
            //     remainingQty: validations[quantityType2Index].remainingQty,
            //   },
            // };
            // setValidations(newValidation);
          }
        } else {
          const matchingObj2 = temp.findIndex(
            (obj2: any) => obj2._id === obj1._id
          );
          if (matchingObj2 > -1) {
            // const newValidation: any = {
            //   ...validation,
            //   [matchingObj2]: {
            //     isInvalid: false,
            //     remainingQty: 0,
            //   },
            // };
            // setValidations(newValidation);
            temp[matchingObj2] = obj1;
          }
        }
      } else {
        temp.push(obj1);
      }
    }
    setValidation({ ...validation, product: false });
    setSelectedProduct(temp);
    setSelectOfferModal(false);
  };
  const handleRemove = (index: number) => {
    let temp: any = [...selectedProduct];
    temp.splice(index, 1);
    if (!temp.length) {
      setValidation({ ...validation, product: true });
    }
    setSelectedProduct(temp);
  };
  const handleQuantityChange = (
    value: any,
    index: number,
    quantIndex: number,
    type: number
  ) => {
    const item = selectedProduct[index].inventoryInfo?.quantityTypes.find(
      (item: any) => item.type === type
    );
    const remainingQty = item?.remainingQty || 0;
    const newValidation: any = {
      ...validations,
      [index]: {
        isInvalid: value > remainingQty,
        remainingQty: remainingQty,
      },
    };
    setValidations(newValidation);
    const temp = [...selectedProduct];
    temp[index].quantityTypes[quantIndex]["stockCount"] = value;
    if (value == 0) {
      temp.splice(index, 1);
    }
    setSelectedProduct(temp);
  };
  const handleOnKeyPress = (event: any) => {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      event.preventDefault();
      return false;
    }
    return true;
  };
  const handleSearch = (value: string) => {
    if (value) {
      if (
        selectedCategory.length &&
        selectedSubCategory.length &&
        selectedGroupCategory.length
      ) {
        fetchProduct(selectedGroupCategory, 3, value);
      } else if (selectedCategory.length && selectedSubCategory.length) {
        fetchProduct(selectedSubCategory, 2, value);
      } else if (selectedCategory.length) {
        fetchProduct(selectedCategory, 1, value);
      }
    }
    setSearchTerm(value);
  };
  useEffect(() => {
    if (selectedProduct.length) {
      let commissioned = calculateCommission(selectedProduct);
      const amount = calculateTotalAmount(commissioned);
      let temp = {
        totalAmount: amount.totalAmount,
        totalAmountWithTax: amount.totalAmountTax,
        totalCommission: amount.commission,
      };
      setOrderValue(temp);
    } else {
      setOrderValue([]);
    }
  }, [selectedProduct]);
  const calculateCommission = (variantData: any) => {
    let commission = 0;
    variantData.forEach((mVariantObj: any) => {
      const category = mVariantObj.category;
      const subCategory = mVariantObj.subCategory;
      const groupCategory = mVariantObj.groupCategory;
      if (
        groupCategory &&
        category.commission === subCategory.commission &&
        subCategory.commission === groupCategory.commission
      ) {
        commission = category.commission;
      } else if (groupCategory) {
        commission = groupCategory.commission;
      } else if (category.commission === subCategory.commission) {
        commission = category.commission;
      } else {
        commission = subCategory.commission;
      }
      mVariantObj.commission = commission;
      return mVariantObj;
    });
    return variantData;
  };
  const calculateTotalAmount = (variantsData: any) => {
    let totalAmount = 0;
    let totalAmountTax = 0;
    let totalCommission = 0;
    variantsData.forEach((variant: any) => {
      if (!variant.taxFree && variant.productTax) {
        variant.quantityTypes.forEach((quantityType: any) => {
          const stockCount = quantityType.stockCount;
          const amount = quantityType.amount;
          const discountAmt = quantityType.discountAmt;
          const discountAmtEnabled = quantityType.discountAmtEnabled;
          const discountsByQuantities = quantityType.discountsByQuantities;
          let discountedPrice =
            stockCount *
            getVariantUnitAndTotalPriceByQuantityType(
              quantityType,
              quantityType.stockCount
            ).unitPrice;
          let discountedTaxPrice =
            stockCount *
            (getVariantUnitAndTotalPriceByQuantityType(
              quantityType,
              quantityType.stockCount
            ).unitPrice /
              (1 + variant.productTax / 100));
          totalAmount += discountedPrice;
          totalAmountTax += discountedTaxPrice;
          totalCommission += (discountedPrice * variant.commission) / 100;
        });
      } else {
        variant.quantityTypes.forEach((quantityType: any) => {
          const stockCount = quantityType.stockCount;
          const amount = quantityType.amount;
          const discountAmt = quantityType.discountAmt;
          const discountAmtEnabled = quantityType.discountAmtEnabled;
          const discountsByQuantities = quantityType.discountsByQuantities;
          let discountedPrice =
            stockCount *
            getVariantUnitAndTotalPriceByQuantityType(
              quantityType,
              quantityType.stockCount
            ).unitPrice;
          let discountedTaxPrice =
            stockCount *
            getVariantUnitAndTotalPriceByQuantityType(
              quantityType,
              quantityType.stockCount
            ).unitPrice;
          totalAmount += discountedPrice;
          totalAmountTax += discountedTaxPrice;
          totalCommission += (discountedPrice * variant.commission) / 100;
        });
      }
    });
    return {
      totalAmount: totalAmount,
      totalAmountTax: totalAmountTax,
      commission: totalCommission,
    };
  };
  const handleSaveAddress = (address: any) => {
    let temp = { ...selectedCustomerInfo };
    temp.user.deliveryAddresses.push(address);
    setSelectedCustomerInfo(temp);
    setAddressModal(false);
  };
  const handleDiscount = (value: string) => {
    let temp: any = [];
    if (parseInt(value)) {
      if (parseInt(value) > parseInt(orderValue.totalAmount)) {
        setDiscountValidation(true);
      } else {
        setDiscountValidation(false);
      }
      temp = [{ type: 1, value: value }];
    } else {
      temp = [];
    }
    setDiscountValue(temp);
  };
  const handlePlaceOrder = async () => {
    let validateTemp = { ...validation };
    if (!selectedCustomer && !selectedVariant && !selectedAddress) {
      validateTemp.customer = true;
      validateTemp.address = true;
      validateTemp.product = true;
    } else if (!selectedCustomer) {
      validateTemp.customer = true;
    } else if (!selectedProduct.length) {
      validateTemp.product = true;
    } else if (!selectedAddress) {
      validateTemp.address = true;
    } else {
      if (discountValue.length) {
        if (
          parseInt(discountValue[0].value) > parseInt(orderValue.totalAmount)
        ) {
          error("Discount amount should be less than original total");
        } else {
          if (orderValue.totalAmount >= cost.minimumOrder) {
            setLoading(true);
            let apiService = new APICallService(
              ordersDelivery.add,
              orderDeliveryJSON.addNewOrder({
                buyer: selectedCustomer,
                variants: selectedProduct,
                address: selectedAddress,
                discounts: discountValue,
                instantOrder: instantOrder,
                paymentMode: paymentMode,
              }),
              "",
              "",
              "",
              OrdersDelivery,
              tempWarehouse?._id
            );
            let response = await apiService.callAPI();
            if (response) {
              setOrderId(response.queueId);
              // if (instantOrder) {
              //   setBatchDetails(response.record);
              //   setBatchInfo(true);
              // } else {
              //   navigate('/orders');
              // }
              setIsOrderPlaced(true);
            } else {
              setLoading(false);
            }
          } else {
            error("Minimum order is " + cost.minimumOrder);
          }
        }
      } else {
        if (orderValue.totalAmount >= cost.minimumOrder) {
          setLoading(true);
          let apiService = new APICallService(
            ordersDelivery.add,
            orderDeliveryJSON.addNewOrder({
              buyer: selectedCustomer,
              variants: selectedProduct,
              address: selectedAddress,
              discounts: discountValue,
              instantOrder: instantOrder,
              paymentMode: paymentMode,
            }),
            "",
            "",
            "",
            "",
            OrdersDelivery,
            tempWarehouse?._id
          );
          let response = await apiService.callAPI();
          if (response) {
            setOrderId(response.queueId);
            // if (instantOrder) {
            //   setBatchDetails(response.record);
            //   setBatchInfo(true);
            // } else {
            //   navigate('/orders');
            // }
            setIsOrderPlaced(true);
          } else {
            setLoading(false);
          }
        } else {
          error("Minimum order is " + cost.minimumOrder);
        }
      }
    }
    setValidation(validateTemp);
    setLoading(false);
    // setLoading(true);
    // for (let i = 0; i < 10; i++) {
    //   let apiService = new APICallService(
    //     ordersDelivery.add,
    //     // orderDeliveryJSON.addNewOrder({
    //     //   buyer: '648b0910303198da5b09e0b6',
    //     //   variants: [
    //     //     {
    //     //       variant: '64747e2123838e789c690a12',
    //     //       quantityType: 1,
    //     //       stockCount: 1,
    //     //     },
    //     //     {
    //     //       variant: '64747e2123838e789c690a12',
    //     //       quantityType: 2,
    //     //       stockCount: 2,
    //     //     },
    //     //   ],
    //     //   address: '64959418fe358127f9eaa513',
    //     //   discounts: [
    //     //     {
    //     //       type: 1,
    //     //       value: 10,
    //     //     },
    //     //   ],
    //     //   record: '',
    //     // })
    //     {
    //       buyer: '648b0910303198da5b09e0b6',
    //       variants: [
    //         {
    //           variant: '648c4ba250349ab764f77f88',
    //           quantityType: 3,
    //           stockCount: 5,
    //         },
    //         {
    //           variant: '648c4ba250349ab764f77f89',
    //           quantityType: 3,
    //           stockCount: 5,
    //         },
    //         // {
    //         //   variant: '65057191523a991acbbdc25f',
    //         //   quantityType: 2,
    //         //   stockCount: 5,
    //         // },
    //         // {
    //         //   variant: '65057191523a991acbbdc24a',
    //         //   quantityType: 4,
    //         //   stockCount: 5,
    //         // },
    //         // {
    //         //   variant: '65057191523a991acbbdc24a',
    //         //   quantityType: 3,
    //         //   stockCount: 5,
    //         // },
    //         // {
    //         //   variant: '65057191523a991acbbdc250',
    //         //   quantityType: 2,
    //         //   stockCount: 5,
    //         // },
    //         // {
    //         //   variant: '65057191523a991acbbdc25c',
    //         //   quantityType: 1,
    //         //   stockCount: 5,
    //         // },
    //         // {
    //         //   variant: '65057191523a991acbbdc25c',
    //         //   quantityType: 2,
    //         //   stockCount: 5,
    //         // },
    //         // {
    //         //   variant: '65057191523a991acbbdc259',
    //         //   quantityType: 3,
    //         //   stockCount: 5,
    //         // },
    //         // {
    //         //   variant: '65057191523a991acbbdc24d',
    //         //   quantityType: 4,
    //         //   stockCount: 5,
    //         // },
    //         // {
    //         //   variant: '65057191523a991acbbdc24d',
    //         //   quantityType: 3,
    //         //   stockCount: 5,
    //         // },
    //         // {
    //         //   variant: '65057191523a991acbbdc256',
    //         //   quantityType: 3,
    //         //   stockCount: 5,
    //         // },
    //         // {
    //         //   variant: '65057191523a991acbbdc244',
    //         //   quantityType: 1,
    //         //   stockCount: 5,
    //         // },
    //         // {
    //         //   variant: '65057191523a991acbbdc247',
    //         //   quantityType: 1,
    //         //   stockCount: 5,
    //         // },
    //         // {
    //         //   variant: '65057191523a991acbbdc253',
    //         //   quantityType: 2,
    //         //   stockCount: 5,
    //         // },
    //       ],
    //       address: '650573bb523a991acbbdca86',
    //       discounts: [
    //         {
    //           type: 1,
    //           value: 10,
    //         },
    //       ],
    //       record: '',
    //     }
    //   );
    //   let response = await apiService.callAPI();
    // }
    // setLoading(false);
  };
  function calculateTotalCost(
    stockCount: any,
    amount: any,
    isTaxFree: any,
    productTax: any
  ) {
    const price = isTaxFree
      ? stockCount * amount
      : stockCount * amount * (1 + productTax / 100);
    return price;
  }
  function calculateTotalCostWithoutTax(stockCount: any, amount: any) {
    const price = stockCount * amount;
    return price;
  }
  function ProductPrice({ productVal }: any) {
    const { quantityTypes, taxFree, productTax } = productVal;
    const stockCount = quantityTypes[0].stockCount;
    const amount = quantityTypes[0].amount;
    let priceContent: any = "";
    if (quantityTypes[0].discountAmtEnabled) {
      const discountedPrice = calculateTotalCost(
        stockCount,
        quantityTypes[0].discountAmt,
        taxFree,
        productTax
      );
      priceContent = (
        <>
          TSh {Method.getGeneralizedAmount(discountedPrice)}{" "}
          <span className="text-decoration-line-through text-gray ms-1">
            TSh{" "}
            {Method.getGeneralizedAmount(
              calculateTotalCost(stockCount, amount, taxFree, productTax)
            )}
          </span>
        </>
      );
    } else if (quantityTypes[0].discountByQuantitiesEnabled) {
      const discounts = quantityTypes[0].discountsByQuantities;
      if (discounts.length > 0) {
        discounts.sort(
          (a: any, b: any) =>
            Math.abs(stockCount - (a.min + a.max) / 2) -
            Math.abs(stockCount - (b.min + b.max) / 2)
        );
        const discount = discounts[0];
        const discountedPrice = calculateTotalCost(
          stockCount,
          discount.discountAmt,
          taxFree,
          productTax
        );
        priceContent = (
          <>
            TSh {Method.getGeneralizedAmount(discountedPrice)}{" "}
            <span className="text-decoration-line-through text-gray ms-1">
              TSh{" "}
              {Method.getGeneralizedAmount(
                calculateTotalCost(stockCount, amount, taxFree, productTax)
              )}
            </span>
          </>
        );
      }
    } else {
      priceContent = (
        <span className="fs-16 fw-600">
          TSh{" "}
          {Method.getGeneralizedAmount(
            calculateTotalCost(stockCount, amount, taxFree, productTax)
          )}
        </span>
      );
    }
    return <span className="fs-16 fw-600">{priceContent}</span>;
  }
  function ProductPriceWithoutTax({ productVal }: any) {
    const { quantityTypes } = productVal;
    const stockCount = quantityTypes[0].stockCount;
    const priceData = getVariantUnitAndTotalPriceByQuantityType(
      quantityTypes[0],
      stockCount
    );
    const priceContent = (
      <>
        TSh{" "}
        {Method.getGeneralizedAmount(
          calculateTotalCostWithoutTax(stockCount, priceData.unitPrice)
        )}{" "}
        {priceData.unitPrice !== priceData.originalPrice ? (
          <span className="text-decoration-line-through text-gray ms-1">
            TSh{" "}
            {Method.getGeneralizedAmount(
              calculateTotalCostWithoutTax(stockCount, priceData.originalPrice)
            )}
          </span>
        ) : (
          <></>
        )}
      </>
    );
    return <span className="fs-16 fw-600">{priceContent}</span>;
  }
  function getVariantUnitAndTotalPriceByQuantityType(
    quantityTypeObj: any,
    quantity: any = 0
  ) {
    let unitPrice = quantityTypeObj["amount"] || 0;
    let originalPrice = quantityTypeObj["amount"] || 0;
    if (quantityTypeObj["discountAmtEnabled"]) {
      unitPrice = quantityTypeObj["discountAmt"] || 0;
    } else if (quantityTypeObj["discountByQuantitiesEnabled"]) {
      let availableDiscountRanges =
        quantityTypeObj["discountsByQuantities"] || [];
      if (availableDiscountRanges.length > 0) {
        availableDiscountRanges.sort((a: any, b: any) => a["min"] - b["min"]);
        if (quantity < availableDiscountRanges[0]["min"]) {
          //consider original price as it is
        } else {
          let { matchedRange, maxRange } = availableDiscountRanges.reduce(
            (p: any, c: any) => {
              if (quantity >= c["min"] && quantity <= c["max"]) {
                p.matchedRange = c;
              }
              if (quantity > c["min"]) {
                p.maxRange = c;
              }
              return p;
            },
            {
              matchedRange: undefined,
              maxRange: undefined,
            }
          );
          if (matchedRange) {
            unitPrice = matchedRange["discountAmt"] || 0;
          } else {
            unitPrice = maxRange["discountAmt"] || 0;
          }
        }
      } else {
        //consider original price as it is
      }
    }
    return {
      originalPrice,
      unitPrice,
      totalPrice: quantity * unitPrice,
    };
  }
  useEffect(() => {
    const checkOrderStatus = async () => {
      if (isOrderPlaced) {
        let apiService = new APICallService(
          ordersDelivery.checkStatus,
          orderId,
          "",
          "",
          "",
          "",
          OrdersDelivery,
          tempWarehouse?._id
        );
        let response = await apiService.callAPI();
        if (response) {
          if (response.orderQueueRecord.status === OrderQueueFailed) {
            setIsOrderPlaced(false);
            error(response.error);
            setLoading(false);
          } else if (response.orderQueueRecord.status === OrderQueueProcessed) {
            setIsOrderPlaced(false);
            success("Order placed successfully");
            if (instantOrder) {
              setBatchDetails(response.order);
              setBatchInfo(true);
            } else {
              navigate("/orders");
            }
            setLoading(false);
          } else {
            setTimeout(checkOrderStatus, 1000); // Call again after 1 second
          }
        } else {
          error(response.error);
          setIsOrderPlaced(false);
          setLoading(false);
        }
      }
    };
    if (isOrderPlaced == true) {
      checkOrderStatus();
    }
  }, [isOrderPlaced]);
  return (
    <>
      {addressModal ? (
        <AddNewAddress
          show={addressModal}
          onHide={() => setAddressModal(false)}
          onSaveAddress={handleSaveAddress}
          selectedCustomer={selectedCustomer}
          details={
            customerData && customerData.length
              ? customerData.find((item: any) => item._id === selectedCustomer)
              : {}
          }
          moduleName={OrdersDelivery}
        />
      ) : (
        <></>
      )}
      {selectOfferModal ? (
        <SelectProduct
          show={selectOfferModal}
          variantId={variantId}
          onHide={() => setSelectOfferModal(false)}
          selectedVariant={selectedVariant}
          onAddProduct={handleAddProduct}
          moduleName={OrdersDelivery}
          selectedProduct={selectedProduct}
        />
      ) : (
        <></>
      )}
      {batchDetails && batchInfo ? (
        <>
          <InstantOrderBatchInfo
            show={batchInfo}
            onClose={() => {
              navigate("/orders");
              setBatchInfo(!batchInfo);
            }}
            details={batchDetails}
          />
        </>
      ) : (
        <></>
      )}
      <div className="p-9">
        <div className="mb-7">
          <h1 className="fs-22 fw-bolder">Add new order</h1>
        </div>
        {fetchLoader ? (
          <>
            <div className="d-flex justify-content-center">
              <Loader loading={fetchLoader} />
            </div>
          </>
        ) : (
          <>
            <Card className="border bg-f9f9f9 mb-7">
              <Card.Body>
                <Row className="align-items-center">
                  <Col lg="6">
                    <Row className="align-items-center">
                      <Col>
                        <Form.Label className="fs-16 fw-500">
                          Customer name
                        </Form.Label>
                      </Col>
                      <Col className="min-w-lg-292px">
                        <CustomSelect
                          disabled={fetchCustomerLoader}
                          backgroundColor="#ffff"
                          border={validation.customer ? "#e55451" : ""}
                          isSearchable={true}
                          options={
                            customerData &&
                            customerData.map((val: any) => ({
                              label: (
                                <>
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-30px border me-3">
                                      <img
                                        src={val.business.image}
                                        className="object-fit-contain"
                                        alt=""
                                      />
                                    </div>
                                    <div className="ms-3">
                                      <span className="fs-16 fw-600 text-black mb-0">
                                        {val.business.name}
                                      </span>
                                      <div className="fs-14 fw-500 text-gray">
                                        {val.phoneCountry} {val.phone}
                                      </div>
                                    </div>
                                    {/* <span className="fs-16 fw-600 text-black mb-0">
                                    {val.business.name}
                                  </span> */}
                                  </div>
                                </>
                              ),
                              title:
                                val.business.name +
                                val.phoneCountry.replace("+", "") +
                                val.phone,
                              value: val._id,
                            }))
                          }
                          value={
                            customerData && customerData.length
                              ? customerData
                                  .filter(
                                    (item: any) => item._id === selectedCustomer
                                  )
                                  .map((val: any) => ({
                                    label: (
                                      <>
                                        <div className="symbol symbol-30px border me-3">
                                          <img
                                            src={val.business.image}
                                            className="object-fit-contain"
                                            alt=""
                                          />
                                        </div>
                                        <span className="fs-16 fw-600 text-black mb-0">
                                          {val.business.name}
                                        </span>
                                      </>
                                    ),
                                    title: val.business.name,
                                    value: val._id,
                                  }))
                              : null // Set to null when no match is found
                          }
                          onChange={(event: any) => {
                            handleCustomerChange(event);
                          }}
                          onMenuScrollToBottom={onMenuScrollToBottom}
                        />
                      </Col>
                    </Row>
                  </Col>
                  {selectedCustomerInfo &&
                  Object.keys(selectedCustomerInfo).length ? (
                    <Col lg="6">
                      <div className="d-flex flex-column">
                        <span className="fs-16 fw-500">
                          Business name:{" "}
                          <span className="fw-bold">
                            {selectedCustomerInfo.user.business.name}
                          </span>
                        </span>
                        <span className="fs-16 fw-500">
                          Business type:{" "}
                          <span className="fw-bold">
                            {
                              selectedCustomerInfo.user.business.types[0]
                                .reference.title
                            }
                          </span>
                        </span>
                      </div>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </Card.Body>
            </Card>
            <Card className="border bg-f9f9f9 py-3 custom-tabContainer">
              <Card.Header className="border-bottom-0 mb-3">
                <Card.Title className="fs-22 fw-bolder">
                  Select Products
                </Card.Title>
              </Card.Header>
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={active}
                // onSelect={handleSelect}
              >
                <Card.Body className="pt-0">
                  <Row className="g-5 gx-lg-10">
                    <Col lg={1} md={2} className="align-self-start">
                      <Form.Label className="fs-16 fw-600 mt-md-2">
                        Filter by <br className="br" />
                        categories
                      </Form.Label>
                    </Col>
                    <Col lg={11} md={10}>
                      <Row className="align-items-center g-5 gx-lg-10">
                        <Col lg={3} md={6}>
                          <CustomSelect
                            backgroundColor="#ffff"
                            disabled={fetchCategoryLoader}
                            options={
                              categoryData &&
                              categoryData
                                .filter(
                                  (item: any) => item._id !== selectedCategory
                                )
                                .map((val: any) => ({
                                  label: (
                                    <>
                                      <div className="symbol symbol-30px border me-3">
                                        <img
                                          src={val.image}
                                          className="object-fit-contain"
                                          alt=""
                                        />
                                      </div>
                                      <span className="fs-16 fw-600 text-black mb-0">
                                        {val.title}
                                      </span>
                                    </>
                                  ),
                                  title: val.title,
                                  value: val._id,
                                  categories: val.categories,
                                }))
                            }
                            onMenuScrollToBottom={onMenuCategoryScrollToBottom}
                            value={
                              selectedCategory && categoryData.length
                                ? categoryData
                                    .filter(
                                      (item: any) =>
                                        item._id === selectedCategory
                                    )
                                    .map((val: any) => {
                                      return {
                                        label: (
                                          <>
                                            <div className="symbol symbol-30px border me-3">
                                              <img
                                                src={val.image}
                                                className="object-fit-contain"
                                                alt=""
                                              />
                                            </div>
                                            <span className="fs-16 fw-600 text-black mb-0">
                                              {val.title}
                                            </span>
                                          </>
                                        ),
                                        title: val.title,
                                        value: val._id,
                                        categories: val.categories,
                                      };
                                    })
                                : null // Set to null when no match is found
                            }
                            onChange={(event: any) => {
                              handlePrimaryCategoryChange(event);
                            }}
                          />
                        </Col>
                        <Col lg={3} md={6}>
                          {" "}
                          {subCategoryData.length ? (
                            <CustomSelect
                              backgroundColor="#ffff"
                              options={
                                subCategoryData &&
                                subCategoryData
                                  .filter(
                                    (item: any) =>
                                      item._id !== selectedSubCategory
                                  )
                                  .map((val: any) => ({
                                    label: (
                                      <>
                                        <div className="symbol symbol-30px border me-3">
                                          <img
                                            src={val.image}
                                            className="object-fit-contain"
                                            alt=""
                                          />
                                        </div>
                                        <span className="fs-16 fw-600 text-black mb-0">
                                          {val.title}
                                        </span>
                                      </>
                                    ),
                                    value: val._id,
                                    categories: val.categories,
                                    title: val.title,
                                  }))
                              }
                              value={
                                selectedSubCategory && subCategoryData.length
                                  ? subCategoryData
                                      .filter(
                                        (item: any) =>
                                          item._id === selectedSubCategory
                                      )
                                      .map((val: any) => ({
                                        label: (
                                          <>
                                            <div className="symbol symbol-30px border me-3">
                                              <img
                                                src={val.image}
                                                className="object-fit-contain"
                                                alt=""
                                              />
                                            </div>
                                            <span className="fs-16 fw-600 text-black mb-0">
                                              {val.title}
                                            </span>
                                          </>
                                        ),
                                        value: val._id,
                                        categories: val.categories,
                                        title: val.title,
                                      }))
                                  : null // Set to null when no match is found
                              }
                              onChange={(event: any) => {
                                handleSubCategoryChange(event);
                              }}
                            />
                          ) : (
                            <></>
                          )}{" "}
                        </Col>
                        <Col lg={6}>
                          <div className="d-flex align-items-center position-relative">
                            <KTSVG
                              path="/media/icons/duotune/general/gen021.svg"
                              className="svg-icon-3 position-absolute ms-5"
                            />
                            <input
                              type="text"
                              id="kt_filter_search"
                              className="form-control form-control-custom bg-white ps-13"
                              placeholder="Search by product name…"
                              onChange={(event: any) => {
                                handleSearch(event.target.value.trim());
                              }}
                            />
                          </div>
                        </Col>
                        {groupCategoryData && groupCategoryData.length ? (
                          <Col xs={12}>
                            <div className="add-new-order">
                              <Nav variant="pills" className="gap-3">
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="0"
                                    onClick={() => {
                                      handleActive(0);
                                    }}
                                    active={active === 0}
                                  >
                                    {" "}
                                    All products{" "}
                                  </Nav.Link>
                                </Nav.Item>
                                <>
                                  {groupCategoryData.map(
                                    (val: any, index: number = 1) => {
                                      return (
                                        <>
                                          <Nav.Item>
                                            <Nav.Link
                                              eventKey={val._id}
                                              onClick={() => {
                                                handleActive(val._id);
                                              }}
                                              active={active === val._id}
                                            >
                                              {" "}
                                              {val.title}{" "}
                                            </Nav.Link>
                                          </Nav.Item>
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              </Nav>
                            </div>
                          </Col>
                        ) : (
                          <></>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Body className="p-0 pb-6">
                  <Row className="variant-categories">
                    <Tab.Content>
                      <Tab.Pane eventKey={active} active={true}>
                        <div className="table-responsive h-lg-315px h-275px overflow-y-auto border-top border-bottom">
                          <table className="table table-row-bordered align-middle gs-7 gy-4 mb-0">
                            <thead>
                              <tr className="fs-16 fw-bold text-dark h-70px align-middle">
                                <th className="min-w-150px">Product name</th>
                                <th className="min-w-150px">Unit price</th>
                                <th className="min-w-125px">Offers</th>
                                <th className="min-w-125px text-end"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {productLoader ? (
                                <>
                                  <tr>
                                    <td colSpan={4}>
                                      <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                                        <Loader loading={productLoader} />
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <>
                                  {productData && productData.length ? (
                                    <>
                                      {productData.map(
                                        (productVal: any, index: number) => {
                                          return (
                                            <tr>
                                              <td>
                                                <div className="d-flex align-items-center">
                                                  <div className="symbol symbol-50px border me-5">
                                                    <img
                                                      src={
                                                        productVal.media[0].url
                                                      }
                                                      className="object-fit-contain"
                                                      alt=""
                                                    />
                                                  </div>
                                                  <div className="fs-15 fw-600">
                                                    {productVal.title.replace(
                                                      /\s*\)\s*/g,
                                                      ")"
                                                    )}
                                                    <br />
                                                    <span className="fs-14 fw-500 text-gray">
                                                      {
                                                        productVal.product
                                                          .business.name
                                                      }
                                                    </span>
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                <span className="fs-15 fw-600">
                                                  {productVal.quantityTypes[0]
                                                    .discountAmtEnabled ? (
                                                    <>
                                                      {"TSh " +
                                                        Method.getGeneralizedAmount(
                                                          productVal
                                                            .quantityTypes[0]
                                                            .discountAmt
                                                        )}{" "}
                                                      <span className="text-decoration-line-through text-gray ms-1">
                                                        TSh{" "}
                                                        {Method.getGeneralizedAmount(
                                                          productVal
                                                            .quantityTypes[0]
                                                            .amount
                                                        )}
                                                      </span>
                                                    </>
                                                  ) : (
                                                    "TSh " +
                                                    Method.getGeneralizedAmount(
                                                      productVal
                                                        .quantityTypes[0].amount
                                                    )
                                                  )}
                                                </span>
                                              </td>
                                              <td>
                                                <span className="fs-15 fw-600">
                                                  {/* {productVal.quantityTypes.map((e:any) => {
  if ( e.discountByQuantitiesEnabled === true && e.discountsByQuantities.length > 0) {
  }
})} */}
                                                  {/* {productVal.quantityTypes.map().discountAmtEnabled?productVal.quantityTypes.discountAmtEnabled:"-"} */}
                                                  {/* {productVal.quantityTypes.find(
                                                    (e: any) =>
                                                      e.discountByQuantitiesEnabled ===
                                                        true &&
                                                      e.discountsByQuantities
                                                        .length > 0
                                                  )?.discountsByQuantities
                                                    .length
                                                    ? productVal.quantityTypes.find(
                                                        (e: any) =>
                                                          e.discountByQuantitiesEnabled ===
                                                            true &&
                                                          e
                                                            .discountsByQuantities
                                                            .length > 0
                                                      )?.discountsByQuantities
                                                        .length + '  offers'
                                                    : '-'}
                                                  <>
                                                    {(
                                                      productVal.quantityTypes.find(
                                                        (e: any) =>
                                                          e.discountByQuantitiesEnabled ===
                                                            true &&
                                                          e
                                                            .discountsByQuantities
                                                            .length > 0
                                                      )?.discountsByQuantities
                                                        .length
                                                    )}
                                                  </> */}
                                                  {productVal.quantityTypes.reduce(
                                                    (
                                                      acc: any,
                                                      product: any
                                                    ) => {
                                                      return (
                                                        acc +
                                                        (product.discountByQuantitiesEnabled
                                                          ? product
                                                              .discountsByQuantities
                                                              .length
                                                          : 0)
                                                      );
                                                    },
                                                    0
                                                  ) ? (
                                                    <div className="bg-light-primary w-fit-content p-1 px-4 mb-2 border-r5px">
                                                      <span className="text-primary fs-14 fw-500">
                                                        {productVal.quantityTypes.reduce(
                                                          (
                                                            acc: any,
                                                            product: any
                                                          ) => {
                                                            return (
                                                              acc +
                                                              (product.discountByQuantitiesEnabled
                                                                ? product
                                                                    .discountsByQuantities
                                                                    .length
                                                                : 0)
                                                            );
                                                          },
                                                          0
                                                        ) + " offers"}
                                                      </span>
                                                    </div>
                                                  ) : (
                                                    "-"
                                                  )}
                                                </span>
                                              </td>
                                              <td className="text-end">
                                                {productVal.inventoryInfo &&
                                                productVal.inventoryInfo
                                                  .quantityTypes.length ? (
                                                  <>
                                                    <Button
                                                      variant="primary"
                                                      className="fs-14 fw-600"
                                                      onClick={() => {
                                                        setSelectedVariant(
                                                          productVal.variantType
                                                        );
                                                        setVariantId(
                                                          productVal.product
                                                            .reference
                                                        );
                                                        setSelectOfferModal(
                                                          true
                                                        );
                                                      }}
                                                    >
                                                      Select
                                                    </Button>
                                                  </>
                                                ) : (
                                                  <>
                                                    <Button
                                                      variant="light"
                                                      className="fs-15 fw-600 text-danger"
                                                      disabled={true}
                                                    >
                                                      Out of Stock
                                                    </Button>
                                                  </>
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <tr>
                                        <td colSpan={4}>
                                          <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                                            No Data found
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Row>
                </Card.Body>
              </Tab.Container>
            </Card>
            {selectedProduct && selectedProduct.length ? (
              <>
                <Card
                  className={clsx(
                    "border bg-f9f9f9 mt-7",
                    validation.product ? "border-danger" : ""
                  )}
                >
                  <Card.Header className="border-bottom-0">
                    <Card.Title className="fs-22 fw-bolder">
                      Added products
                    </Card.Title>
                  </Card.Header>
                  <Card.Body className="pt-0">
                    {selectedProduct && selectedProduct.length ? (
                      <>
                        <div className="table-responsive">
                          <table className="table table-rounded table-row-bordered align-middle gs-9 gy-4 mb-0">
                            <thead>
                              <tr className="fs-16 fw-bold text-dark h-70px align-middle">
                                <th className="min-w-150px">Product name</th>
                                <th className="min-w-100px">Type</th>
                                <th className="min-w-125px">Unit price</th>
                                <td className="min-w-150px">Quantity</td>
                                <td className="min-w-50px">Tax (%)</td>
                                <th className="min-w-125px">Total amount</th>
                                {/* <th className="min-w-125px">Tax amount</th> */}
                                <th className="min-w-50px text-end"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedProduct.map(
                                (productVal: any, index: number) => {
                                  return (
                                    <tr>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <div className="symbol symbol-50px border me-5">
                                            <img
                                              src={productVal.media[0].url}
                                              className="object-fit-contain"
                                              alt=""
                                            />
                                          </div>
                                          <div className="fs-15 fw-600">
                                            {productVal.title.replace(
                                              /\s*\)\s*/g,
                                              ")"
                                            )}
                                            <br />
                                            <span className="fs-14 fw-500 text-gray">
                                              {productVal.business.name}
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <span className="fs-15 fw-600">
                                          {productVal.quantityTypes[0].type ===
                                            CartonWithDozens ||
                                          productVal.quantityTypes[0].type ===
                                            CartonWithPieces
                                            ? "Cartons"
                                            : ""}
                                          {productVal.quantityTypes[0].type ===
                                          Dozen
                                            ? "Dozens"
                                            : ""}
                                          {productVal.quantityTypes[0].type ===
                                          Piece
                                            ? "Pieces"
                                            : ""}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="fs-15 fw-600">
                                          {productVal.quantityTypes[0]
                                            .discountAmtEnabled ? (
                                            <>
                                              {"TSh " +
                                                Method.getGeneralizedAmount(
                                                  getVariantUnitAndTotalPriceByQuantityType(
                                                    productVal.quantityTypes[0],
                                                    productVal.quantityTypes[0]
                                                      .stockCount
                                                  ).unitPrice
                                                )}{" "}
                                              <span className="text-decoration-line-through text-gray ms-1">
                                                TSh{" "}
                                                {Method.getGeneralizedAmount(
                                                  getVariantUnitAndTotalPriceByQuantityType(
                                                    productVal.quantityTypes[0],
                                                    productVal.quantityTypes[0]
                                                      .stockCount
                                                  ).originalPrice
                                                )}
                                              </span>
                                            </>
                                          ) : productVal.quantityTypes[0]
                                              .discountByQuantitiesEnabled ? (
                                            <>
                                              {getVariantUnitAndTotalPriceByQuantityType(
                                                productVal.quantityTypes[0],
                                                productVal.quantityTypes[0]
                                                  .stockCount
                                              ).unitPrice !==
                                              getVariantUnitAndTotalPriceByQuantityType(
                                                productVal.quantityTypes[0],
                                                productVal.quantityTypes[0]
                                                  .stockCount
                                              ).originalPrice ? (
                                                <>
                                                  {"TSh " +
                                                    Method.getGeneralizedAmount(
                                                      getVariantUnitAndTotalPriceByQuantityType(
                                                        productVal
                                                          .quantityTypes[0],
                                                        productVal
                                                          .quantityTypes[0]
                                                          .stockCount
                                                      ).unitPrice
                                                    )}{" "}
                                                  <span className="text-decoration-line-through text-gray ms-1">
                                                    TSh{" "}
                                                    {Method.getGeneralizedAmount(
                                                      getVariantUnitAndTotalPriceByQuantityType(
                                                        productVal
                                                          .quantityTypes[0],
                                                        productVal
                                                          .quantityTypes[0]
                                                          .stockCount
                                                      ).originalPrice
                                                    )}
                                                  </span>
                                                </>
                                              ) : (
                                                "TSh " +
                                                Method.getGeneralizedAmount(
                                                  getVariantUnitAndTotalPriceByQuantityType(
                                                    productVal.quantityTypes[0],
                                                    productVal.quantityTypes[0]
                                                      .stockCount
                                                  ).unitPrice
                                                )
                                              )}
                                            </>
                                          ) : (
                                            "TSh " +
                                            Method.getGeneralizedAmount(
                                              getVariantUnitAndTotalPriceByQuantityType(
                                                productVal.quantityTypes[0],
                                                productVal.quantityTypes[0]
                                                  .stockCount
                                              ).unitPrice
                                            )
                                          )}
                                        </span>
                                      </td>
                                      <td>
                                        <div className="stepperInput">
                                          <Button
                                            size="sm"
                                            className="button button--addOnLeft"
                                            onClick={() => {
                                              const currentStockCount =
                                                parseInt(
                                                  productVal.quantityTypes[0]
                                                    .stockCount !== undefined
                                                    ? productVal
                                                        .quantityTypes[0]
                                                        .stockCount
                                                    : 0
                                                );
                                              if (currentStockCount > 0) {
                                                handleQuantityChange(
                                                  currentStockCount - 1,
                                                  index,
                                                  0,
                                                  productVal.quantityTypes[0]
                                                    .type
                                                );
                                              }
                                            }}
                                          >
                                            -
                                          </Button>
                                          <input
                                            type="number"
                                            value={
                                              productVal.quantityTypes[0]
                                                .stockCount !== undefined
                                                ? productVal.quantityTypes[0]
                                                    .stockCount
                                                : 0
                                            }
                                            className="input stepperInput__input form-control"
                                            onChange={(event: any) => {
                                              handleQuantityChange(
                                                event.target.value,
                                                index,
                                                0,
                                                productVal.quantityTypes[0].type
                                              );
                                            }}
                                            onKeyPress={(event: any) => {
                                              handleOnKeyPress(event);
                                            }}
                                          />
                                          <Button
                                            size="sm"
                                            className="button button--addOnRight"
                                            onClick={() => {
                                              handleQuantityChange(
                                                parseInt(
                                                  productVal.quantityTypes[0]
                                                    .stockCount !== undefined
                                                    ? productVal
                                                        .quantityTypes[0]
                                                        .stockCount
                                                    : 0
                                                ) + 1,
                                                index,
                                                0,
                                                productVal.quantityTypes[0].type
                                              );
                                            }}
                                            disabled={
                                              selectedProduct[
                                                index
                                              ].inventoryInfo?.quantityTypes.find(
                                                (sval: any) =>
                                                  sval.type ===
                                                  productVal.quantityTypes[0]
                                                    .type
                                              )?.remainingQty <=
                                              parseInt(
                                                productVal.quantityTypes[0]
                                                  .stockCount
                                              )
                                            }
                                          >
                                            +
                                          </Button>
                                        </div>
                                        {validations[index]?.isInvalid ? (
                                          <label
                                            htmlFor=""
                                            className="fs-14 fw-500 mb-3 text-danger"
                                          >
                                            You can add up to{" "}
                                            {validations[index].remainingQty}{" "}
                                            products
                                          </label>
                                        ) : (
                                          <></>
                                        )}
                                      </td>
                                      <td>
                                        <span className="fs-15 fw-600">
                                          {productVal.taxFree
                                            ? "0%"
                                            : `${productVal.productTax}%`}
                                        </span>
                                      </td>
                                      <td>
                                        {ProductPriceWithoutTax({
                                          productVal: productVal,
                                        })}
                                      </td>
                                      {/* <td>
                                        {ProductPrice({
                                          productVal: productVal,
                                        })}
                                      </td> */}
                                      <td className="text-end">
                                        <Button
                                          variant="link"
                                          className="btn-flush"
                                          onClick={() => {
                                            handleRemove(index);
                                          }}
                                        >
                                          <img
                                            width={22}
                                            height={22}
                                            src={crossIcon}
                                            alt=""
                                          />
                                        </Button>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        <div className="d-flex flex-center h-75px">
                          <span className="fs-18 fw-500">
                            Added products will be shown in this section.
                          </span>
                        </div>
                      </>
                    )}
                  </Card.Body>
                </Card>
              </>
            ) : (
              <></>
            )}
            {/* <Card className="border bg-f9f9f9 mt-7">
              <Card.Header className="border-bottom-0">
                <Card.Title className="fs-22 fw-bolder">
                  Delivery address
                </Card.Title>
              </Card.Header>
              <Card.Body className="pt-0">
                <Row className="align-items-center g-5">
                  <Col md={12}>
                    <div className="border bg-white border-r8px p-6">
                      <Row className="align-items-center">
                        <Col xs="auto">
                          <div className="symbol symbol-40px">
                            <img
                              src={GreenCheck}
                              alt=""
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex flex-column">
                            <span className="fs-18 fw-600">
                              Maisha Supermarket / +255 657 727 427
                            </span>
                            <span className="fs-16 fw-500">
                              Kibada St, Dar es Salaam, Tanzania
                            </span>
                          </div>
                        </Col>
                        <Col xs="auto">
                          <Button
                            variant="link"
                            className="btn-flush"
                          >
                            <img
                              width={27}
                              height={7}
                              src={threeDot}
                              alt=""
                            />
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="border bg-white border-r8px p-6">
                      <Row className="align-items-center">
                        <Col xs="auto">
                          <div className="symbol symbol-40px">
                            <img
                              src={UnCheck}
                              alt=""
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex flex-column">
                            <span className="fs-18 fw-600">
                              BN Mini Supermarket / +255 657 727 427
                            </span>
                            <span className="fs-16 fw-500">
                              Masondo St, Dar es Salaam, Tanzania
                            </span>
                          </div>
                        </Col>
                        <Col xs="auto">
                          <Button
                            variant="link"
                            className="btn-flush"
                          >
                            <img
                              width={27}
                              height={7}
                              src={threeDot}
                              alt=""
                            />
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col
                    md={12}
                    className="justify-content-start"
                  >
                    <Button
                      variant="link"
                      className="btn-flush text-primary fs-16 fw-bold"
                      onClick={() => setAddressModal(true)}
                    >
                      + Add new address
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card> */}
            <Row className="g-6 mt-1">
              {selectedCustomerInfo &&
              Object.keys(selectedCustomerInfo).length ? (
                <Col lg={orderValue && Object.keys(orderValue).length ? 8 : 12}>
                  <Card
                    className={clsx(
                      "card-stretch py-3 bg-light border border-r10px",
                      validation.address ? "border-danger" : ""
                    )}
                  >
                    <Card.Header className="border-0">
                      <Card.Title className="fs-22 fw-bolder">
                        Delivery address
                      </Card.Title>
                    </Card.Header>
                    <Card.Body className="pt-0">
                      <Row className="align-items-center g-5">
                        {selectedCustomerInfo.user.deliveryAddresses.length ? (
                          <>
                            {selectedCustomerInfo.user.deliveryAddresses.map(
                              (val: any) => {
                                return (
                                  <>
                                    <Col md={12}>
                                      <div className="border bg-white border-r8px p-6">
                                        <Row className="align-items-center">
                                          <Col xs="auto">
                                            <div className="symbol symbol-40px">
                                              <Button
                                                variant=""
                                                onClick={() => {
                                                  setValidation({
                                                    ...validation,
                                                    address: false,
                                                  });
                                                  setSelectedAddress(val._id);
                                                }}
                                              >
                                                <img
                                                  src={
                                                    selectedAddress === val._id
                                                      ? GreenCheck
                                                      : UnCheck
                                                  }
                                                  alt=""
                                                />
                                              </Button>
                                            </div>
                                          </Col>
                                          <Col>
                                            <div className="d-flex flex-column">
                                              <span className="fs-18 fw-600">
                                                {val.name} / {val.phoneCountry}{" "}
                                                {val.phone}
                                              </span>
                                              <span className="fs-16 fw-500">
                                                {val.addressLine1}
                                                {", "}
                                                {val.landmark
                                                  ? val.landmark
                                                  : ""}
                                                , {val.city}
                                                {", "}
                                                {val.districtName}
                                                {", "}
                                              </span>
                                            </div>
                                          </Col>
                                          {/* <Col xs="auto">
                                            <Button
                                              variant="link"
                                              className="btn-flush"
                                            >
                                              <img
                                                width={27}
                                                height={7}
                                                src={threeDot}
                                                alt=""
                                              />
                                            </Button>
                                          </Col> */}
                                        </Row>
                                      </div>
                                    </Col>
                                  </>
                                );
                              }
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                        <Col md={12} className="justify-content-start">
                          <Button
                            variant="link"
                            className="btn-flush text-primary fs-16 fw-bold"
                            onClick={() => setAddressModal(true)}
                          >
                            + Add new address
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              ) : (
                <></>
              )}
              {orderValue && Object.keys(orderValue).length ? (
                <Col lg={4} className="h-450px">
                  <Card className="card-stretch py-3 bg-light border border-r10px">
                    <Card.Header className="border-bottom-0">
                      <Card.Title>
                        <h5 className="fs-22 fw-bolder">Order totals</h5>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body className="pt-0 pb-5 border-bottom">
                      <Row className="g-4">
                        {selectedProduct
                          .map((i: any) => i.quantityTypes)
                          .flat()
                          .reduce((a: any, { type, stockCount }: any) => {
                            const existing = a.find(
                              (x: any) => x.type === type
                            );
                            if (existing) {
                              existing.stockCount =
                                parseInt(existing.stockCount) +
                                parseInt(stockCount);
                            } else {
                              a.push({
                                type,
                                stockCount: parseInt(stockCount),
                              });
                            }
                            return a;
                          }, [])
                          .find((item: any) => item.type === CartonWithDozens)
                          ?.stockCount ? (
                          <Col xs={12}>
                            <div className="d-flex justify-content-between align-items-center">
                              <span className="fs-16 fw-500">
                                Carton (In Dozens):
                              </span>
                              <span className="fs-16 fw-600">
                                {Method.getGeneralizedAmount(
                                  selectedProduct
                                    .map((i: any) => i.quantityTypes)
                                    .flat()
                                    .reduce(
                                      (a: any, { type, stockCount }: any) => {
                                        const existing = a.find(
                                          (x: any) => x.type === type
                                        );
                                        if (existing) {
                                          existing.stockCount =
                                            parseInt(existing.stockCount) +
                                            parseInt(stockCount);
                                        } else {
                                          a.push({
                                            type,
                                            stockCount: parseInt(stockCount),
                                          });
                                        }
                                        return a;
                                      },
                                      []
                                    )
                                    .find(
                                      (item: any) =>
                                        item.type === CartonWithDozens
                                    )?.stockCount
                                )}
                              </span>
                            </div>
                          </Col>
                        ) : (
                          <></>
                        )}
                        {selectedProduct
                          .map((i: any) => i.quantityTypes)
                          .flat()
                          .reduce((a: any, { type, stockCount }: any) => {
                            const existing = a.find(
                              (x: any) => x.type === type
                            );
                            if (existing) {
                              existing.stockCount =
                                parseInt(existing.stockCount) +
                                parseInt(stockCount);
                            } else {
                              a.push({
                                type,
                                stockCount: parseInt(stockCount),
                              });
                            }
                            return a;
                          }, [])
                          .find((item: any) => item.type === CartonWithPieces)
                          ?.stockCount ? (
                          <Col xs={12}>
                            <div className="d-flex justify-content-between align-items-center">
                              <span className="fs-16 fw-500">
                                Carton (In Pieces):
                              </span>
                              <span className="fs-16 fw-600">
                                {Method.getGeneralizedAmount(
                                  selectedProduct
                                    .map((i: any) => i.quantityTypes)
                                    .flat()
                                    .reduce(
                                      (a: any, { type, stockCount }: any) => {
                                        const existing = a.find(
                                          (x: any) => x.type === type
                                        );
                                        if (existing) {
                                          existing.stockCount =
                                            parseInt(existing.stockCount) +
                                            parseInt(stockCount);
                                        } else {
                                          a.push({
                                            type,
                                            stockCount: parseInt(stockCount),
                                          });
                                        }
                                        return a;
                                      },
                                      []
                                    )
                                    .find(
                                      (item: any) =>
                                        item.type === CartonWithPieces
                                    )?.stockCount
                                )}
                              </span>
                            </div>
                          </Col>
                        ) : (
                          <></>
                        )}
                        {selectedProduct
                          .map((i: any) => i.quantityTypes)
                          .flat()
                          .reduce((a: any, { type, stockCount }: any) => {
                            const existing = a.find(
                              (x: any) => x.type === type
                            );
                            if (existing) {
                              existing.stockCount =
                                parseInt(existing.stockCount) +
                                parseInt(stockCount);
                            } else {
                              a.push({
                                type,
                                stockCount: parseInt(stockCount),
                              });
                            }
                            return a;
                          }, [])
                          .find((item: any) => item.type === Dozen)
                          ?.stockCount ? (
                          <Col xs={12}>
                            <div className="d-flex justify-content-between align-items-center">
                              <span className="fs-16 fw-500">Dozens:</span>
                              <span className="fs-16 fw-600">
                                {Method.getGeneralizedAmount(
                                  selectedProduct
                                    .map((i: any) => i.quantityTypes)
                                    .flat()
                                    .reduce(
                                      (a: any, { type, stockCount }: any) => {
                                        const existing = a.find(
                                          (x: any) => x.type === type
                                        );
                                        if (existing) {
                                          existing.stockCount =
                                            parseInt(existing.stockCount) +
                                            parseInt(stockCount);
                                        } else {
                                          a.push({
                                            type,
                                            stockCount: parseInt(stockCount),
                                          });
                                        }
                                        return a;
                                      },
                                      []
                                    )
                                    .find((item: any) => item.type === Dozen)
                                    ?.stockCount
                                )}
                              </span>
                            </div>
                          </Col>
                        ) : (
                          <></>
                        )}
                        {selectedProduct
                          .map((i: any) => i.quantityTypes)
                          .flat()
                          .reduce((a: any, { type, stockCount }: any) => {
                            const existing = a.find(
                              (x: any) => x.type === type
                            );
                            if (existing) {
                              existing.stockCount =
                                parseInt(existing.stockCount) +
                                parseInt(stockCount);
                            } else {
                              a.push({
                                type,
                                stockCount: parseInt(stockCount),
                              });
                            }
                            return a;
                          }, [])
                          .find((item: any) => item.type === Piece)
                          ?.stockCount ? (
                          <Col xs={12}>
                            <div className="d-flex justify-content-between align-items-center">
                              <span className="fs-16 fw-500">Pieces:</span>
                              <span className="fs-16 fw-600">
                                {Method.getGeneralizedAmount(
                                  selectedProduct
                                    .map((i: any) => i.quantityTypes)
                                    .flat()
                                    .reduce(
                                      (a: any, { type, stockCount }: any) => {
                                        const existing = a.find(
                                          (x: any) => x.type === type
                                        );
                                        if (existing) {
                                          existing.stockCount =
                                            parseInt(existing.stockCount) +
                                            parseInt(stockCount);
                                        } else {
                                          a.push({
                                            type,
                                            stockCount: parseInt(stockCount),
                                          });
                                        }
                                        return a;
                                      },
                                      []
                                    )
                                    .find((item: any) => item.type === Piece)
                                    ?.stockCount
                                )}
                              </span>
                            </div>
                          </Col>
                        ) : (
                          <></>
                        )}
                        <Col xs={12}>
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="fs-16 fw-500">Subtotal:</span>
                            <span className="fs-16 fw-600">
                              TSh{" "}
                              {discountValidation
                                ? Method.getGeneralizedAmount(
                                    orderValue.totalAmount
                                  )
                                : discountValue.length
                                ? Method.getGeneralizedAmount(
                                    parseInt(orderValue.totalAmount) -
                                      parseInt(discountValue[0].value)
                                  )
                                : Method.getGeneralizedAmount(
                                    orderValue.totalAmount
                                  )}
                            </span>
                          </div>
                        </Col>
                        {/* <Col xs={12}>
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="fs-16 fw-500">
                              Product discount:
                            </span>
                            <span className="fs-16 fw-600">
                              TSh {orderValue.totalAmount}
                            </span>
                          </div>
                        </Col> */}
                        <Col xs={12}>
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="fs-16 fw-500">
                              U-Trade special <br />
                              discount:
                            </span>
                            {discount ? (
                              <>
                                <div className="d-flex flex-row">
                                  <InputGroup
                                    className={clsx(
                                      "min-w-50px border border-r8px",
                                      discountValidation ? "border-danger" : ""
                                    )}
                                  >
                                    <InputGroup.Text className="border-0 bg-white px-3 pe-1 fs-15 fw-600">
                                      TSh
                                    </InputGroup.Text>
                                    <Form.Control
                                      type="number"
                                      className="form-control-custom min-h-50px w-100px bg-white border-0 ps-1"
                                      placeholder="0"
                                      onChange={(event: any) => {
                                        handleDiscount(event.target.value);
                                      }}
                                    />
                                  </InputGroup>
                                  <Button
                                    variant="link"
                                    className="btn-flush ms-3"
                                    onClick={() => {
                                      setDiscountValue([]);
                                      setDiscount(false);
                                    }}
                                  >
                                    <img src={CrossIcon} alt="" />
                                  </Button>
                                </div>
                              </>
                            ) : (
                              <>
                                <Button
                                  variant="link"
                                  className="btn-flush text-primary fs-15 fw-600"
                                  onClick={() => {
                                    setDiscount(true);
                                  }}
                                >
                                  Add discount
                                </Button>
                              </>
                            )}
                          </div>
                        </Col>
                        {/* <Col xs={12}>
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="fs-16 fw-500">
                              U-Trade commission:
                            </span>
                            <span className="fs-16 fw-600">TSh 0</span>
                          </div>
                        </Col> */}
                        {/* <Col xs={12}>
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="fs-16 fw-500">Tax (2%):</span>
                            <span className="fs-16 fw-600">
                              TSh{' '}
                              {(
                                orderValue.totalAmountWithTax -
                                orderValue.totalAmount
                              )}
                            </span>
                          </div>
                        </Col> */}
                        <Col xs={12}>
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="fs-16 fw-500">Tax:</span>
                            <span className="fs-16 fw-600">
                              TSh{" "}
                              {Method.getGeneralizedAmount(
                                orderValue.totalAmount -
                                  orderValue.totalAmountWithTax
                              )}
                            </span>
                          </div>
                        </Col>
                        <Col xs={12}>
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="fs-16 fw-bold">Grand Total:</span>
                            <span className="fs-16 fw-bold">
                              TSh{" "}
                              {discountValidation
                                ? Method.getGeneralizedAmount(
                                    orderValue.totalAmount
                                  )
                                : discountValue.length
                                ? Method.getGeneralizedAmount(
                                    parseInt(orderValue.totalAmount) -
                                      parseInt(discountValue[0].value)
                                  )
                                : Method.getGeneralizedAmount(
                                    orderValue.totalAmount
                                  )}
                            </span>
                          </div>
                        </Col>
                        <Col xs={12}>
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="fs-16 fw-bold">
                              U-Trade commission:
                            </span>
                            <span className="fs-16 fw-bold">
                              TSh{" "}
                              {Method.getGeneralizedAmount(
                                orderValue.totalCommission
                              )}
                            </span>
                          </div>
                        </Col>
                        <Col xs={12}>
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="fs-16 fw-bold">
                              Instant Order:
                            </span>
                            <div className="form-check form-check-custom form-check-solid mb-lg-0">
                              <input
                                className="form-check-input h-24px w-24px"
                                type="radio"
                                checked={instantOrder}
                                onChange={() => {
                                  setInstantOrder(!instantOrder);
                                }}
                              />{" "}
                              <label className="form-check-label text-dark fs-16 fw-600 me-4">
                                Yes
                              </label>{" "}
                              <input
                                className="form-check-input h-24px w-24px"
                                type="radio"
                                checked={!instantOrder}
                                onChange={() => {
                                  setInstantOrder(!instantOrder);
                                }}
                              />{" "}
                              <label className="form-check-label text-dark fs-16 fw-600">
                                No
                              </label>{" "}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Body className="align-items-center">
                      <p className="fs-16 fw-500">
                        The customer has to make payment of this order in cash.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              ) : (
                <></>
              )}
            </Row>
            {/* BUTTON */}
            <div className="d-flex justify-content-start mt-9">
              <Button size="lg" onClick={() => handlePlaceOrder()}>
                {!loading && (
                  <span className="indicator-label"> Place order</span>
                )}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default AddNewOrderSubAdmin;
