import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Warehouse from './warehouse';
import CreateWarehouse from './create-warehouse';
import WarehouseDetail from './warehouse-detail';
import InterTransferRequest from './inter-transfer-request/inter-transfer-request';
import CreateInterTransferRequest from './inter-transfer-request/create-transfer-request';
import InterTransferDetail from './inter-transfer-request/inter-transfer-detail';
import AddGoodsToInventory from './inter-transfer-request/add-goods-to-inventory';
import EditWarehouse from './edit-warehouse';
import SendGoodsSeller from './inter-transfer-request/send-goods-seller';
const WarehousePage = () => (
  <Routes>
    <Route element={<Outlet />} />
    <Route
      index
      element={<Navigate to="/warehouse/all-warehouse" />}
    />
    <Route
      path="all-warehouse"
      element={
        <>
          <Warehouse />
        </>
      }
    />
    <Route
      path="all-inter-transfer-request/add-goods-to-inventory"
      element={
        <>
          <AddGoodsToInventory />
        </>
      }
    />
    <Route
      path="all-warehouse/create-warehouse"
      element={
        <>
          <CreateWarehouse />
        </>
      }
    />
    <Route
      path="all-warehouse/warehouse-details"
      element={
        <>
          <WarehouseDetail />
        </>
      }
    />
    <Route
      path="all-inter-transfer-requests"
      element={
        <>
          <InterTransferRequest />
        </>
      }
    />
    <Route
      path="create-transfer-request"
      element={
        <>
          <CreateInterTransferRequest />
        </>
      }
    />
    <Route
      path="all-inter-transfer-request/inter-transfer-details"
      element={
        <>
          <InterTransferDetail />
        </>
      }
    />
    <Route
      path="edit-warehouse-details"
      element={
        <>
          <EditWarehouse />
        </>
      }
    />
    <Route
      path="all-inter-transfer-request/send-goods-seller"
      element={
        <>
          <SendGoodsSeller />
        </>
      }
    />
  </Routes>
);
export default WarehousePage;
