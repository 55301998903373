import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  FormLabel,
  Nav,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Method from '../../../utils/methods';
import { useAuth } from '../auth';
import CustomDatePicker from '../../custom/DateRange/DatePicker';
import APICallService from '../../../api/apiCallService';
import Loader from '../../../Global/loader';
import {
  customerService,
  ordersDelivery,
  seller,
} from '../../../api/apiEndPoints';
import OrderPagination from '../../../Global/order-pagination';
import editIcon from '../../../_admin/assets/media/svg_uTrade/edit-round-blue.svg';
import verify from '../../../_admin/assets/media/svg_uTrade/verified.svg';
import CrossIcon from '../../../_admin/assets/media/svg_uTrade/cross-rounded-blue.svg';
import DeleteIcon from '../../../_admin/assets/media/svg_uTrade/trash.svg';
import greenCheck from '../../../_admin/assets/media/svg_uTrade/green_check.svg';
import {
  AllPaymentMethod,
  DeliveryBank,
  DeliveryCash,
  DeliveryReports,
  DeliveryTigoPesa,
  Edit,
  Manager,
  ORDER_PAGE_LIMIT,
  OrderCash,
  OrdersDelivery,
  ServiceMember,
  View,
} from '../../../utils/constants';
import { CustomSelect } from '../../custom/Select/CustomSelect';
import { CustomSelectTable2 } from '../../custom/Select/custom-select-table';
import { error } from '../../../Global/toast';
import { listOrdersDeliveryReport } from '../../../utils/storeString';
import { getKey, setKey } from '../../../Global/history';
import { KTSVG } from '../../../_admin/helpers';
import clsx from 'clsx';
import { ElementAnimateUtil } from '../../../_admin/assets/ts/_utils';
import PermissionModal from '../../modals/permission';
import { warehouse as warehouseStoreString } from '../../../utils/storeString';
const DeliveryReport = () => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [fetchLoader, setFetchLoader] = useState(true);
  const [orders, setOrders] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<any>(
    getKey(listOrdersDeliveryReport.page) || 1
  );
  const [pageLimit, setPageLimit] = useState<any>(
    getKey(listOrdersDeliveryReport.limit) || ORDER_PAGE_LIMIT
  );
  const [startDate, setStartDate] = useState<any>(
    getKey(listOrdersDeliveryReport.filterStartDate)
      ? new Date(
          getKey(listOrdersDeliveryReport.filterStartDate)?.toString() || ''
        )
      : new Date()
  );
  const [endDate, setEndDate] = useState<any>(
    getKey(listOrdersDeliveryReport.filterEndDate)
      ? new Date(
          getKey(listOrdersDeliveryReport.filterEndDate)?.toString() || ''
        )
      : new Date()
  );
  const [area, setArea] = useState<any>(
    getKey(listOrdersDeliveryReport.currentAreas)
      ? getKey(listOrdersDeliveryReport.currentAreas)
      : JSON.stringify({ value: '-1', label: 'All', title: 'All' })
  );
  const [district, setDistrict] = useState<any>(
    getKey(listOrdersDeliveryReport.currentDistrict) || -1
  );
  const [districtData, setDistrictData] = useState<any>([]);
  const [selectedDistrict, setSelectedDistrict] = useState<any>([]);
  const [distictAreas, setDistrictAreas] = useState<any>([]);
  const [financeBankData, setFinanceBankData] = useState<any>([]);
  const [download, setDownload] = useState(false);
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState<{
    [key: string]: number;
  }>({});
  const [selectedBanks, setSelectedBanks] = useState<{ [key: string]: any }>(
    {}
  );
  const [payment, setPayment] = useState<any>(
    getKey(listOrdersDeliveryReport.filterPayment) || 0
  );
  const [dropdownVisible, setDropdownVisible] = useState<{
    [key: number]: boolean;
  }>({});
  const [validations, setValidations] = useState<{
    [key: string]: boolean;
  }>({});
  const [dataFetched, setDataFetched] = useState(false);
  const [count, setCount] = useState<any>([]);
  const [showVerifyIcon, setShowVerifyIcon] = useState<string | null>(null);
  const paymentOptions = [
    { value: AllPaymentMethod, label: 'All' },
    { value: DeliveryCash, label: 'Cash' },
    { value: DeliveryTigoPesa, label: 'TigoPesa' },
    { value: DeliveryBank, label: 'Bank' },
  ];
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  useEffect(() => {
    (async () => {
      if (!Method.hasModulePermission(DeliveryReports, currentUser)) {
        return window.history.back();
      }
      setFetchLoader(true);
      fetchOrder(
        page,
        pageLimit,
        startDate,
        endDate,
        district,
        JSON.parse(area)?.value,
        payment
      );
      await fetchFinanceSales(
        startDate,
        endDate,
        district,
        JSON.parse(area)?.value,
        payment
      );
      await fetchDistrict();
      await fetchFinanceBank();
      setDataFetched(true);
      setFetchLoader(false);
    })();
  }, []);
  useEffect(() => {
    if (dataFetched) {
      let initialScrollPosition: any = getKey(
        listOrdersDeliveryReport.scrollPosition
      );
      if (initialScrollPosition) {
        // ElementAnimateUtil.scrollTo(parseInt(initialScrollPosition));
        setDataFetched(false);
      }
      setKey(listOrdersDeliveryReport.filterStartDate, null, false);
      setKey(listOrdersDeliveryReport.filterEndDate, null, false);
      setKey(listOrdersDeliveryReport.filterPayment, 0, false);
      setKey(listOrdersDeliveryReport.currentDistrict, -1, false);
      setKey(listOrdersDeliveryReport.currentAreas, '', false);
    }
  }, [dataFetched]);
  const fetchOrder = async (
    pageNo: number,
    limit: number,
    startDate: string,
    endDate: string,
    district?: any,
    area?: any,
    payment?: any
  ) => {
    let params:{
      pageNo: number;
      limit: number;
      sortKey: string;
      sortOrder: number;
      needCount: boolean;
      searchTerm: string;
      fromDate: string;
      toDate: string;
      district: any;
      ward: string | number;
      paymentMethod?: any;
      warehouse?: any
    } = {
      pageNo: pageNo,
      limit: limit,
      sortKey: 'statusUpdatedAt',
      sortOrder: -1,
      needCount: true,
      searchTerm: '',
      fromDate: startDate
        ? Method.convertDateToFormat(startDate, 'YYYY-MM-DD')
        : '',
      toDate: endDate ? Method.convertDateToFormat(endDate, 'YYYY-MM-DD') : '',
      district: district && district !== -1 ? district : '',
      ward:
        area && area != -1 ? (area != -2 ? encodeURIComponent(area) : -1) : '',
    };
    if (payment) {
      params = { ...params, paymentMethod: payment };
    }
    if (tempWarehouse?._id) {
      params = {
        ...params,
        warehouse: tempWarehouse?._id,
      };
    }
    let apiService = new APICallService(seller.orderDelivered, params,'','','','',DeliveryReports,tempWarehouse?._id || null);
    let response: any = await apiService.callAPI();
    if (response.records) {
      if (response.total) {
        setTotalRecords(response.total);
      } else {
        setTotalRecords(0);
      }
      setOrders(response.records);
      const paymentMethods: any = {};
      const banks: any = {};
      const newValidations: any = {};
      response.records.forEach((order: any) => {
        if (order.paymentSettlement) {
          paymentMethods[order._id] = order.paymentSettlement.paymentMethod;
          if (order.paymentSettlement.paymentMethod === DeliveryBank) {
            banks[order._id] = order.paymentSettlement.bankDetails.reference;
          }
          newValidations[order._id] = false;
        }
      });
      setSelectedPaymentMethods(paymentMethods);
      setSelectedBanks(banks);
      setValidations(newValidations);
    }
  };
  const fetchFinanceSales = async (
    startDate?: string,
    endDate?: string,
    district?: any,
    area?: any,
    payment?: any
  ) => {
    let params: any = {
      fromDate: startDate
        ? Method.convertDateToFormat(startDate, 'YYYY-MM-DD')
        : '',
      toDate: endDate ? Method.convertDateToFormat(endDate, 'YYYY-MM-DD') : '',
      district: district && district !== -1 ? district : '',
      ward:
        area && area != -1 ? (area != -2 ? encodeURIComponent(area) : -1) : '',
    };
    if (payment) {
      params = { ...params, paymentMethod: payment };
    }
    if (tempWarehouse?._id) {
      params = {
        ...params,
        warehouse: tempWarehouse?._id,
      };
    }
    let apiService = new APICallService(seller.financeSalesReport, params,'','','','',DeliveryReports,tempWarehouse?._id || null);
    let response: any = await apiService.callAPI();
    if (response) {
      const newCount: any = [];
      response.forEach((item: any) => {
        switch (item.paymentMode) {
          case DeliveryBank:
            newCount.push({
              totalAmount: item.totalAmount,
              bankName: `Bank (${item.bankDetails?.name})` || '',
            });
            break;
          case DeliveryTigoPesa:
            newCount.push({
              totalAmount: item.totalAmount,
              bankName: 'Tigo Pesa',
            });
            break;
          case DeliveryCash:
            newCount.push({
              totalAmount: item.totalAmount,
              bankName: 'Cash',
            });
            break;
        }
      });
      console.log('hii',newCount);
      setCount(newCount);
    }
  };
  const handleEditClick = (index: any) => {
    setDropdownVisible((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const handleHideDropdown = (index: any) => {
    setDropdownVisible((prev) => ({
      ...prev,
      [index]: false,
    }));
  };
  const handleCurrentPage = (val: number) => {
    setPage(val);
    setKey(listOrdersDeliveryReport.page, val);
    setFetchLoader(true);
    fetchOrder(
      val,
      pageLimit,
      startDate,
      endDate,
      district,
      JSON.parse(area)?.value,
      payment
    );
    setFetchLoader(false);
  };
  const handleNextPage = (val: number) => {
    setPage(val + 1);
    setKey(listOrdersDeliveryReport.page, val + 1);
    setFetchLoader(true);
    fetchOrder(
      val + 1,
      pageLimit,
      startDate,
      endDate,
      district,
      JSON.parse(area)?.value,
      payment
    );
    setFetchLoader(false);
  };
  const handlePreviousPage = (val: number) => {
    setPage(val - 1);
    setKey(listOrdersDeliveryReport.page, val - 1);
    setFetchLoader(true);
    fetchOrder(
      val - 1,
      pageLimit,
      startDate,
      endDate,
      district,
      JSON.parse(area)?.value,
      payment
    );
    setFetchLoader(false);
  };
  const handlePageLimit = (event: any) => {
    setPage(1);
    setPageLimit(parseInt(event.target.value));
    setFetchLoader(true);
    fetchOrder(
      1,
      event.target.value,
      startDate,
      endDate,
      district,
      JSON.parse(area)?.value,
      payment
    );
    setFetchLoader(false);
    setKey(listOrdersDeliveryReport.page, 1);
    setKey(listOrdersDeliveryReport.limit, parseInt(event.target.value));
  };
  const handleDateFilter = async ([startDate, endDate]: any) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setPage(1);
    setKey(listOrdersDeliveryReport.page, 1);
    if (startDate && endDate) {
      setFetchLoader(true);
      setTotalRecords(0);
      await fetchOrder(
        1,
        pageLimit,
        Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
        Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
        district,
        JSON.parse(area)?.value,
        payment
      );
      await fetchFinanceSales(
        Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
        Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
        district,
        JSON.parse(area)?.value,
        payment
      );
      setFetchLoader(false);
    }
  };
  const handlePaymentMethodChange = async (event: any) => {
    setPage(1);
    setKey(listOrdersDeliveryReport.page, 1);
    setFetchLoader(true);
    if (event.value == 0) {
      await fetchOrder(
        1,
        pageLimit,
        Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
        Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
        district,
        JSON.parse(area)?.value,
      );
      await fetchFinanceSales(
        Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
        Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
        district,
        JSON.parse(area)?.value,
      );
    } else {
      await fetchOrder(
        1,
        pageLimit,
        Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
        Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
        district,
        JSON.parse(area)?.value,
        event?.value
      );
      await fetchFinanceSales(
        Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
        Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
        district,
        JSON.parse(area)?.value,
        event?.value
      );
    }
    setPayment(event?.value);
    setFetchLoader(false);
  };
  // const fetchDistrict = async () => {
  //   let params = {};
  //   let apiService = new APICallService(customerService.listDistrict, params);
  //   let response = await apiService.callAPI();
  //   if (response?.records) {
  //     setDistrictData(response.records);
  //   }
  // };
  const fetchDistrict = async () => {
    const allOption = { _id: -1, name: 'All', title: 'All' };
    let params = {};
    let apiService = new APICallService(customerService.listDistrict, params,'','','','',DeliveryReports,tempWarehouse?._id || null);
    let response = await apiService.callAPI();
    if (response?.records) {
      let districtRecords = response.records.map((district: any) => {
        return { _id: district._id, name: district.name, title: district.name };
      });
      let finalDistricts = [allOption, ...districtRecords];
      setDistrictData(finalDistricts);
    }
  };
  const fetchFinanceBank = async () => {
    let params = {};
    let apiService = new APICallService(seller.financeBankList, params,'','','','',DeliveryReports,tempWarehouse?._id || null);
    let response = await apiService.callAPI();
    if (response) {
      setFinanceBankData(response);
    }
  };
  const fetchAreas = async (districtId: any) => {
    const allOption = { value: '-1', label: 'All', title: 'All' };
    setDistrictAreas([allOption]);
    let params: any = {};
    let endpoint = customerService.listArea;
    if (
      currentUser?.role.some(
        (obj: any) => obj.type === Manager || obj.type === ServiceMember
      )
    ) {
      endpoint = customerService.listAssistArea;
    }
    if (tempWarehouse?._id) {
      params = {
        ...params,
        warehouse: tempWarehouse?._id,
      };
    }
    let apiService = new APICallService(endpoint, params, {
      districtId,
    },'','','',DeliveryReports,tempWarehouse?._id || null);
    let response = await apiService.callAPI();
    if (response?.length) {
      let transformedAreas = response.map((area: string | null) => {
        if (area === null) {
          return { value: '-2', label: 'Other', title: 'Other' };
        } else {
          return { value: area, label: area, title: area };
        }
      });
      transformedAreas = transformedAreas.filter(
        (option: any) => option.value !== '-1'
      );
      transformedAreas = transformedAreas.sort((a: any, b: any) => {
        if (a.label === 'Other') return 1; // Move 'Other' to the end
        if (b.label === 'Other') return -1; // Keep 'Other' at the end
        return a.label.localeCompare(b.label); // Sort the rest alphabetically
      });
      // const otherOption = { value: 'Other', label: 'Other', title: 'Other' };
      let finalAreas = [allOption, ...transformedAreas];
      setDistrictAreas(finalAreas);
    }
  };
  const setAreaDetails = async (selectedValue: any) => {
    setFetchLoader(true);
    setArea(JSON.stringify(selectedValue));
    setPage(1);
    setKey(listOrdersDeliveryReport.page, 1);
    await fetchOrder(
      1,
      pageLimit,
      startDate,
      endDate,
      district,
      selectedValue?.value,
      payment
    );
    await fetchFinanceSales(
      Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
      Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
      district,
      selectedValue?.value,
      payment
    );
    setFetchLoader(false);
  };
  const handleDistricts = async (event: any) => {
    setPage(1);
    setKey(listOrdersDeliveryReport.page, 1);
    setArea(
      JSON.stringify({
        value: '-1',
        label: 'All',
        title: 'All',
      })
    );
    setDistrict(event.value);
    setFetchLoader(true);
    setPage(1);
    await fetchOrder(
      1,
      pageLimit,
      startDate,
      endDate,
      event.value,
      JSON.parse(area)?.value,
      payment
    );
    await fetchFinanceSales(
      startDate,
      endDate,
      event.value,
      JSON.parse(area)?.value,
      payment
    );
    setFetchLoader(false);
  };
  const handlePaymentChange = async (
    event: any,
    index: number,
    orderId: string
  ) => {
    const newPaymentMethods = {
      ...selectedPaymentMethods,
      [orderId]: event.value,
    };
    setSelectedPaymentMethods(newPaymentMethods);
    if (event.value !== DeliveryBank) {
      const newSelectedBanks = { ...selectedBanks };
      delete newSelectedBanks[orderId];
      setSelectedBanks(newSelectedBanks);
      let params = {
        id: orderId,
      };
      setValidations((prev) => ({ ...prev, [orderId]: false }));
      let apiService = new APICallService(
        seller.updateFinanceDetails,
        { paymentMethod: event?.value },
        params,
        '','','',DeliveryReports,tempWarehouse?._id || null
      );
      let response = await apiService.callAPI();
      if (response) {
        await fetchOrder(
          page,
          pageLimit,
          startDate,
          endDate,
          district,
          JSON.parse(area)?.value,
          payment
        );
        await fetchFinanceSales(
          startDate,
          endDate,
          district,
          JSON.parse(area)?.value,
          payment
        );
        setShowVerifyIcon(orderId);
        setTimeout(() => setShowVerifyIcon(null), 2000);
      }
      handleHideDropdown(orderId);
    } else {
      setValidations((prev) => ({ ...prev, [orderId]: true }));
    }
  };
  const handleBankChange = async (
    event: any,
    index: number,
    orderId: string
  ) => {
    const newSelectedBanks = { ...selectedBanks, [orderId]: event.value };
    setSelectedBanks(newSelectedBanks);
    setValidations((prev) => ({ ...prev, [orderId]: false }));
    let params = {
      id: orderId,
    };
    let apiService = new APICallService(
      seller.updateFinanceDetails,
      { paymentMethod: 3, bankId: event?.value },
      params,
      '','','',DeliveryReports,tempWarehouse?._id || null
    );
    let response = await apiService.callAPI();
    if (response) {
      fetchOrder(
        page,
        pageLimit,
        startDate,
        endDate,
        district,
        JSON.parse(area)?.value,
        payment
      );
      fetchFinanceSales(
        startDate,
        endDate,
        district,
        JSON.parse(area)?.value,
        payment
      );
      setShowVerifyIcon(orderId);
      setTimeout(() => setShowVerifyIcon(null), 2000);
    }
    handleHideDropdown(orderId);
  };
  const popoverArea = (
    <Popover id="popover-basic">
      <Popover.Body>
        <div className="mb-5">
          <label className="form-label fw-semibold">Areas:</label>
          <CustomSelect
            minWidth="200px"
            options={distictAreas.map((area: any) => ({
              value: area.value,
              label: area.label,
              title: area.title,
            }))}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            isSearchable={true}
            getOptionLabel={(option: any) => option.label}
            onChange={(selectedOptions: any) => {
              setAreaDetails(selectedOptions);
            }}
            placeholder="select areas"
            value={area ? JSON.parse(area) : {}}
          />
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <div className="p-9">
      {showPermissionModal && (
        <PermissionModal
        show={showPermissionModal}
        onHide={() => setShowPermissionModal(false)}
        moduleName = {'orders & delivery'}
      />
      )}
      <Row className="align-items-center g-md-5 g-3 mb-7">
        <Col xs>
          <h1 className="fs-22 fw-bolder">Delivery Report</h1>
        </Col>
        <Col xs="auto">
          <CustomSelect
            value={districtData.map((val: any) => {
              if (val._id === district) {
                return {
                  label: val.name,
                  value: val._id,
                  title: val.name,
                };
              }
            })}
            // backgroundColor="#f9f9f9"
            minHeight="50px"
            minWidth="224px"
            controlFontSize="1.231rem"
            onChange={(event: any) => {
              handleDistricts(event);
            }}
            options={districtData.map((val: any) => {
              return {
                label: val.name,
                value: val._id,
                title: val.name,
              };
            })}
          />
        </Col>
        {district !== -1 ? (
          <Col xs="auto">
            <Nav.Item className="d-flex align-items-center">
              <OverlayTrigger
                trigger="click"
                placement="bottom-end"
                rootClose={true}
                rootCloseEvent="click"
                overlay={popoverArea}
                onToggle={async (show) => {
                  if (show) {
                    await fetchAreas(district);
                  }
                }}
              >
                <Button
                  variant="link"
                  className="btn btn-icon w-20px h-20px w-md-20px h-md-20px"
                >
                  <KTSVG
                    path="/media/icons/duotune/general/gen031.svg"
                    className={clsx(
                      'svg-icon-2x cursor-pointer',
                      area
                        ? JSON.parse(area).value != -1
                          ? 'svg-icon-primary'
                          : ''
                        : ''
                    )}
                  />
                </Button>
              </OverlayTrigger>
            </Nav.Item>
          </Col>
        ) : (
          <></>
        )}
      </Row>
      <Row className="align-items-center mb-7 g-6">
        {count && count.length>0 &&
          count.map((bank: any, index: number) => (
            <Col
              xl={4}
              md={6}
              sm={4}
              key={index}
            >
              <div className="border-r8px bg-fbeace rounded py-4 px-5">
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-column">
                    <span className="fs-22 fw-bolder">
                      {bank.totalAmount > 0
                        ? 'TSh ' + Method.getGeneralizedAmount(bank.totalAmount)
                        : 0}
                    </span>
                    <span className="fw-medium fs-16">{bank.bankName}</span>
                  </div>
                </div>
              </div>
            </Col>
          ))}
      </Row>
      <Col xs={12}>
        <Card className="bg-light border mb-7">
          <Card.Body className="px-7">
            <Row className="d-flex justify-content-between align-items-center">
              <Col md="auto">
                <div className="d-flex align-items-center">
                  <FormLabel className="fs-16 fw-500">
                    Filter by dates
                  </FormLabel>
                  <div className="ms-5">
                    <CustomDatePicker
                      className="form-control bg-white min-h-30px fs-16 fw-bold text-dark min-w-md-288px min-w-175px"
                      onChange={handleDateFilter}
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="dd/MM/yyyy"
                      showFullMonthYearPicker={true}
                      maxDate={new Date()}
                      inputTextBG="bg-white"
                    />
                  </div>
                </div>
              </Col>
              <Col md="auto">
                <div className="d-flex align-items-center">
                  <FormLabel className="fs-16 fw-500">
                    Filter by Payment Method
                  </FormLabel>
                  <div className="ms-5">
                    <CustomSelectTable2
                      backgroundColor="#ffffff"
                      minHieight={'50px'}
                      minWidth={'250px'}
                      value={
                        paymentOptions.find(
                          (option) => option.value === payment
                        ) || paymentOptions[0]
                      }
                      options={paymentOptions}
                      onChange={(e: any) => {
                        handlePaymentMethodChange(e);
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Row>
        <Col xs={12}>
          {!fetchLoader ? (
            orders && orders.length ? (
              <>
                <Card className="border border-r10px">
                  <Card.Body className="p-0">
                    <div className="table-responsive">
                      <table className="table table-rounded table-row-bordered align-middle gs-7 gy-6 mb-0">
                        <thead>
                          <tr className="fw-bold fs-16 fw-600 text-dark border-bottom align-middle">
                            <th className="min-w-md-125px">Order Date & ID</th>
                            <th className="min-w-md-125px">Customer name</th>
                            <th className="min-w-md-125px">Delivered on</th>
                            <th className="min-w-md-130px">Total amount</th>
                            <th className="min-w-md-120px">
                              Customer Payment Method
                            </th>
                            <th className="min-w-md-150px">
                              Finance Payment settlement
                            </th>
                            <th className="min-w-50px text-end"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {orders.map((orderVal: any, index: number) => (
                            <tr key={index}>
                              <td className="fs-15 fw-500">
                                <span className="text-dark d-block">
                                  {Method.convertDateToDDMMYYYYHHMM(
                                    orderVal._createdAt,
                                    '-'
                                  )}
                                </span>
                                <span className="text-gray d-block">
                                  {orderVal.refKey}
                                </span>
                              </td>
                              <td>
                                <span className="fs-15 fw-500">
                                  {orderVal.customer.name}
                                </span>
                              </td>
                              <td>
                                <span className="fs-15 fw-500">
                                  {Method.convertDateToDDMMYYYYHHMM(
                                    orderVal.statusUpdatedAt,
                                    '-'
                                  )}
                                </span>
                              </td>
                              <td>
                                <span className="fs-15 fw-500">
                                  {'TSh ' +
                                    Method.getGeneralizedAmount(
                                      orderVal.payment.totalCharge
                                    )}
                                </span>
                              </td>
                              <td>
                                <span className="fs-15 fw-500">
                                  {orderVal.payment.paymentMode == OrderCash
                                    ? 'Cash'
                                    : 'Tigo Pesa'}
                                </span>
                              </td>
                              <td>
                                <div className="fw-bold fs-15 fw-500">
                                  <div className="d-flex align-items-center">
                                    {dropdownVisible[orderVal._id] ? (
                                      <>
                                        <CustomSelectTable2
                                          placeholder={'select..'}
                                          backgroundColor="#f9f9f9"
                                          minHieight={'50px'}
                                          minWidth={'150px'}
                                          value={{
                                            value:
                                              selectedPaymentMethods[
                                                orderVal._id
                                              ] || '',
                                            label:
                                              selectedPaymentMethods[
                                                orderVal._id
                                              ] === DeliveryCash
                                                ? 'Cash'
                                                : selectedPaymentMethods[
                                                    orderVal._id
                                                  ] === DeliveryTigoPesa
                                                ? 'TigoPesa'
                                                : selectedPaymentMethods[
                                                    orderVal._id
                                                  ] === DeliveryBank
                                                ? 'Bank'
                                                : '',
                                          }}
                                          options={[
                                            {
                                              value: DeliveryCash,
                                              label: 'Cash',
                                            },
                                            {
                                              value: DeliveryTigoPesa,
                                              label: 'TigoPesa',
                                            },
                                            {
                                              value: DeliveryBank,
                                              label: 'Bank',
                                            },
                                          ]}
                                          onChange={(e: any) => {
                                            handlePaymentChange(
                                              e,
                                              index,
                                              orderVal?._id
                                            );
                                          }}
                                        />
                                        {selectedPaymentMethods[
                                          orderVal?._id
                                        ] === DeliveryBank ? (
                                          <div className="mx-2">
                                            <CustomSelectTable2
                                              className={clsx(
                                                validations[orderVal._id]
                                                  ? 'border-danger'
                                                  : ''
                                              )}
                                              border={
                                                validations[orderVal._id]
                                                  ? '#d9534f'
                                                  : ''
                                              }
                                              placeholder={'select..'}
                                              backgroundColor="#f9f9f9"
                                              minHieight={'50px'}
                                              minWidth={'150px'}
                                              value={{
                                                value:
                                                  selectedBanks[orderVal._id] ||
                                                  '',
                                                label:
                                                  financeBankData.find(
                                                    (bank: any) =>
                                                      bank._id ===
                                                      selectedBanks[
                                                        orderVal._id
                                                      ]
                                                  )?.name || '',
                                              }}
                                              options={financeBankData.map(
                                                (val: any) => {
                                                  return {
                                                    label: val.name,
                                                    value: val._id,
                                                    title: val.name,
                                                    type: val.type,
                                                  };
                                                }
                                              )}
                                              onChange={(e: any) =>
                                                handleBankChange(
                                                  e,
                                                  index,
                                                  orderVal?._id
                                                )
                                              }
                                            />
                                          </div>
                                        ) : (
                                          // (Object.keys(selectedPaymentMethods).some(key =>
                                          //   orders.some((order: any) => order._id === key) &&
                                          //   selectedPaymentMethods[key] === DeliveryBank)
                                          // ) ? (
                                          //   <span className="fs-15 fw-500 mx-auto">
                                          //     -
                                          //   </span>
                                          // ) : (
                                          <></>
                                          // )
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <span className="w-90px">
                                          {selectedPaymentMethods[
                                            orderVal._id
                                          ] === DeliveryCash
                                            ? 'Cash'
                                            : selectedPaymentMethods[
                                                orderVal._id
                                              ] === DeliveryTigoPesa
                                            ? 'TigoPesa'
                                            : selectedPaymentMethods[
                                                orderVal._id
                                              ] === DeliveryBank
                                            ? financeBankData.find(
                                                (bank: any) =>
                                                  bank._id ===
                                                  selectedBanks[orderVal._id]
                                              )?.name || 'Bank'
                                            : '-'}
                                        </span>
                                        {showVerifyIcon === orderVal._id ? (
                                          <img
                                            className="mx-2"
                                            width={34}
                                            height={34}
                                            src={greenCheck}
                                            alt="Verified"
                                          />
                                        ) : (
                                          <>
                                          {Method.hasPermission(DeliveryReports, Edit, currentUser) ? (
                                          <Button
                                            variant="link"
                                            className="btn-flush mx-2"
                                            onClick={() =>
                                              handleEditClick(orderVal?._id)
                                            }
                                            disabled={loading}
                                          >
                                            <img
                                              src={editIcon}
                                              alt="Edit"
                                            />
                                          </Button>
                                        ) : (
                                          <></>
                                        )}
                                        </>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td className="text-end">
                                <div className="d-flex align-items-center">
                                {(Method.hasPermission(DeliveryReports, View, currentUser) && Method.hasPermission(OrdersDelivery, View, currentUser)) ? (
                                  <Button
                                    className="fs-14 fw-600"
                                    onClick={() => {
                                      navigate('/orders/order-details', {
                                        state: {id: orderVal._id, moduleName: DeliveryReports},
                                      });
                                      let currentScrollY = window.scrollY;
                                      setKey(
                                        listOrdersDeliveryReport.filterStartDate,
                                        startDate,
                                        false
                                      );
                                      setKey(
                                        listOrdersDeliveryReport.filterEndDate,
                                        endDate,
                                        false
                                      );
                                      setKey(
                                        listOrdersDeliveryReport.filterPayment,
                                        payment,
                                        false
                                      );
                                      setKey(
                                        listOrdersDeliveryReport.currentDistrict,
                                        district,
                                        false
                                      );
                                      setKey(
                                        listOrdersDeliveryReport.currentAreas,
                                        area,
                                        false
                                      );
                                      setKey(
                                        listOrdersDeliveryReport.scrollPosition,
                                        currentScrollY
                                      );
                                    }}
                                  >
                                    View details
                                  </Button>
                                ) : (
                                  <></>
                                )}
                                 {(Method.hasPermission(DeliveryReports, View, currentUser) && !Method.hasPermission(OrdersDelivery, View, currentUser)) ? (
                                  <Button
                                    className="fs-14 fw-600"
                                    onClick={() =>{setShowPermissionModal(true)}}
                                  >
                                    View details
                                  </Button>
                                ) : (
                                  <></>
                                )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
                <OrderPagination
                  totalRecords={totalRecords}
                  currentPage={page}
                  handleCurrentPage={handleCurrentPage}
                  handleNextPage={handleNextPage}
                  handlePreviousPage={handlePreviousPage}
                  handlePageLimit={handlePageLimit}
                  pageLimit={pageLimit}
                />
              </>
            ) : (
              <div className="border border-r10px mb-6">
                <div className="d-flex justify-content-center text-center align-items-center min-h-160px px-3">
                  <span className="fs-18 fw-500">
                    {startDate && endDate
                      ? 'No Data Found'
                      : 'Delivered orders will be shown in this section.'}
                  </span>
                </div>
              </div>
            )
          ) : (
            <div className="border border-r10px mb-6">
              <div className="d-flex justify-content-center text-center align-items-center min-h-160px px-3">
                <Loader loading={fetchLoader || loading} />
              </div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};
export default DeliveryReport;
