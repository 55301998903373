import { getKey } from "../Global/history";
import { warehouse } from "./storeString";

export const PREF_TOKEN = 'bearerToken';
export const IS_INTRO = '/';
export const IS_LOGIN = 'Login';
// API BASE URL
// export const BASE_URL = 'https://api.utrade.imperoserver.in/';
// export const BASE_URL = 'http://127.0.0.1:4000/';
export const BASE_URL = 'http://api.dev.utrade.imperoserver.in/';
// export const BASE_URL =' https://9f77-122-179-140-29.ngrok-free.app/';
// export const BASE_URL = 'https://api.utrade.tz/';
export const PAGE_LIMIT = 10;
export const ORDER_PAGE_LIMIT = 100;
// API TYPES
export const GET = 'GET';
export const GET_URL_PARAMS = 'GET_URL_PARAMS';
export const GET_ID_PARAMS = 'GET_ID_PARAMS';
export const GET_URL_ENCODED = 'GET_URL_ENCODED';
export const GET_URL_ID_PARAMS = 'GET_URL_ID_PARAMS';
export const POST = 'POST';
export const POST_ID_PARAMS = 'POST_ID_PARAMS';
export const POST_RAW = 'POST_RAW';
export const POST_FORM = 'POST_FORM';
export const POST_URL_ENCODED = 'POST_URL_ENCODED';
export const POST_URL_PARAMS = 'POST_URL_PARAMS';
export const POST_URL_ENCODED_ID_PARAMS = 'POST_URL_ENCODED_ID_PARAMS';
export const MULTI_PART_POST = 'MULTI_PART';
export const PATCH = 'PATCH';
export const PATCH_ID = 'PATCH_ID';
export const PATCH_FORM = 'PATCH_FORM';
export const PATCH_FORM_ID = 'PATCH_FORM_ID';
export const PATCH_URL_ENCODED = 'PATCH_URL_ENCODED';
export const PATCH_FORM_ID_URL_ENCODED = 'PATCH_FORM_ID_URL_ENCODED';
export const MULTI_PART_ID_POST = 'MULTI_PART';
export const MULTI_PART_ID_PATCH = 'MULTI_PART_PATCH';
export const DELETE = 'DELETE';
export const DELETE_URL_PARAMS = 'DELETE_URL_PARAMS';
export const DELETE_URL_ENCODED = 'DELETE_URL_ENCODED';
export const DELETE_ID_PARAMS = 'DELETE_ID_PARAMS';
export const MULTI_PART_ID_DELETE = 'MULTI_PART_ID_DELETE';
//Response
export const ResponseFail = 400;
export const ResponseSuccess = 200;
export const AuthError = 401;
export const Maintenance = 503;
//KYC Request Enum
export const All = 1;
export const NewRequests = 2;
export const UnderReview = 3;
export const InfoRequired = 4;
export const Accepted = 5;
export const Rejected = 6;
//Technical Details Enum
export const SingleLineText = 1;
export const MultiLineText = 2;
export const SingleSelection = 3;
export const MultiSelection = 4;
export const DateSelection = 5;
//Variants Defined By Enum
export const Skip = 0;
export const SuggestOptions = 1;
export const TextGuide = 2;
//Product State Enum
export const AllProduct = 1;
export const Actived = 2;
export const Deactivated = 3;
export const VerificationPending = 4;
export const ProductRejectedState = 5;
//Quantity Enum
export const CartonWithDozens = 1;
export const Dozen = 2;
export const Piece = 3;
export const CartonWithPieces = 4;
//Product Status Enum
export const ApprovedProduct = 2;
export const PendingProduct = 3;
export const RejectedProduct = 4;
// Orders Status
export const NewOrder = 1;
// export const PreparingForDispatch = 2;
export const Delivered = 3;
export const Cancelled = 4;
//Advertisement Type
export const HomePage = 1;
export const CategoryPage = 2;
//Advertisement Product Type
export const SingleProduct = 1;
export const MultipleProduct = 2;
//Advertisement State
export const AdvertisementRequest = 1;
export const AdvertisementAccepted = 2;
export const AdvertisementOngoing = 3;
export const AdvertisementUpcoming = 4;
export const AdvertisementCompleted = 5;
//Advertisement PaymentStatuses
export const AdvertisementPaid = 1;
export const AdvertisementUnpaid = 2;
//Advertisement PaymentMethods
export const Card = 1;
export const Cash = 2;
//Assist Admin User Role
export const Manager = 1;
export const ServiceMember = 2;
export const FinanceManager = 3;
export const LoadingBayManager = 4;
export const FulfillmentManager = 5;
//Admin UserType
export const Admin = 1;
export const AssistAdmin = 2;
//Permissions
export const AllPermissions = 0;
//Roles
// export const All = 0;
export const View = 1;
export const Add = 2;
export const Edit = 3;
export const Delete = 4;
export const Download = 5;
//Permissions module
export const AllModules = 0;
export const Sellers = 1;
export const AllSeller = 2;
export const KycVerifications = 3;
export const PendingCertificate = 4;
export const PaymentToSellers = 5;
export const SellersSalesReport = 6;
export const Customers = 7;
export const AllCustomers = 8;
export const RefundsToCustomer = 9;
export const ReceivableReports = 10;
export const OrdersDelivery = 11;
export const RoutePlan = 12;
export const Master = 13;
export const Categories = 14;
export const BusinessType = 15;
export const BusinessCertificates = 16;
export const Brand = 17;
export const TechnicalDetails = 18;
export const ProductVariants = 19;
export const MasterProduct = 20;
export const Driver = 21;
export const Vehicle = 22;
export const VehicleLoadingArea = 23;
export const SellerProductZone = 24;
export const FinanceMember = 25;
export const RolesAndPermission = 26;
export const UserManagement = 27;
export const Inventory = 28;
export const GoodsWarehouse = 29;
export const LowStockLists = 30;
export const GoodsRequest = 31;
export const ReceivedDirectGood = 32;
export const CustomerServices = 33;
export const All_Products = 34;
export const PlatformFees = 35;
export const Advertising = 36;
export const ContactInquires = 37;
export const CustomNotifications = 38;
export const Settings = 39;
export const Privacy_Policy = 40;
export const TermsConditions = 41;
export const AppSettings = 42;
export const Dashboard = 43;
export const FinanceReports = 44;
export const OutWardReports = 45;
export const ReturnProductReports = 46;
export const DeliveryReports = 47;
export const Reports = 48;
export const CustomerPurchaseBehaviourReport = 49;
export const GeographicSalesInsightReport = 50;
export const AreaBasedCustomerSegmentationReport = 51;
export const FrequentCustomerPurchasePatternAnalysisReport = 52;
export const ProductSalesPerformanceReport = 53;
export const BrandWiseSalesPerformanceReport = 54;
export const CategoryAndSubCategoryAnalysisReport = 55;
export const RevenueGenerationReport = 56;
export const CommissionAnalysisReport = 57;
export const InventoryStatusReport = 58;
export const InterTransferUmart = 59
export const PreOrderCustomerList = 60;
export const WarehouseModule = 61;
export const WarehouseManger = 62;
export const WarehouseInterTransfer = 63;
//export const Product = 3;
// Modules
// export const All = 0;
// export const Product = 3;
//OrderType
export const ReturnInitiated = 1;
export const PreparingForDispatch = 2;
export const OutForDelivery = 3;
export const CollectToday = 4;
//Inventory Transaction/History
export const ShipmentThroughAdded = 'c1'; //New Stock Updated
export const AddedOnReturn = 'c2'; //Customer return
export const ManuallyAdded = 'c3'; //New Stock Updated
export const DirectlyReceived = 'c4'; //New Stock Updated
export const AddedOnOrderCancellation = 'c5'; //Order modified
export const AddedOnOrderModification = 'c6'; //Order modified
export const AddedOnConversion = 'c7'; //Order modified
export const AddedInterTransferRequest = 'c8';
export const DeductedOnSale = 'd1'; //New order
export const DeductedOnReturnToSeller = 'd2'; //Goods return request
export const ManuallyDeducted = 'd3'; //Missing/Damaged goods
export const DeductedOnMissing = 'd4'; //Missing/Damaged goods
export const DeductedOnDamage = 'd5'; //Missing/Damaged goods
export const DeductedOnOrderModification = 'd6'; //Order modified
export const DeductedOnConversion = 'd7'; //Order modified
export const TransferRequest = 'd8'
//Shipment Status
export const ShipmentSubmitted = 1;
export const ShipmentAccepted = 2;
export const ShipmentCancelled = 3;
export const ShipmentRejected = 4;
export const ShipmentPickedUp = 5;
export const ShipmentDelivered = 6;
export const ShipmentCompleted = 7;
export const ShipmentPickedUpStarted = 8;
//User Types
export const SuperAdmin = 2;
export const Seller = 1;
//Shipment Type
export const SelfTransport = 1;
export const UTradeTransport = 2;
//Shipment Fees
export const WarehouseStorage = 1;
export const Shipping = 2;
//Order Status
export const OrderSubmitted = 1; //New-order-placed
export const OrderProcessed = 2; //Route-planning generated
export const OrderOutForDelivery = 3; //out for delivery
export const OrderDelivered = 4; //Delivered
export const OrderCancelled = 5; //Cancelled
//Tax Types
export const GeneralTax = 1;
export const SystemTax = 2;
//Order Payment method
export const OrderCash = 1;
export const OrderTigoPesa = 2;
export const OrderMPesa = 3;
export const OrderCard = 4;
export const DTBBank = 5;
export const CRDBBank = 6;
export const NMBBank = 7;
export const StanbicBank = 8;
export const AirtelMoney = 9;
export const HaloPesa = 10;
//Google autocomplete apikey
export const APIkey = 'AIzaSyAg41o-JrOGvOov4uDRb9CzL2AyfUMYDbI';
//Route Planning
export const RoutePlanning = 1;
export const RoutePlanned = 2;
export const RoutePlanCompleted = 3;
export const RoutePlanCancelled = 4;
export const RoutePlanFailed = 5;
//Route Plan Vehicle
export const RouteOrdersAssigned = 1;
export const RouteOrderLoadingInitiated = 2;
export const RouteOrderOutForDelivery = 3;
export const RouteOrderDelivered = 4;
export const RouteOrderCancelled = 5; //End-status
//Route Plan Vehicle Order
export const RouteOrderNotDispatched = 1; //In progress
export const RouteOrderDispatched = 2; //Out for delivery
export const RouteVehicleOrderDelivered = 3; //Delivered
export const RouteOrderCustomerNotAvailable = 4; //Customer not available
export const RouteOrderMovedToPendingOrder = 5; //Move to pending
//Route Plan Payment Collection
export const RouteOrderPaymentNONE = 1;
export const RouteOrderPaymentFULL = 2;
//Route Plan Order Loading
export const RouteOrderNotLoaded = 1;
export const RouteOrderLoaded = 2;
//Route Plan Payment Method
export const RouteOrderCash = 1;
export const RouteOrderTigoPesa = 2;
//Order placed by types
export const OrderPlacedByCustomer = 1;
export const OrderPlacedByAdmin = 2;
export const OrderPlacedByAssistance = 3;
//Seller Payment Status
export const SellerPaymentPaid = 1;
export const SellerPaymentUnPaid = 2;
//Seller Payment Method
export const BankPayment = 1;
export const TigoPesaPayment = 2;
// Delivery Payment Method
export const AllPaymentMethod = 0;
export const DeliveryCash = 1;
export const DeliveryTigoPesa = 2;
export const DeliveryBank = 3;
//Notification Type
export const General = 1;
export const Product = 2;
export const Cart = 3;
//Admin Notification Type
export const BuyerContact = 1;
export const SellerContact = 2;
export const MarkRouteAsCompleted = 3;
//Customer Filter Type
export const Registered = 1;
export const NotRegistered = 0;
//Order Queue Status
export const OrderQueueSubmitted = 1; //Added to Queue
export const OrderQueueProcessing = 2; //Processing
export const OrderQueueProcessed = 3; //Processed
export const OrderQueueFailed = 4; //Failed Due to Error

//Transfer Request Status
export const TransferRequestSubmitted = 1;
export const TransferRequestAccepted = 2;
export const TransferRequestCancelled = 3;
export const TransferRequestRejected = 4;
export const TransferRequestPickup = 5;
export const TransferRequestDelivered = 6;
export const TransferRequestCompleted = 7;
export const TransferRequestPickupStarted = 8;

export const warehouseRef = (() => {
    const storedStatus = getKey(warehouse.warehouseRef);
    if (typeof storedStatus === 'string') {
      return JSON.parse(storedStatus); 
    }
    return storedStatus ?? null;
  })();