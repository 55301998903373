import { Button, Card, Col, FormLabel, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  CustomerPurchaseBehaviourReport,
  Download,
  PAGE_LIMIT,
  Reports,
} from "../../../utils/constants";
import APICallService from "../../../api/apiCallService";
import { reports } from "../../../api/apiEndPoints";
import Method from "../../../utils/methods";
import Loader from "../../../Global/loader";
import CustomDatePicker from "../../custom/DateRange/DatePicker";
import { useAuth } from "../auth";
import { getKey } from "../../../Global/history";
import { warehouse as warehouseStoreString } from "../../../utils/storeString";
const PurchaseBehaviourReport = () => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const { state }: any = useLocation();
  const [details, setDetails] = useState<any>();
  const [endDate, setEndDate] = useState<any>(
    new Date(new Date().setDate(new Date().getDate() - 1))
  );
  const [startDate, setStartDate] = useState<any>(
    new Date(new Date().setDate(new Date().getDate() - 1))
  );
  const [fetchLoading, setFetchLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(PAGE_LIMIT);
  const [search, setSearch] = useState<string>("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [paymentData, setPaymentData] = useState<any>([]);
  const [paymentDetails, setPaymentDetails] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      setFetchLoading(true);
      await fetchOrder(startDate, endDate);
      setFetchLoading(false);
    })();
  }, []);
  const fetchOrder = async (startDate: string, endDate: string) => {
    let params: any = {
      fromDate: startDate
        ? Method.convertDateToFormat(startDate, "YYYY-MM-DD")
        : "",
      toDate: endDate ? Method.convertDateToFormat(endDate, "YYYY-MM-DD") : "",
      utcOffset: new Date().getTimezoneOffset(),
    };
    if (tempWarehouse?._id) {
      params.warehouse = tempWarehouse._id;
    }
    let apiService = new APICallService(
      reports.purchaseBehaviourReport,
      params,
      "",
      "",
      "",
      "",
      CustomerPurchaseBehaviourReport,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    if (response) {
      setPaymentData(response);
    }
  };
  const handleChange = async ([startDate, endDate]: any) => {
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate && endDate) {
      setFetchLoading(true);
      setTotalRecords(0);
      await fetchOrder(
        Method.convertDateToFormat(startDate, "YYYY-MM-DD"),
        Method.convertDateToFormat(endDate, "YYYY-MM-DD")
      );
      setFetchLoading(false);
    }
  };
  const handleDownload = async () => {
    let params: any = {
      isReport: true,
      fromDate: startDate
        ? Method.convertDateToFormat(startDate, "YYYY-MM-DD")
        : "",
      toDate: endDate ? Method.convertDateToFormat(endDate, "YYYY-MM-DD") : "",
      utcOffset: new Date().getTimezoneOffset(),
    };
    if (tempWarehouse?._id) {
      params.warehouse = tempWarehouse._id;
    }
    //   if (startDate && endDate) {
    //     params = {
    //       ...params,
    //       fromDate: Method.convertDateToFormat(startDate, 'YYYY-MM-DD'),
    //       toDate: Method.convertDateToFormat(endDate, 'YYYY-MM-DD'),
    //     };
    //   }
    setLoading(true);
    let apiService = new APICallService(
      reports.purchaseBehaviourReport,
      params,
      undefined,
      "blob",
      "",
      "",
      CustomerPurchaseBehaviourReport,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    if (response) {
      const pdfBlob = new Blob([response], { type: "application/pdf" });
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(pdfBlob);
      downloadLink.download =
        "customer_purchase_behaviour_report" +
        Method.convertDateToDDMMYYYY(startDate) +
        "_" +
        Method.convertDateToDDMMYYYY(endDate) +
        ".pdf";
      downloadLink.click();
    } else {
    }
    setLoading(false);
  };
  return (
    <>
      <div className="">
        <Row className="g-4">
          <Col md>
            <div className="d-flex align-items-center mt-4">
              <h1 className="fs-22 fw-bolder">
                Customer Purchase Behaviour Report
              </h1>
            </div>
          </Col>
          <Col md="auto">
            <div className="d-flex align-items-center">
              <FormLabel className="fs-16 fw-500">Filter by dates</FormLabel>
              <div className="ms-5">
                <CustomDatePicker
                  className="form-control bg-white min-h-30px fs-16 fw-bold text-dark min-w-md-288px min-w-175px"
                  onChange={handleChange}
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="dd/MM/yyyy"
                  showFullMonthYearPicker={true}
                  maxDate={
                    new Date(new Date().setDate(new Date().getDate() - 1))
                  }
                  inputTextBG="bg-white"
                />
              </div>
            </div>
          </Col>
          {!fetchLoading && paymentData.length ? (
            <>
              {Method.hasPermission(
                CustomerPurchaseBehaviourReport,
                Download,
                currentUser
              ) ? (
                <Col sm="auto">
                  <Button
                    variant=""
                    size="lg"
                    className="text-primary  bg-light-primary ms-3"
                    onClick={() => handleDownload()}
                    disabled={loading}
                  >
                    {!loading && (
                      <span className="indicator-label">Download report</span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </Button>
                </Col>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </Row>
        <Card className="border border-r10px mt-6">
          <Card.Body className="p-0">
            <div className="table-responsive">
              <table className="table table-row-bordered datatable align-middle gs-7 gy-4 my-0">
                <thead>
                  <tr className="fs-16 fw-600 h-65px align-middle">
                    <th className="min-w-250px">Date</th>
                    <th className="min-w-150px">Customer Name </th>
                    <th className="min-w-600px">Product Name</th>
                  </tr>
                </thead>
                <tbody className="fs-15 fw-600">
                  {fetchLoading ? (
                    <>
                      <tr>
                        <td colSpan={3}>
                          <div className="d-flex justify-content-center">
                            <Loader loading={fetchLoading} />
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {paymentData.length ? (
                        <>
                          {paymentData.map((val: any, index: number) => (
                            <>
                              <tr key={index}>
                                <td>
                                  {Method.checkSameDate(startDate, endDate)
                                    ? `${Method.convertDateToFormat(
                                        startDate,
                                        "DD-MM-YYYY"
                                      )}`
                                    : `${Method.convertDateToFormat(
                                        startDate,
                                        "DD-MM-YYYY"
                                      )} - ${Method.convertDateToFormat(
                                        endDate,
                                        "DD-MM-YYYY"
                                      )}`}
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">
                                    {val.customer.name}{" "}
                                  </span>
                                </td>
                                <td>
                                  <span className="fs-15 fw-600">
                                    {
                                      val.variants.map(
                                        (item: any) => item.title
                                      )[0]
                                    }
                                  </span>
                                </td>
                              </tr>
                              {val.variants.length > 1 ? (
                                <>
                                  {val.variants.map(
                                    (item: any, itemIndex: number) => {
                                      return (
                                        <>
                                          {itemIndex > 0 ? (
                                            <tr key={itemIndex}>
                                              <td></td>
                                              <td></td>
                                              <td>
                                                <span className="fs-15 fw-600">
                                                  {" "}
                                                  {item.title}{" "}
                                                </span>
                                              </td>
                                            </tr>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </>
                      ) : (
                        <tr>
                          <td colSpan={3}>
                            <div className="w-100 fs-15 fw-bold d-flex justify-content-center">
                              No Data found
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
export default PurchaseBehaviourReport;
