import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { CustomSelectTable2 } from '../../custom/Select/custom-select-table';
import clsx from 'clsx';
import AutoComplete from '../../custom/autoComplete';
import { AllModules, APIkey, WarehouseManger } from '../../../utils/constants';
import Validations from '../../../utils/validations';
import APICallService from '../../../api/apiCallService';
import { multipleWarehouse } from '../../../api/apiEndPoints';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../../Global/loader';
import { success } from '../../../Global/toast';
import { warehouse as warehouseStoreString } from '../../../utils/storeString';
import { getKey } from '../../../Global/history';
const EditWarehouse = () => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [fetchLoading, setFetchLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [warehouseData, setWarehouseData] = useState<any>({
    name: '',
    phone: '',
    address: '',
    lat: '',
    lng: '',
    email: '',
    districts: [],
    managers: [],
    loadingArea: [],
    discount1: '',
    discount2: '',
    roundValue: '',
  });
  const [validations, setValidations] = useState<any>({
    name: false,
    phone: false,
    address: false,
    email: false,
    districts: false,
    managers: false,
    loadingArea: false,
    discount1: false,
    discount2: false,
    roundValue: false,
  });
  useEffect(() => {
    (async () => {
      if (!state) {
        return window.history.back();
      }
      await fetchWarehouse();
    })();
  }, []);
  const fetchWarehouse = async () => {
    setFetchLoading(true);
    const apiService = new APICallService(
      multipleWarehouse.warehouseInfo,
      state?.id,
      '',
      '',
      '',
      '',
      WarehouseManger,
      tempWarehouse?._id
    );
    const response = await apiService.callAPI();
    let data: any = [];
    if (response) {
      // setWarehouseList(response.records);
      setWarehouseData({
        name: response.name || '',
        phone: response.phone || '',
        address: response.address || '',
        lat: response.lat || '',
        long: response.lng || '',
        email: response?.email || '',
        roundValue: response?.roundValue + '' || '',
        discount1: response?.discountType == 1 ? response.discount + '' : '',
        discount2: response?.discountType == 2 ? response.discount + '' : '',
        districts: response.district.length
          ? response?.district.map((val: any) => {
              return {
                value: val.reference,
                label: val.name,
                title: val.name,
              };
            })
          : [],
        managers: response.manager.length
          ? response?.manager.map((val: any) => {
              return {
                value: val.reference,
                label: val.name,
                title: val.name,
              };
            })
          : [],
      });
    }
    setFetchLoading(false);
  };
  const handleChange = (value: any, name: keyof any) => {
    const temp = { ...warehouseData };
    const tempValidation: any = { ...validations };
    temp[name] = value;
    if (name === 'discount1' || name === 'discount2') {
      tempValidation['discount1'] = false;
      tempValidation['discount2'] = false;
    } else {
      tempValidation[name] = value.trim().length === 0;
    }
    if (name == 'discount1') {
      temp['discount2'] = '';
    }
    if (name == 'discount2') {
      temp['discount1'] = '';
    }
    setValidations(tempValidation);
    setWarehouseData(temp);
  };
  const handleManagerChange = (data: any) => {
    const tempValidation = { ...validations };
    setWarehouseData((prevData: any) => ({
      ...prevData,
      managers: data,
    }));
    if (data.length) {
      tempValidation.managers = false;
    } else {
      tempValidation.managers = true;
    }
    setValidations(tempValidation);
  };
  const handleDistrictChange = (data: any) => {
    const tempValidation = { ...validations };
    setWarehouseData((prevData: any) => ({
      ...prevData,
      districts: data,
    }));
    if (data.length) {
      tempValidation.districts = false;
    } else {
      tempValidation.districts = true;
    }
    setValidations(tempValidation);
  };
  const handleLoadingAreaChange = (data: any) => {
    const tempValidation = { ...validations };
    setWarehouseData((prevData: any) => ({
      ...prevData,
      loadingArea: data,
    }));
    if (data.length) {
      tempValidation.loadingArea = false;
    } else {
      tempValidation.loadingArea = true;
    }
    setValidations(tempValidation);
  };
  const handleAddressChange = async (value: string, lat: any, lng: any) => {
    let tempValidation: any = { ...validations };
    let temp: any = { ...warehouseData };
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${APIkey}`;
    await fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'OK') {
          console.log(
            'Geocode API response:',
            data.results[0]?.formatted_address
          );
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    temp['address'] = value.trimStart();
    temp['lat'] = lat;
    temp['lng'] = lng;
    tempValidation['address'] = !value.length;
    setWarehouseData(temp);
    setValidations(tempValidation);
  };
  const handleSave = async () => {
    const tempValidation = { ...validations };
    tempValidation.name = warehouseData.name.trim().length === 0;
    // tempValidation.email = warehouseData.email.trim().length === 0;
    tempValidation.phone = warehouseData.phone.trim().length === 0;
    tempValidation.address = warehouseData.address.trim().length === 0;
    tempValidation.email = warehouseData.email.trim().length === 0;
    tempValidation.roundValue = warehouseData.roundValue.trim().length === 0;
    tempValidation.discount1 =
      warehouseData.discount1.trim().length === 0 &&
      warehouseData.discount2.trim().length === 0;
    tempValidation.discount2 =
      warehouseData.discount1.trim().length === 0 &&
      warehouseData.discount2.trim().length === 0;
    const isValid = await Validations.validateObject(tempValidation);
    if (isValid) {
      setLoading(true);
      const params = {
        name: warehouseData.name,
        phone: warehouseData.phone,
        phoneCountry: '+255',
        address: warehouseData.address,
        lng: warehouseData.long,
        lat: warehouseData.lat,
        email: warehouseData.email,
        roundValue: warehouseData.roundValue,
        discount: warehouseData.discount1.length
          ? warehouseData.discount1
          : warehouseData.discount2,
        discountType: warehouseData.discount1.length ? 1 : 2,
      };
      const apiService = new APICallService(
        multipleWarehouse.updateWarehouse,
        params,
        { id: state?.id },
        '',
        '',
        '',
        WarehouseManger,
        tempWarehouse?._id
      );
      const response = await apiService.callAPI();
      if (response) {
        success('Warehouse updated successfully!');
        navigate('/warehouse/all-warehouse');
      }
      setLoading(false);
    }
    setValidations(tempValidation);
  };
  return (
    <div className="p-9">
      <Row className="align-items-center">
        <Col
          xs={12}
          className="mb-5"
        >
          <h1 className="fs-22 fw-bolder">Edit warehouse</h1>
        </Col>
        {!fetchLoading ? (
          <>
            <Col xs={12}>
              <Card className="bg-light border mb-7">
                <Card.Body className="px-7">
                  <Row className="gx-lg-9 gx-6 gy-6">
                    <Col md={6}>
                      <Row className="gy-2 align-items-center">
                        <Col sm={4}>
                          <label
                            htmlFor=""
                            className="fs-16 fw-500"
                          >
                            Name
                          </label>
                        </Col>
                        <Col sm={8}>
                          <Form.Control
                            className={clsx(
                              'form-control-custom bg-white h-60px',
                              {
                                'border-danger': validations.name,
                              }
                            )}
                            type="text"
                            placeholder="Type here..."
                            name="name"
                            value={warehouseData.name}
                            onChange={(e: any) =>
                              handleChange(e.target.value, 'name')
                            }
                          />
                          {/* {validation.name && (
                        <div className="text-danger fs-12 fw-bold">
                          Please enter a warehouse name
                        </div>
                      )} */}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row className="gy-2 align-items-center">
                        <Col sm={4}>
                          <label
                            htmlFor=""
                            className="fs-16 fw-500"
                          >
                            Warehouse Contact number
                          </label>
                        </Col>
                        <Col sm={8}>
                          <InputGroup
                            size="lg"
                            className={`${
                              validations.phone
                                ? 'border border-1 border-danger rounded-3'
                                : ''
                            }`}
                          >
                            <InputGroup.Text id="inputGroup-sizing-lg">
                              +255
                            </InputGroup.Text>
                            <Form.Control
                              className={clsx(
                                'form-control-custom bg-white h-60px'
                              )}
                              type="number"
                              placeholder="Type here…"
                              name="phone"
                              value={warehouseData.phone}
                              onChange={(e: any) =>
                                handleChange(e.target.value, 'phone')
                              }
                            />
                          </InputGroup>
                          {/* {validation.phone && (
                        <div className="text-danger fs-12 fw-bold">
                          Please enter a warehouse phone number
                        </div>
                      )} */}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row className="gy-2 align-items-center">
                        <Col sm={4}>
                          <label
                            htmlFor=""
                            className="fs-16 fw-500"
                          >
                            Address
                          </label>
                        </Col>
                        <Col sm={8}>
                          {/* <Form.Control
                      className={clsx('form-control-custom bg-white h-60px', {
                        'border-danger': validations.address,
                      })}
                        type="text"
                        placeholder="Type here..."
                        name="address"
                        // value={warehouseData.address}
                        onChange={(e:any)=> handleChange(e.target.value,'address')}
                      /> */}
                          {/* {validation.address && (
                        <div className="text-danger fs-12 fw-bold">
                          Please enter an warehouse address
                        </div>
                      )} */}
                          <AutoComplete
                            address={warehouseData.address}
                            handleAddressChange={handleAddressChange}
                            lat={warehouseData.lat}
                            lng={warehouseData.lng}
                            border={clsx(validations.address ? '#e55451' : '')}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row className="gy-2 align-items-center">
                        <Col sm={4}>
                          <label
                            htmlFor=""
                            className="fs-16 fw-500"
                          >
                            Email
                          </label>
                        </Col>
                        <Col sm={8}>
                          <Form.Control
                            className={clsx(
                              'form-control-custom bg-white h-60px',
                              {
                                'border-danger': validations.email,
                              }
                            )}
                            type="text"
                            placeholder="Type here..."
                            name="email"
                            value={warehouseData.email}
                            onChange={(e: any) =>
                              handleChange(e.target.value, 'email')
                            }
                          />
                          {/* {validation.name && (
                        <div className="text-danger fs-12 fw-bold">
                          Please enter a warehouse name
                        </div>
                      )} */}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12}>
                      <Row className="gy-2 align-items-center">
                        <Col sm={2}>
                          <label
                            htmlFor=""
                            className="fs-16 fw-500"
                          >
                            Assign districts
                          </label>
                        </Col>
                        <Col sm={10}>
                          <CustomSelectTable2
                            border={clsx(
                              validations.districts ? '#e55451' : ''
                            )}
                            backgroundColor="white"
                            multiValueBackground="#e7f1fd"
                            indicatorDisplay="none"
                            display="none"
                            isMulti={true}
                            menuIsOpen={false}
                            controlFontSize="1.077rem"
                            minHieight="60px"
                            options={
                              warehouseData.districts
                              // district.length
                              //   ? district.map((district: any) => ({
                              //       label: district.name,
                              //       value: district._id,
                              //     }))
                              //   : []
                            }
                            value={warehouseData.districts}
                            onChange={handleDistrictChange}
                            isDisabled={true}
                          />
                          {/* {validation.districts && (
                        <div className="text-danger fs-12 fw-bold">
                          Please select a districts
                        </div>
                      )} */}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12}>
                      <Row className="gy-2 align-items-center">
                        <Col sm={2}>
                          <label
                            htmlFor=""
                            className="fs-16 fw-500"
                          >
                            Assign Managers
                          </label>
                        </Col>
                        <Col sm={10}>
                          <CustomSelectTable2
                            border={clsx(validations.managers ? '#e55451' : '')}
                            backgroundColor="white"
                            multiValueBackground="#e7f1fd"
                            indicatorDisplay="none"
                            display="none"
                            isMulti={true}
                            menuIsOpen={false}
                            controlFontSize="1.077rem"
                            minHieight="60px"
                            options={warehouseData.managers}
                            value={warehouseData.managers}
                            onChange={handleManagerChange}
                            isDisabled={true}
                          />
                          {/* {validation.managers && (
                        <div className="text-danger fs-12 fw-bold">
                          Please select warehouse managers
                        </div>
                      )} */}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row className="gy-2 align-items-center">
                        <Col sm={4}>
                          <label
                            htmlFor=""
                            className="fs-16 fw-500"
                          >
                            Discount(+)
                          </label>
                        </Col>
                        <Col sm={8}>
                          <Form.Control
                            className={clsx(
                              'form-control-custom bg-white h-60px',
                              {
                                ' border border-1 border-danger':
                                  validations.discount1,
                              }
                            )}
                            type="number"
                            placeholder="Type here..."
                            name="name"
                            value={warehouseData.discount1}
                            onChange={(e: any) =>
                              handleChange(e.target.value, 'discount1')
                            }
                            // disabled={isDiscount1Disabled}
                          />
                          {/* {validation.name && (
                        <div className="text-danger fs-12 fw-bold">
                          Please enter a warehouse name
                        </div>
                      )} */}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row className="gy-2 align-items-center">
                        <Col sm={4}>
                          <label
                            htmlFor=""
                            className="fs-16 fw-500"
                          >
                            Discount(-)
                          </label>
                        </Col>
                        <Col sm={8}>
                          <Form.Control
                            className={clsx(
                              'form-control-custom bg-white h-60px',
                              {
                                ' border border-1 border-danger':
                                  validations.discount2,
                              }
                            )}
                            type="number"
                            placeholder="Type here..."
                            name="name"
                            value={warehouseData.discount2}
                            onChange={(e: any) =>
                              handleChange(e.target.value, 'discount2')
                            }
                            // disabled={isDiscount2Disabled}
                          />
                          {/* {validation.name && (
                        <div className="text-danger fs-12 fw-bold">
                          Please enter a warehouse name
                        </div>
                      )} */}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12}>
                      <Row className="gy-2 align-items-center">
                        <Col sm={2}>
                          <label
                            htmlFor=""
                            className="fs-16 fw-500"
                          >
                            Rounded Value
                          </label>
                        </Col>
                        <Col sm={10}>
                          <Form.Control
                            className={clsx(
                              'form-control-custom bg-white h-60px',
                              {
                                ' border border-1 border-danger':
                                  validations.roundValue,
                              }
                            )}
                            type="number"
                            placeholder="Type here..."
                            name="name"
                            value={warehouseData.roundValue}
                            onChange={(e: any) =>
                              handleChange(e.target.value, 'roundValue')
                            }
                          />
                          {/* {validation.name && (
                        <div className="text-danger fs-12 fw-bold">
                          Please enter a warehouse name
                        </div>
                      )} */}
                        </Col>
                      </Row>
                    </Col>
                    {/* <Col md={12}>
                      <Row className="gy-2 align-items-center">
                        <Col sm={2}>
                          <label
                            htmlFor=""
                            className="fs-16 fw-500"
                          >
                            Add loading areas
                          </label>
                        </Col>
                        <Col sm={10}>
                          <CustomSelectTable2
                            border={clsx(
                              validations.loadingArea ? '#e55451' : ''
                            )}
                            backgroundColor="white"
                            multiValueBackground="#e7f1fd"
                            indicatorDisplay="none"
                            display="none"
                            isMulti={true}
                            menuIsOpen={false}
                            controlFontSize="1.077rem"
                            minHieight="60px"
                            options={
                              [
                                {
                                  label: 'Loading Area 1',
                                  value: 1,
                                },
                                {
                                  label: 'Loading Area 2',
                                  value: 2,
                                },
                              ]
                              // loadingArea.length
                              //   ? loadingArea.map((val: any) => ({
                              //       label: val.name,
                              //       value: val._id,
                              //     }))
                              //   : []
                            }
                            onChange={handleLoadingAreaChange}
                          />
                        </Col>
                      </Row>
                    </Col> */}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Row>
                <Col sm={3}>
                  <Button
                    disabled={loading}
                    onClick={handleSave}
                  >
                    {!loading && (
                      <span className="indicator-label">Save Details</span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: 'block' }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </Button>
                </Col>
              </Row>
            </Col>{' '}
          </>
        ) : (
          <Col>
            <div className="d-flex justify-content-center">
              <Loader loading={fetchLoading} />
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default EditWarehouse;
