import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { CustomSelectTable2 } from '../../custom/Select/custom-select-table';
import clsx from 'clsx';
import AutoComplete from '../../custom/autoComplete';
import {
  AllModules,
  APIkey,
  CustomerServices,
  VehicleLoadingArea,
  WarehouseManger,
  warehouseRef,
} from '../../../utils/constants';
import APICallService from '../../../api/apiCallService';
import {
  customerService,
  master,
  multipleWarehouse,
} from '../../../api/apiEndPoints';
import { multipleWarehouseJSON } from '../../../api/apiJSON/multipleWarehouse';
import { success } from '../../../Global/toast';
import { useNavigate } from 'react-router-dom';
import Method from '../../../utils/methods';
import Validations from '../../../utils/validations';
import { getKey } from '../../../Global/history';
import {
  warehouse,
  warehouse as warehouseStoreString,
} from '../../../utils/storeString';
const CreateWarehouse = () => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [warehouseData, setWarehouseData] = useState<any>({
    name: '',
    email: '',
    phone: '',
    phoneCountry: '+255',
    address: '',
    lat: '',
    lng: '',
    district: [],
    manager: [],
    discount1: '',
    discount2: '',
    roundValue: '',
    // loadingArea: [],
  });
  const [validations, setValidations] = useState<any>({
    name: false,
    email: false,
    phone: false,
    address: false,
    districts: false,
    managers: false,
    discount1: false,
    discount2: false,
    roundValue: false,
    // loadingArea: false,
  });
  const [districts, setDistricts] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [loadingAreas, setLoadingAreas] = useState<any>([]);
  useEffect(() => {
    fetchDistrict();
    fetchUser();
  }, []);
  const fetchDistrict = async () => {
    let params: any = {
      needCount: true,
    };
    if (tempWarehouse?._id) {
      params.warehouse = tempWarehouse._id;
    }
    let apiService = new APICallService(
      customerService.listDistrict,
      params,
      '',
      '',
      '',
      '',
      WarehouseManger,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    let data: any = [...districts];
    if (response.records.length) {
      response.records.map((val: any) => {
        data.push({
          value: val._id,
          label: val.name,
          title: val.name,
        });
      });
    }
    setDistricts(data);
  };
  // const fetchLoadingArea = async () => {
  //   // setLoading(true);
  //   let params = {
  //     needCount: true,
  //     warehouse: warehouseRef.reference
  //   }
  //   let apiService = new APICallService(
  //     master.listArea,params,
  //     '','','','',''
  //   );
  //   let response = await apiService.callAPI();
  //   let data: any = [...loadingAreas];
  //   if(response.records.length){
  //     response.records.map((val: any) => {
  //       data.push({
  //         value: val._id,
  //         label: val.name,
  //         title: val.name
  //       });
  //     });
  // }
  //   setLoadingAreas(data);
  // };
  const handleChange = (value: any, name: keyof any) => {
    const temp = { ...warehouseData };
    const tempValidation: any = { ...validations };
    temp[name] = value;
    if (name === 'discount1' || name === 'discount2') {
      tempValidation['discount1'] = false;
      tempValidation['discount2'] = false;
    } else {
      tempValidation[name] = value.trim().length === 0;
    }
    setValidations(tempValidation);
    setWarehouseData(temp);
  };
  const handleManagerChange = (data: any) => {
    const tempValidation = { ...validations };
    setWarehouseData((prevData: any) => ({
      ...prevData,
      manager: data,
    }));
    if (data.length) {
      tempValidation.managers = false;
    } else {
      tempValidation.managers = true;
    }
    setValidations(tempValidation);
  };
  const handleDistrictChange = (data: any) => {
    const tempValidation = { ...validations };
    setWarehouseData((prevData: any) => ({
      ...prevData,
      district: data,
    }));
    if (data.length) {
      tempValidation.districts = false;
    } else {
      tempValidation.districts = true;
    }
    setValidations(tempValidation);
  };
  // const handleLoadingAreaChange = (data: any) => {
  //   const tempValidation = { ...validations };
  //   console.log("tempValidation", tempValidation);
  //   setWarehouseData((prevData: any) => ({
  //     ...prevData,
  //     loadingArea: data,
  //   }));
  //   if (data.length) {
  //     tempValidation.loadingArea = false;
  //   } else {
  //     tempValidation.loadingArea = true;
  //   }
  //   setValidations(tempValidation);
  // };
  const handleAddressChange = async (value: string, lat: any, lng: any) => {
    let tempValidation: any = { ...validations };
    let temp: any = { ...warehouseData };
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${APIkey}`;
    await fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'OK') {
          console.log(
            'Geocode API response:',
            data.results[0]?.formatted_address
          );
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    temp['address'] = value.trimStart();
    temp['lat'] = lat;
    temp['lng'] = lng;
    tempValidation['address'] = !value.length;
    setWarehouseData(temp);
    setValidations(tempValidation);
  };
  const fetchUser = async () => {
    setLoading(true);
    const params: any = {
      sortKey: 'createdAt',
      sortOrder: -1,
      needCount: true,
      warehouseManager: 2,
    };
    let apiService = new APICallService(
      master.getUsers,
      params,
      '',
      '',
      '',
      '',
      WarehouseManger,
      tempWarehouse?._id
    );
    let response = await apiService.callAPI();
    let data: any = [...users];
    if (response.records.length) {
      response.records.map((val: any) => {
        data.push({
          value: val._id,
          label: val.name,
          title: val.name,
        });
      });
    }
    setUsers(data);
    setLoading(false);
  };
  const handleSave = async () => {
    const tempValidation = { ...validations };
    tempValidation.name = warehouseData.name.trim().length === 0;
    tempValidation.email = warehouseData.email.trim().length === 0;
    tempValidation.phone = warehouseData.phone.trim().length === 0;
    tempValidation.address = warehouseData.address.trim().length === 0;
    tempValidation.districts = warehouseData.district.length === 0;
    tempValidation.managers = warehouseData.manager.length === 0;
    tempValidation.roundValue = warehouseData.roundValue.trim().length === 0;
    tempValidation.discount1 =
      warehouseData.discount1.trim().length === 0 &&
      warehouseData.discount2.trim().length === 0;
    tempValidation.discount2 =
      warehouseData.discount1.trim().length === 0 &&
      warehouseData.discount2.trim().length === 0;
    const isValid = await Validations.validateObject(tempValidation);
    if (isValid) {
      setIsSubmitting(true);
      const apiService = new APICallService(
        multipleWarehouse.addWarehouse,
        multipleWarehouseJSON.addWarehouse(warehouseData),
        '',
        '',
        '',
        '',
        WarehouseManger,
        tempWarehouse?._id
      );
      const response = await apiService.callAPI();
      if (response) {
        success('Warehouse created successfully!');
        navigate('/warehouse/all-warehouse');
      }
      setIsSubmitting(false);
    }
    setValidations(tempValidation);
  };
  const isDiscount1Disabled = warehouseData.discount2.trim().length > 0;
  const isDiscount2Disabled = warehouseData.discount1.trim().length > 0;
  return (
    <div className="p-9">
      <Row className="align-items-center">
        <Col
          xs={12}
          className="mb-5"
        >
          <h1 className="fs-22 fw-bolder">Add new warehouse</h1>
        </Col>
        <Col xs={12}>
          <Card className="bg-light border mb-7">
            <Card.Body className="px-7">
              <Row className="gx-lg-9 gx-6 gy-6">
                <Col md={6}>
                  <Row className="gy-2 align-items-center">
                    <Col sm={4}>
                      <label
                        htmlFor=""
                        className="fs-16 fw-500"
                      >
                        Name
                      </label>
                    </Col>
                    <Col sm={8}>
                      <Form.Control
                        className={clsx('form-control-custom bg-white h-60px', {
                          ' border border-1 border-danger': validations.name,
                        })}
                        type="text"
                        placeholder="Type here..."
                        name="name"
                        value={warehouseData.name}
                        onChange={(e: any) =>
                          handleChange(e.target.value, 'name')
                        }
                      />
                      {/* {validation.name && (
                        <div className="text-danger fs-12 fw-bold">
                          Please enter a warehouse name
                        </div>
                      )} */}
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="gy-2 align-items-center">
                    <Col sm={4}>
                      <label
                        htmlFor=""
                        className="fs-16 fw-500"
                      >
                        Warehouse Contact number
                      </label>
                    </Col>
                    <Col sm={8}>
                      <InputGroup
                        size="lg"
                        className={`${
                          validations.phone
                            ? 'border border-1 border-danger rounded-3'
                            : ''
                        }`}
                      >
                        <InputGroup.Text id="inputGroup-sizing-lg">
                          +255
                        </InputGroup.Text>
                        <Form.Control
                          className={clsx(
                            'form-control-custom bg-white h-60px'
                          )}
                          type="number"
                          placeholder="Type here…"
                          name="phone"
                          value={warehouseData.phone}
                          onChange={(e: any) =>
                            handleChange(e.target.value, 'phone')
                          }
                        />
                      </InputGroup>
                      {/* {validation.phone && (
                        <div className="text-danger fs-12 fw-bold">
                          Please enter a warehouse phone number
                        </div>
                      )} */}
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="gy-2 align-items-center">
                    <Col sm={4}>
                      <label
                        htmlFor=""
                        className="fs-16 fw-500"
                      >
                        Address
                      </label>
                    </Col>
                    <Col sm={8}>
                      <AutoComplete
                        address={warehouseData.address}
                        handleAddressChange={handleAddressChange}
                        lat={warehouseData.lat}
                        lng={warehouseData.lng}
                        border={clsx(validations.address ? '#e55451' : '')}
                      />
                      {/* {validations.address && (
                        <div className="text-danger fs-12 fw-bold">
                          Please enter a warehouse address
                        </div>
                      )} */}
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="gy-2 align-items-center">
                    <Col sm={4}>
                      <label
                        htmlFor=""
                        className="fs-16 fw-500"
                      >
                        Email
                      </label>
                    </Col>
                    <Col sm={8}>
                      <Form.Control
                        className={clsx('form-control-custom bg-white h-60px', {
                          'border-danger': validations.email,
                        })}
                        type="text"
                        placeholder="Type here..."
                        name="email"
                        value={warehouseData.email}
                        onChange={(e: any) =>
                          handleChange(e.target.value, 'email')
                        }
                      />
                      {/* {validations.email && (
                        <div className="text-danger fs-12 fw-bold">
                          Please enter a valid email address
                        </div>
                      )} */}
                    </Col>
                  </Row>
                </Col>
                <Col md={12}>
                  <Row className="gy-2 align-items-center">
                    <Col sm={2}>
                      <label
                        htmlFor=""
                        className="fs-16 fw-500"
                      >
                        Assign districts
                      </label>
                    </Col>
                    <Col sm={10}>
                      <CustomSelectTable2
                        border={clsx(validations.districts ? '#e55451' : '')}
                        backgroundColor="white"
                        multiValueBackground="#e7f1fd"
                        indicatorDisplay="none"
                        display="none"
                        isMulti={true}
                        menuIsOpen={false}
                        controlFontSize="1.077rem"
                        minHieight="60px"
                        options={
                          districts
                          // district.length
                          //   ? district.map((district: any) => ({
                          //       label: district.name,
                          //       value: district._id,
                          //     }))
                          //   : []
                        }
                        onChange={handleDistrictChange}
                      />
                      {/* {validation.districts && (
                        <div className="text-danger fs-12 fw-bold">
                          Please select a districts
                        </div>
                      )} */}
                    </Col>
                  </Row>
                </Col>
                <Col md={12}>
                  <Row className="gy-2 align-items-center">
                    <Col sm={2}>
                      <label
                        htmlFor=""
                        className="fs-16 fw-500"
                      >
                        Assign Managers
                      </label>
                    </Col>
                    <Col sm={10}>
                      <CustomSelectTable2
                        border={clsx(validations.managers ? '#e55451' : '')}
                        backgroundColor="white"
                        multiValueBackground="#e7f1fd"
                        indicatorDisplay="none"
                        display="none"
                        isMulti={true}
                        menuIsOpen={false}
                        controlFontSize="1.077rem"
                        minHieight="60px"
                        options={users}
                        onChange={handleManagerChange}
                      />
                      {/* {validation.managers && (
                        <div className="text-danger fs-12 fw-bold">
                          Please select warehouse managers
                        </div>
                      )} */}
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="gy-2 align-items-center">
                    <Col sm={4}>
                      <label
                        htmlFor=""
                        className="fs-16 fw-500"
                      >
                        Discount(+)
                      </label>
                    </Col>
                    <Col sm={8}>
                      <Form.Control
                        className={clsx('form-control-custom bg-white h-60px', {
                          ' border border-1 border-danger':
                            validations.discount1,
                        })}
                        type="number"
                        placeholder="Type here..."
                        name="name"
                        value={warehouseData.discount1}
                        onChange={(e: any) =>
                          handleChange(e.target.value, 'discount1')
                        }
                        disabled={isDiscount1Disabled}
                      />
                      {/* {validation.name && (
                        <div className="text-danger fs-12 fw-bold">
                          Please enter a warehouse name
                        </div>
                      )} */}
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className="gy-2 align-items-center">
                    <Col sm={4}>
                      <label
                        htmlFor=""
                        className="fs-16 fw-500"
                      >
                        Discount(-)
                      </label>
                    </Col>
                    <Col sm={8}>
                      <Form.Control
                        className={clsx('form-control-custom bg-white h-60px', {
                          ' border border-1 border-danger':
                            validations.discount2,
                        })}
                        type="number"
                        placeholder="Type here..."
                        name="name"
                        value={warehouseData.discount2}
                        onChange={(e: any) =>
                          handleChange(e.target.value, 'discount2')
                        }
                        disabled={isDiscount2Disabled}
                      />
                      {/* {validation.name && (
                        <div className="text-danger fs-12 fw-bold">
                          Please enter a warehouse name
                        </div>
                      )} */}
                    </Col>
                  </Row>
                </Col>
                <Col md={12}>
                  <Row className="gy-2 align-items-center">
                    <Col sm={2}>
                      <label
                        htmlFor=""
                        className="fs-16 fw-500"
                      >
                        Rounded Value
                      </label>
                    </Col>
                    <Col sm={10}>
                      <Form.Control
                        className={clsx('form-control-custom bg-white h-60px', {
                          ' border border-1 border-danger':
                            validations.roundValue,
                        })}
                        type="number"
                        placeholder="Type here..."
                        name="name"
                        value={warehouseData.roundValue}
                        onChange={(e: any) =>
                          handleChange(e.target.value, 'roundValue')
                        }
                      />
                      {/* {validation.name && (
                        <div className="text-danger fs-12 fw-bold">
                          Please enter a warehouse name
                        </div>
                      )} */}
                    </Col>
                  </Row>
                </Col>
                {/* <Col md={12}>
                  <Row className="gy-2 align-items-center">
                    <Col sm={2}>
                      <label
                        htmlFor=""
                        className="fs-16 fw-500"
                      >
                        Add loading areas
                      </label>
                    </Col>
                    <Col sm={10}>
                      <CustomSelectTable2
                        border={clsx(validations.loadingArea ? "#e55451" : "")}
                        backgroundColor="white"
                        multiValueBackground="#e7f1fd"
                        indicatorDisplay="none"
                        display="none"
                        isMulti={true}
                        menuIsOpen={false}
                        controlFontSize="1.077rem"
                        minHieight="60px"
                        options={loadingAreas}
                        onChange={handleLoadingAreaChange}
                      />
                    </Col>
                  </Row>
                </Col> */}
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Row>
            <Col sm={3}>
              <Button
                disabled={isSubmitting}
                onClick={handleSave}
              >
                {!isSubmitting && (
                  <span className="indicator-label">Save Details</span>
                )}
                {isSubmitting && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default CreateWarehouse;
