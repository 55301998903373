import React, { useEffect, useState } from "react";
import { Button, Card, Col, Nav, Row, Tab } from "react-bootstrap";
import Pagination from "../../../../Global/pagination";
import { useNavigate } from "react-router-dom";
import {
  interTransferRequest,
  multipleWarehouse,
} from "../../../../api/apiEndPoints";
import {
  Add,
  Admin,
  AllModules,
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  PAGE_LIMIT,
  Piece,
  TransferRequestAccepted,
  TransferRequestSubmitted,
  WarehouseInterTransfer,
} from "../../../../utils/constants";
import APICallService from "../../../../api/apiCallService";
import Loader from "../../../../Global/loader";
import { useAuth } from "../../auth";
import Method from "../../../../utils/methods";
import ViewRequestedGoodsProducts from "../../../modals/view-requested-goods-products";
import { getKey } from "../../../../Global/history";
import { warehouse as warehouseStoreString } from "../../../../utils/storeString";
const InterTransferRequest = () => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const navigate = useNavigate();
  const [fetchLoader, setFetchLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [warehouseList, setWarehouseList] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState<any>(1);
  const [pageLimit, setPageLimit] = useState<any>(PAGE_LIMIT);
  const { currentUser }: any = useAuth();
  const [requestList, setRequestList] = useState<any>([]);
  const [showProductModal, setShowProductModal] = useState<any>(false);
  const [currentData, setCurrentData] = useState<any>();
  const warehouseName = [
    { id: 1, title: "Kigamboni Warehouse", status: "In progress" },
    { id: 2, title: "Ilala Warehouse", status: "In progress" },
    { id: 3, title: "Kinondoni Warehouse", status: "Out for Delivery" },
    { id: 4, title: "Temeke Warehouse", status: "In progress" },
    { id: 5, title: "Ubungo Warehouse", status: "Out for Delivery" },
    { id: 6, title: "Kinondoni Warehouse", status: "In progress" },
    { id: 7, title: "Kigamboni Warehouse", status: "In progress" },
    { id: 8, title: "Ubungo Warehouse", status: "Out for Delivery" },
    { id: 9, title: "Kinongoni Warehouse", status: "In progress" },
    { id: 10, title: "Ubungo Warehouse", status: "Out for Delivery" },
  ];
  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      await fetchWarehouse();
      setFetchLoader(false);
    })();
  }, []);
  const fetchTransferRequests = async (
    page: number,
    limit: number,
    tab: number,
    warehouse?: any
  ) => {
    let params: any = {
      pageNo: page,
      limit: limit,
      sortOrder: -1,
      sortKey: "_createdAt",
      needCount: true,
      warehouseTransferRequest: true,
    };
    if (warehouse) {
      params.warehouseId = warehouse;
    }
    if (tab == 1) {
      // params.status = [1];
      params = {
        ...params,
        "status[0]": 1,
      };
    } else if (tab == 2) {
      params = {
        ...params,
        "status[0]": 2,
        "status[1]": 6,
        "status[2]": 5,
        "status[3]": 8,
      };
    } else if (tab == 3) {
      params = {
        ...params,
        "status[0]": 7,
      };
    } else if (tab == 4) {
      params = {
        ...params,
        "status[0]": 3,
      };
    } else if (tab == 5) {
      params = {
        ...params,
        "status[0]": 4,
      };
    }
    if (tempWarehouse?._id) {
      params.warehouse = tempWarehouse?._id;
    }

    setLoading(true);
    const apiService = new APICallService(
      interTransferRequest.listTransferRequest,
      params,
      "",
      "",
      "",
      "",
      WarehouseInterTransfer,
      tempWarehouse?._id
    );
    const response = await apiService.callAPI();
    if (response) {
      setRequestList(response.records);
      setTotalRecords(response.total);
    }
    setLoading(false);
  };
  const fetchWarehouse = async () => {
    let params: any = {
      sortKey: "name",
      sortOrder: 1,
      // needCount: true,
    };
    if (tempWarehouse?._id) {
      params.warehouse = tempWarehouse?._id;
    }
    setFetchLoader(true);
    const apiService = new APICallService(
      multipleWarehouse.listWarehouse,
      params,
      "",
      "",
      "",
      "",
      WarehouseInterTransfer,
      tempWarehouse?._id
    );
    const response = await apiService.callAPI();
    let data: any = [];
    if (response) {
      const temp = response?.records.map((item: any) => {
        return {
          ...item,
          title: item?.name || "",
        };
      });
      setWarehouseList(temp);
      if (temp.length) {
        setSelectedWarehouse(temp[0]._id);
      }
      await fetchTransferRequests(
        page,
        pageLimit,
        activeTab,
        currentUser?.userType == Admin
          ? temp[0]?._id || ""
          : currentUser?.warehouse?._id
      );
      // setTotalRecords(response.total);
    }
    setFetchLoader(false);
  };
  const handleWarehouseClick = (id: any) => {
    setSelectedWarehouse(id);
  };
  const handleCurrentPage = async (val: number) => {
    if (val === page || val.toString() === "...") return;
    setLoading(true);
    setPage(val);
    // setKey(interTransferList.page, val);
    await fetchTransferRequests(
      val,
      pageLimit,
      activeTab,
      currentUser?.userType == Admin
        ? selectedWarehouse
        : currentUser?.warehouse?._id
    );
    setLoading(false);
  };
  const handleNextPage = async (val: number) => {
    setLoading(true);
    setPage(val + 1);
    // setKey(interTransferList.page, val + 1);
    await fetchTransferRequests(
      val + 1,
      pageLimit,
      activeTab,
      currentUser?.userType == Admin
        ? selectedWarehouse
        : currentUser?.warehouse?._id
    );
    setLoading(false);
  };
  const handlePreviousPage = async (val: number) => {
    setLoading(true);
    setPage(val - 1);
    // setKey(interTransferList.page, val - 1);
    await fetchTransferRequests(
      val - 1,
      pageLimit,
      activeTab,
      currentUser?.userType == Admin
        ? selectedWarehouse
        : currentUser?.warehouse?._id
    );
    setLoading(false);
  };
  const handlePageLimit = async (event: any) => {
    setLoading(true);
    setPage(1);
    setTotalRecords(0);
    // setKey(interTransferList.page, 1);
    // setKey(interTransferList.limit, parseInt(event.target.value));
    // setTests([]);
    await setPageLimit(parseInt(event.target.value));
    await fetchTransferRequests(
      1,
      event.target.value,
      activeTab,
      currentUser?.userType == Admin
        ? selectedWarehouse
        : currentUser?.warehouse?._id
    );
    setLoading(false);
  };
  const getQuantityTypes = (val: any) => {
    if (val == CartonWithDozens) {
      return "C";
    } else if (val == Dozen) {
      return "D";
    } else if (val == Piece) {
      return "P";
    } else if (val == CartonWithPieces) {
      return "C";
    } else {
      return "";
    }
  };
  const handleProduct = (data: any) => {
    setShowProductModal(true);
    setCurrentData(data);
  };
  return (
    <>
      {currentData && showProductModal ? (
        <ViewRequestedGoodsProducts
          show={showProductModal}
          onHide={() => {
            setShowProductModal(false);
            setCurrentData(undefined);
          }}
          products={currentData?.variants || []}
        />
      ) : (
        <></>
      )}
      <div className="p-9">
        <Row className="align-items-center">
          {/* {currentUser?.userType == Admin ? ( */}
            <Col xs={12} className="mb-5">
              <div className="d-flex flex-wrap justify-content-between align-items-center">
                <h1 className="fs-22 fw-bolder">Inter transfer request</h1>
                {
                  Method.hasPermission(WarehouseInterTransfer,Add,currentUser) ? <>{!fetchLoader ? (
                  <Button
                    onClick={() =>
                      navigate("/warehouse/create-transfer-request")
                    }
                  >
                    Create new request
                  </Button>
                ) : (
                  <></>
                )}</> : <></>
                }
              </div>
            </Col>
           {/* ) : (
            <></>
          )}  */}
          {!fetchLoader ? (
            <>
              {currentUser?.userType == 1 ? (
                <Col className="mb-5" xs={12}>
                  <div className="bg-light border border-r8px p-3 px-md-6 gy-4">
                    <span className="fs-16 fw-500">
                      Filter transfer requests by warehouses
                    </span>
                    <div className="d-flex overflow-auto mt-4 mb-2">
                      <Nav variant="pill" className="flex-nowrap gap-3">
                        {/* <Nav.Item>
                          <Nav.Link
                            className={`border rounded-pill px-3 py-2 d-flex align-items-center h-45px justify-content-center min-w-175px fw-600 fs-14 ${
                              selectedWarehouse === ''
                                ? 'bg-primary text-white'
                                : 'text-gray-600'
                            }`}
                            onClick={() => {
                              handleWarehouseClick('');
                              setPage(1);
                              fetchTransferRequests(
                                1,
                                pageLimit,
                                activeTab,
                                ''
                              );
                            }}
                          >
                            <span>All Warehouse</span>
                          </Nav.Link>
                        </Nav.Item> */}
                        {warehouseList.map((val: any) => (
                          <Nav.Item key={val._id}>
                            <Nav.Link
                              className={`border rounded-pill px-3 py-2 d-flex align-items-center h-45px justify-content-center min-w-175px fw-600 fs-14 ${
                                selectedWarehouse === val._id
                                  ? "bg-primary text-white"
                                  : "text-gray-600"
                              }`}
                              onClick={() => {
                                handleWarehouseClick(val._id);
                                setPage(1);
                                fetchTransferRequests(
                                  1,
                                  pageLimit,
                                  activeTab,
                                  currentUser?.userType == Admin
                                    ? val._id
                                    : currentUser?.warehouse?._id
                                );
                              }}
                            >
                              <span>{val.title}</span>
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </div>
                  </div>
                </Col>
              ) : (
                <></>
              )}
              <div className="custom-tabContainer">
                <Tab.Container activeKey={activeTab}>
                  <Row>
                    <Col lg={"auto"} className="mb-6">
                      <div className="bg-light border border-r8px p-3 px-md-6">
                        <Nav variant="pills">
                          <Nav.Item>
                            <Nav.Link
                              eventKey={1}
                              onClick={() => {
                                setActiveTab(1);
                                setPage(1);
                                fetchTransferRequests(
                                  page,
                                  pageLimit,
                                  1,
                                  currentUser?.userType == Admin
                                    ? selectedWarehouse
                                    : currentUser?.warehouse?._id
                                );
                              }}
                            >
                              Pending Requests
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey={2}
                              onClick={() => {
                                setActiveTab(2);
                                setPage(1);
                                fetchTransferRequests(
                                  page,
                                  pageLimit,
                                  2,
                                  currentUser?.userType == Admin
                                    ? selectedWarehouse
                                    : currentUser?.warehouse?._id
                                );
                              }}
                            >
                              Accepted Requests
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey={3}
                              onClick={() => {
                                setActiveTab(3);
                                setPage(1);
                                fetchTransferRequests(
                                  page,
                                  pageLimit,
                                  3,
                                  currentUser?.userType == Admin
                                    ? selectedWarehouse
                                    : currentUser?.warehouse?._id
                                );
                              }}
                            >
                              Completed
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey={4}
                              onClick={() => {
                                setActiveTab(4);
                                setPage(1);
                                fetchTransferRequests(
                                  page,
                                  pageLimit,
                                  4,
                                  currentUser?.userType == Admin
                                    ? selectedWarehouse
                                    : currentUser?.warehouse?._id
                                );
                              }}
                            >
                              Cancelled
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey={5}
                              onClick={() => {
                                setActiveTab(5);
                                setPage(1);
                                fetchTransferRequests(
                                  page,
                                  pageLimit,
                                  5,
                                  currentUser?.userType == Admin
                                    ? selectedWarehouse
                                    : currentUser?.warehouse?._id
                                );
                              }}
                            >
                              Rejected
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                    </Col>
                    <Tab.Content>
                      <Tab.Pane eventKey={1}>
                        <Col xs={12}>
                          <Card className=" border mb-7">
                            <Card.Body className="px-7">
                              <div className="table-responsive">
                                <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4 mb-0">
                                  <thead>
                                    <tr className="fw-bold fs-16 fw-600 text-dark border-bottom h-70px align-middle">
                                      <th className="min-w-250px">
                                        Request initiated by
                                      </th>
                                      <th className="min-w-200px">
                                        Request initiated on
                                      </th>
                                      <th className="min-w-200px">Quantity</th>
                                      <th className="min-w-150px">Status</th>
                                      <th className="min-w-200px"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {!loading ? (
                                      <>
                                        {requestList.length > 0 ? (
                                          requestList.map((val: any) => (
                                            <tr key={val.id}>
                                              <td>
                                                <span className="fs-15 fw-600">
                                                  {val?.requester?.name
                                                    ? val?.requester?.name
                                                    : val?.receiverWarehouse
                                                        ?.name}
                                                </span>
                                              </td>
                                              <td className="fw-600 fs-15">
                                                {val?._createdAt
                                                  ? Method.convertDateToDDMMYYYYHHMM(
                                                      val._createdAt
                                                    )
                                                  : "-"}
                                              </td>
                                              <td>
                                                <div className="d-flex flex-column align-items-start">
                                                  <span className="fw-600 fs-15">
                                                    {val?.variants.map(
                                                      (
                                                        item: any,
                                                        index: number
                                                      ) => {
                                                        return `${
                                                          item?.stockCount
                                                        } ${getQuantityTypes(
                                                          item?.quantityType
                                                        )}${
                                                          index <
                                                          val?.variants.length -
                                                            1
                                                            ? ", "
                                                            : " "
                                                        }`;
                                                      }
                                                    )}
                                                  </span>
                                                  {/* <Button
                                                    variant="link"
                                                    onClick={() => {
                                                      handleProduct(val);
                                                    }}
                                                  >
                                                    <span className="text-decoration-underline fs-15 fw-600">
                                                      View Products
                                                    </span>
                                                  </Button> */}
                                                </div>
                                              </td>
                                              <td>
                                                <div
                                                  style={{
                                                    height: "33px",
                                                    width: "100px",
                                                    borderRadius: "4px",
                                                    backgroundColor:
                                                      val.status == 6
                                                        ? "#e5f7de"
                                                        : "#fff4d9",
                                                  }}
                                                  className="d-flex align-items-center justify-content-center fs-14 fw-600"
                                                >
                                                  {val?.status ==
                                                    TransferRequestSubmitted ||
                                                  val?.status ===
                                                    TransferRequestAccepted
                                                    ? "In Progress"
                                                    : "Out for Delivery"}
                                                </div>
                                              </td>
                                              <td>
                                                <Button
                                                  onClick={() =>
                                                    navigate(
                                                      "/warehouse/all-inter-transfer-request/inter-transfer-details",
                                                      {
                                                        state: {
                                                          _id: val._id,
                                                        },
                                                      }
                                                    )
                                                  }
                                                >
                                                  View Details
                                                </Button>
                                              </td>
                                            </tr>
                                          ))
                                        ) : (
                                          <tr>
                                            <td
                                              colSpan={5}
                                              className="text-center"
                                            >
                                              <span className="fs-15 fw-600">
                                                No Data Found.
                                              </span>
                                            </td>
                                          </tr>
                                        )}
                                      </>
                                    ) : (
                                      <tr>
                                        <td colSpan={5}>
                                          <div className="w-100 d-flex justify-content-center">
                                            <Loader loading={loading} />
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Tab.Pane>
                      <Tab.Pane eventKey={2}>
                        <Col xs={12}>
                          <Card className=" border mb-7">
                            <Card.Body className="px-7">
                              <div className="table-responsive">
                                <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4 mb-0">
                                  <thead>
                                    <tr className="fw-bold fs-16 fw-600 text-dark border-bottom h-70px align-middle">
                                      <th className="min-w-250px">
                                        Request initiated by
                                      </th>
                                      <th className="min-w-200px">
                                        Request initiated on
                                      </th>
                                      <th className="min-w-200px">
                                        Request accepted on
                                      </th>
                                      <th className="min-w-200px">Quantity</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {!loading ? (
                                      <>
                                        {requestList.length > 0 ? (
                                          requestList.map((val: any) => (
                                            <tr key={val.id}>
                                              <td>
                                                <span className="fs-15 fw-600">
                                                  {val?.receiverWarehouse?.name}
                                                </span>
                                              </td>
                                              <td className="fs-15 fw-600">
                                                {val?._createdAt
                                                  ? Method.convertDateToDDMMYYYYHHMM(
                                                      val._createdAt
                                                    )
                                                  : "-"}
                                              </td>
                                              <td className="fs-15 fw-600">
                                                {val?._createdAt
                                                  ? Method.convertDateToDDMMYYYYHHMM(
                                                      val.statusUpdatedAt
                                                    )
                                                  : "-"}
                                              </td>
                                              <td>
                                                <div className="d-flex flex-column align-items-start">
                                                  <span className="fs-15 fw-600">
                                                    {val?.variants.map(
                                                      (
                                                        item: any,
                                                        index: number
                                                      ) => {
                                                        return `${
                                                          item?.stockCount
                                                        } ${getQuantityTypes(
                                                          item?.quantityType
                                                        )}${
                                                          index <
                                                          val?.variants.length -
                                                            1
                                                            ? ", "
                                                            : " "
                                                        }`;
                                                      }
                                                    )}
                                                  </span>
                                                  {/* <Button
                                                    variant="link"
                                                    onClick={() => {
                                                      handleProduct(val);
                                                    }}
                                                  >
                                                    <span className="text-decoration-underline fs-15 fw-600">
                                                      View Products
                                                    </span>
                                                  </Button> */}
                                                </div>
                                              </td>
                                              <td>
                                                <Button
                                                  onClick={() =>
                                                    navigate(
                                                      "/warehouse/all-inter-transfer-request/inter-transfer-details",
                                                      {
                                                        state: {
                                                          _id: val._id,
                                                        },
                                                      }
                                                    )
                                                  }
                                                >
                                                  View Details
                                                </Button>
                                              </td>
                                            </tr>
                                          ))
                                        ) : (
                                          <tr>
                                            <td
                                              colSpan={5}
                                              className="text-center"
                                            >
                                              <span className="fs-15 fw-600">
                                                No Data Found
                                              </span>
                                            </td>
                                          </tr>
                                        )}
                                      </>
                                    ) : (
                                      <tr>
                                        <td colSpan={5}>
                                          <div className="w-100 d-flex justify-content-center">
                                            <Loader loading={loading} />
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Tab.Pane>
                      <Tab.Pane eventKey={3}>
                        <Col xs={12}>
                          <Card className=" border mb-7">
                            <Card.Body className="px-7">
                              <div className="table-responsive">
                                <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4 mb-0">
                                  <thead>
                                    <tr className="fw-bold fs-16 fw-600 text-dark border-bottom h-70px align-middle">
                                      <th className="min-w-250px">
                                        Request initiated by
                                      </th>
                                      <th className="min-w-200px">
                                        Request initiated on
                                      </th>
                                      <th className="min-w-200px">
                                        Request completed on
                                      </th>
                                      <th className="min-w-200px">Quantity</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {!loading ? (
                                      <>
                                        {requestList.length > 0 ? (
                                          requestList.map((val: any) => (
                                            <tr key={val.id}>
                                              <td>
                                                <span className="fs-15 fw-600">
                                                  {val?.receiverWarehouse?.name}
                                                </span>
                                              </td>
                                              <td className="fs-15 fw-600">
                                                {val?._createdAt
                                                  ? Method.convertDateToDDMMYYYYHHMM(
                                                      val._createdAt
                                                    )
                                                  : "-"}
                                              </td>
                                              <td className="fs-15 fw-600">
                                                {val?._createdAt
                                                  ? Method.convertDateToDDMMYYYYHHMM(
                                                      val.statusUpdatedAt
                                                    )
                                                  : "-"}
                                              </td>
                                              <td>
                                                <div className="d-flex flex-column align-items-start">
                                                  <span className="fs-15 fw-600">
                                                    {val?.variants.map(
                                                      (
                                                        item: any,
                                                        index: number
                                                      ) => {
                                                        return `${
                                                          item?.stockCount
                                                        } ${getQuantityTypes(
                                                          item?.quantityType
                                                        )}${
                                                          index <
                                                          val?.variants.length -
                                                            1
                                                            ? ", "
                                                            : " "
                                                        }`;
                                                      }
                                                    )}
                                                  </span>
                                                  {/* <Button
                                                    variant="link"
                                                    onClick={() => {
                                                      handleProduct(val);
                                                    }}
                                                  >
                                                    <span className="text-decoration-underline fs-15 fw-600">
                                                      View Products
                                                    </span>
                                                  </Button> */}
                                                </div>
                                              </td>
                                              <td>
                                                <Button
                                                  onClick={() =>
                                                    navigate(
                                                      "/warehouse/all-inter-transfer-request/inter-transfer-details",
                                                      {
                                                        state: {
                                                          _id: val._id,
                                                        },
                                                      }
                                                    )
                                                  }
                                                >
                                                  View Details
                                                </Button>
                                              </td>
                                            </tr>
                                          ))
                                        ) : (
                                          <tr>
                                            <td
                                              colSpan={5}
                                              className="text-center"
                                            >
                                              <span className="fs-15 fw-600">
                                                No Data Found
                                              </span>
                                            </td>
                                          </tr>
                                        )}
                                      </>
                                    ) : (
                                      <tr>
                                        <td colSpan={5}>
                                          <div className="w-100 d-flex justify-content-center">
                                            <Loader loading={loading} />
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Tab.Pane>
                      <Tab.Pane eventKey={4}>
                        <Col xs={12}>
                          <Card className=" border mb-7">
                            <Card.Body className="px-7">
                              <div className="table-responsive">
                                <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4 mb-0">
                                  <thead>
                                    <tr className="fw-bold fs-16 fw-600 text-dark border-bottom h-70px align-middle">
                                      <th className="min-w-250px">
                                        Request initiated by
                                      </th>
                                      <th className="min-w-200px">
                                        Request initiated on
                                      </th>
                                      <th className="min-w-200px">
                                        Request cancelled on
                                      </th>
                                      <th className="min-w-200px">Quantity</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {!loading ? (
                                      <>
                                        {requestList.length > 0 ? (
                                          requestList.map((val: any) => (
                                            <tr key={val.id}>
                                              <td>
                                                <span className="fs-15 fw-600">
                                                  {val?.receiverWarehouse?.name}
                                                </span>
                                              </td>
                                              <td className="fs-15 fw-600">
                                                {val?._createdAt
                                                  ? Method.convertDateToDDMMYYYYHHMM(
                                                      val._createdAt
                                                    )
                                                  : "-"}
                                              </td>
                                              <td className="fs-15 fw-600">
                                                {val?._createdAt
                                                  ? Method.convertDateToDDMMYYYYHHMM(
                                                      val.statusUpdatedAt
                                                    )
                                                  : "-"}
                                              </td>
                                              <td>
                                                <div className="d-flex flex-column align-items-start">
                                                  <span className="fs-15 fw-600">
                                                    {val?.variants.map(
                                                      (
                                                        item: any,
                                                        index: number
                                                      ) => {
                                                        return `${
                                                          item?.stockCount
                                                        } ${getQuantityTypes(
                                                          item?.quantityType
                                                        )}${
                                                          index <
                                                          val?.variants.length -
                                                            1
                                                            ? ", "
                                                            : " "
                                                        }`;
                                                      }
                                                    )}
                                                  </span>
                                                  {/* <Button
                                                    variant="link"
                                                    onClick={() => {
                                                      handleProduct(val);
                                                    }}
                                                  >
                                                    <span className="text-decoration-underline fs-15 fw-600">
                                                      View Products
                                                    </span>
                                                  </Button> */}
                                                </div>
                                              </td>
                                              <td>
                                                <Button
                                                  onClick={() =>
                                                    navigate(
                                                      "/warehouse/all-inter-transfer-request/inter-transfer-details",
                                                      {
                                                        state: {
                                                          _id: val._id,
                                                        },
                                                      }
                                                    )
                                                  }
                                                >
                                                  View Details
                                                </Button>
                                              </td>
                                            </tr>
                                          ))
                                        ) : (
                                          <tr>
                                            <td
                                              colSpan={5}
                                              className="text-center"
                                            >
                                              <span className="fs-15 fw-600">
                                                No Data Found
                                              </span>
                                            </td>
                                          </tr>
                                        )}
                                      </>
                                    ) : (
                                      <tr>
                                        <td colSpan={5}>
                                          <div className="w-100 d-flex justify-content-center">
                                            <Loader loading={loading} />
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Tab.Pane>
                      <Tab.Pane eventKey={5}>
                        <Col xs={12}>
                          <Card className=" border mb-7">
                            <Card.Body className="px-7">
                              <div className="table-responsive">
                                <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4 mb-0">
                                  <thead>
                                    <tr className="fw-bold fs-16 fw-600 text-dark border-bottom h-70px align-middle">
                                      <th className="min-w-250px">
                                        Request initiated by
                                      </th>
                                      <th className="min-w-200px">
                                        Request initiated on
                                      </th>
                                      <th className="min-w-200px">
                                        Request rejected on
                                      </th>
                                      <th className="min-w-200px">Quantity</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {!loading ? (
                                      <>
                                        {requestList.length > 0 ? (
                                          requestList.map((val: any) => (
                                            <tr key={val.id}>
                                              <td>
                                                <span className="fs-15 fw-600">
                                                  {val?.receiverWarehouse?.name}
                                                </span>
                                              </td>
                                              <td className="fs-15 fw-600">
                                                {val?._createdAt
                                                  ? Method.convertDateToDDMMYYYYHHMM(
                                                      val._createdAt
                                                    )
                                                  : "-"}
                                              </td>
                                              <td className="fs-15 fw-600">
                                                {val?._createdAt
                                                  ? Method.convertDateToDDMMYYYYHHMM(
                                                      val.statusUpdatedAt
                                                    )
                                                  : "-"}
                                              </td>
                                              <td>
                                                <div className="d-flex flex-column align-items-start">
                                                  <span className="fs-15 fw-600">
                                                    {val?.variants.map(
                                                      (
                                                        item: any,
                                                        index: number
                                                      ) => {
                                                        return `${
                                                          item?.stockCount
                                                        } ${getQuantityTypes(
                                                          item?.quantityType
                                                        )}${
                                                          index <
                                                          val?.variants.length -
                                                            1
                                                            ? ", "
                                                            : " "
                                                        }`;
                                                      }
                                                    )}
                                                  </span>
                                                  {/* <Button
                                                    variant="link"
                                                    onClick={() => {
                                                      handleProduct(val);
                                                    }}
                                                  >
                                                    <span className="text-decoration-underline fs-15 fw-600">
                                                      View Products
                                                    </span>
                                                  </Button> */}
                                                </div>
                                              </td>
                                              <td>
                                                <Button
                                                  onClick={() =>
                                                    navigate(
                                                      "/warehouse/all-inter-transfer-request/inter-transfer-details",
                                                      {
                                                        state: {
                                                          _id: val._id,
                                                        },
                                                      }
                                                    )
                                                  }
                                                >
                                                  View Details
                                                </Button>
                                              </td>
                                            </tr>
                                          ))
                                        ) : (
                                          <tr>
                                            <td
                                              colSpan={5}
                                              className="text-center"
                                            >
                                              <span className="fs-15 fw-600">
                                                No Data Found
                                              </span>
                                            </td>
                                          </tr>
                                        )}
                                      </>
                                    ) : (
                                      <tr>
                                        <td colSpan={5}>
                                          <div className="w-100 d-flex justify-content-center">
                                            <Loader loading={loading} />
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Tab.Pane>
                    </Tab.Content>
                  </Row>
                </Tab.Container>
              </div>
              {totalRecords > 0 && !loading ? (
                <Pagination
                  totalRecords={totalRecords}
                  currentPage={page}
                  handleCurrentPage={handleCurrentPage}
                  handleNextPage={handleNextPage}
                  handlePreviousPage={handlePreviousPage}
                  handlePageLimit={handlePageLimit}
                  pageLimit={pageLimit}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <div className="d-flex justify-content-center">
              <Loader loading={fetchLoader} />
            </div>
          )}
        </Row>
      </div>
    </>
  );
};
export default InterTransferRequest;
