import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Nav, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { CustomSelectTable } from '../../custom/Select/CustomSelectTable';
import ThreeDotMenu from '../../../_admin/assets/media/svg_uTrade/three-dot.svg';
import DeleteModal from '../../modals/delete';
import { AllModules, PAGE_LIMIT, WarehouseManger } from '../../../utils/constants';
import APICallService from '../../../api/apiCallService';
import { multipleWarehouse } from '../../../api/apiEndPoints';
import Pagination from '../../../Global/pagination';
import Loader from '../../../Global/loader';
import { getKey } from '../../../Global/history';
import { warehouse as warehouseStoreString } from '../../../utils/storeString';
const Warehouse = () => {
  let tempWarehouse: any = getKey(warehouseStoreString.warehouseRef);
  tempWarehouse = JSON.parse(tempWarehouse);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('Warehouse');
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>();
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState<any>(1);
  const [pageLimit, setPageLimit] = useState<any>(PAGE_LIMIT);
  const [warehouseList, setWarehouseList] = useState<any>([]);
  useEffect(() => {
    (async () => {
      fetchWarehouse(page, pageLimit);
    })();
  }, []);
  const fetchWarehouse = async (page: any, pageLimit: any) => {
    let params: any = {
      page: page,
      limit: pageLimit,
      sortKey: 'name',
      sortOrder: 1,
      needCount: true,
    };
    if (tempWarehouse?._id) {
      params.warehouse = tempWarehouse?._id;
    }
    setLoading(true);
    const apiService = new APICallService(
      multipleWarehouse.listWarehouse,
      params,
      '',
      '',
      '',
      '',
      WarehouseManger,
      tempWarehouse?._id || null
    );
    const response = await apiService.callAPI();
    let data: any = [];
    if (response) {
      setWarehouseList(response.records);
      setTotalRecords(response.total);
    }
    setLoading(false);
  };
  const handleCurrentPage = async (val: number) => {
    if (val === page || val.toString() === '...') return;
    setLoading(true);
    setPage(val);
    // setKey(interTransferList.page, val);
    await fetchWarehouse(val, pageLimit);
    setLoading(false);
  };
  const handleNextPage = async (val: number) => {
    setLoading(true);
    setPage(val + 1);
    // setKey(interTransferList.page, val + 1);
    await fetchWarehouse(val + 1, pageLimit);
    setLoading(false);
  };
  const handlePreviousPage = async (val: number) => {
    setLoading(true);
    setPage(val - 1);
    // setKey(interTransferList.page, val - 1);
    await fetchWarehouse(val - 1, pageLimit);
    setLoading(false);
  };
  const handlePageLimit = async (event: any) => {
    setLoading(true);
    setPage(1);
    setTotalRecords(0);
    // setKey(interTransferList.page, 1);
    // setKey(interTransferList.limit, parseInt(event.target.value));
    await setPageLimit(parseInt(event.target.value));
    await fetchWarehouse(1, event.target.value);
    setLoading(false);
  };
  const deleteWarehouse = async (id: string) => {
    //  let apiService = new APICallService(
    //    master.deleteBrand,
    //    id,
    //    '',
    //    '',
    //    true,
    //    '',
    //    Warehou
    //  );
    //  let response = await apiService.callAPI();
    //  if (response && !response.error) {
    //    success(masterToast.deleteBrand);
    //    setDeleteModalOpen(false);
    //    await fetchBrands(page, pageLimit);
    //    setSelectedBrand(undefined);
    //  }
  };
  return (
    <>
      {showDelete && selectedWarehouse && (
        <DeleteModal
          show={showDelete}
          onHide={() => {
            setShowDelete(false);
            setSelectedWarehouse(undefined);
          }}
          handleDelete={() => {
            deleteWarehouse(selectedWarehouse?._id);
          }}
          // error={error}
          // loading={deleteLoader}
          serviceName="warehouse"
          message={`Are you sure you want to delete warehouse?`}
          warnMessage={`By continuing, the warehouse will
          be removed permanently`}
        />
      )}
      <div className="p-9">
        <Row className="align-items-center">
          <Col
            xs={12}
            className="mb-5"
          >
            <div className="d-flex flex-wrap justify-content-between align-items-center">
              <h1 className="fs-22 fw-bolder">Warehouses</h1>
              <Button
                onClick={() =>
                  navigate('/warehouse/all-warehouse/create-warehouse')
                }
              >
                Add warehouse
              </Button>
            </div>
          </Col>
          <Card className="border border-r10px">
            <Card.Body className="p-0">
              <div className="table-responsive">
                <table className="table table-rounded table-row-bordered align-middle gs-7 gy-4">
                  <thead>
                    <tr className="fw-bold fs-16 fw-600 text-dark border-bottom h-70px align-middle">
                      <th className="min-w-200px">Warehouses</th>
                      <th className="min-w-200px">Assigned Manager</th>
                      {/* <th className="min-w-200px">Available Products</th>
                      <th className="min-w-150px text-center">
                        Activate /<br></br>Deactivate
                      </th>
                      */}
                      <th className="min-w-200px text-end">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading ? (
                      warehouseList.length > 0 ? (
                        warehouseList.map((val: any, index: number) => (
                          <tr key={val.id}>
                            <td className="fs-15 fw-600">{val?.name || ''}</td>
                            <td className="fs-15 fw-600">
                              {val?.manager?.length
                                ? val?.manager?.map(
                                    (item: any, itemIndex: number) => {
                                      if (
                                        itemIndex <
                                        val?.manager?.length - 1
                                      ) {
                                        return (
                                          <span>
                                            {item?.name + ' , '}
                                          </span>
                                        );
                                      } else {
                                        return <span>{item?.name || ' , '}</span>;
                                      }
                                    }
                                  )
                                : '-'}
                            </td>
                            {/* <td>
                          <div className="d-flex justify-content-center">
                            <label className="form-check form-switch form-check-custom form-check-solid">
                              <input
                                className="form-check-input form-check-success w-50px h-30px"
                                type="checkbox"
                                name="notifications"
                                checked={true}
                                onChange={() => {
                                  updateStatus(val, index);
                                }}
                              />
                            </label>
                          </div>
                        </td> */}
                            <td>
                              <div className="d-flex flex-nowrap justify-content-end align-items-center">
                                {/* <Button
                              variant="primary"
                              className="btn-active-light-primary fs-14 fw-600 me-5"
                              onClick={() =>
                                navigate(
                                  '/warehouse/all-warehouse/warehouse-details'
                                )
                              }
                            >
                              View details
                            </Button> */}
                                {!val?.defaultWarehouse && (
                                  <CustomSelectTable
                                    marginLeft={'0px'}
                                    menuMargin={'-60px'}
                                    width={'140px'}
                                    placeholder={
                                      <img
                                        className="img-fluid"
                                        width={18}
                                        height={5}
                                        src={ThreeDotMenu}
                                        alt=""
                                      />
                                    }
                                    options={[
                                      {
                                        label: (
                                          <button
                                            className="btn btn-link fs-14 fw-500 text-black d-flex justify-content-center align-items-center ms-3"
                                            onClick={() =>
                                              navigate(
                                                '/warehouse/edit-warehouse-details',
                                                {
                                                  state: {
                                                    id: val?._id || '',
                                                  },
                                                }
                                              )
                                            }
                                          >
                                            Edit details
                                          </button>
                                        ),
                                        value: 1,
                                      },
                                      // {
                                      //   label: (
                                      //     <button
                                      //       className="btn btn-link fs-14 fw-500 text-danger ms-3"
                                      //       onClick={() => {
                                      //         setShowDelete(true);
                                      //         setSelectedWarehouse(val);
                                      //       }}
                                      //     >
                                      //       Delete Warehouse
                                      //     </button>
                                      //   ),
                                      //   value: 2,
                                      // },
                                    ]}
                                    isOptionDisabled={(option: any) =>
                                      option.value === 3
                                    }
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            className="fs-15 fw-600"
                            colSpan={3}
                          >
                            No Data Found.
                          </td>
                        </tr>
                      )
                    ) : (
                      <tr>
                        <td colSpan={3}>
                          <div className="d-flex justify-content-center">
                            <Loader loading={loading} />
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
          {/* <DeleteWarehouse
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> */}
        </Row>
        {!loading && totalRecords > 0 ? (
          <Pagination
            totalRecords={totalRecords}
            currentPage={page}
            handleCurrentPage={handleCurrentPage}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            handlePageLimit={handlePageLimit}
            pageLimit={pageLimit}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default Warehouse;
