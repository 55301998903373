import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import CustomerCategory from './customer-category';
const CustomerCategoryPage = () => (
  <Routes>
    <Route element={<Outlet />} />
    <Route
      index
      element={
        <>
          <CustomerCategory />
        </>
      }
    />
  </Routes>
);
export default CustomerCategoryPage;
